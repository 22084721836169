import "./NavigationCircleLeft.scss";
import { BsArrowLeft as ArrowLeft } from "react-icons/bs";

export default function NavigationCircleLeft(props) {
  return (
    <ArrowLeft
      className={"arrow-left " + (props.swiper && "prev")}
      style={{ borderColor: props.color, cursor: "pointer" }}
    />
  );
}
