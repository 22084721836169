import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import "./NewsMediaPressKitComunicatiStampa.scss";
import IntroPressKit from "components/intro-press-kit/IntroPressKit";
import LinkPressKit from "components/link-press-kit/LinkPressKit";
import NavigationCircle from "components/navigation/circle/NavigationCircle";
import { Link } from "react-router-dom";
import { truncate } from "functions/formatTextFunctions";
import Placeholders from "components/placeholders/Placeholders";
import Filter from "components/filter/Filter";

const link = {
  icon: "video",
  title: "Tutti i <span>video</span>",
  text: "In questa sezione i video istituzionali, le interviste e altro",
  to: "/news-media/press-kit/video",
};

const activeYear = new Date().getFullYear();

const years = [];

for (let i = 2015; i <= activeYear; i++) {
  years.unshift(i);
}

export default class NewsMediaPressKitComunicatiStampa extends Component {
  state = {
    comunicati: [],
    comunicatiFrom: 1,
    loadingComunicati: true,
    active: { year: activeYear },
  };

  async componentDidMount() {
    try {
      const response = await this.fetchComunicati();
      const comunicati = response?.data?.data?.documents;
      this.setState({
        comunicati: comunicati || [],
      });
    } catch (err) {
      console.log("Error CDM NewsMediaPressKitComunicatiStampa", err);
    }
    this.setState({ loadingComunicati: false });
  }

  async componentDidUpdate(oldProps, oldState) {
    if (
      this.state.comunicatiFrom !== oldState.comunicatiFrom ||
      this.state.active?.year !== oldState.active?.year
    ) {
      this.setState({ loadingComunicati: true });
      try {
        const response = await this.fetchComunicati();
        const comunicati = response?.data?.data?.documents;
        if (comunicati && comunicati.length === 0) {
          this.setState({ comunicatiFrom: 1 });
        }
        this.setState({
          comunicati: comunicati || [],
        });
      } catch (err) {
        console.log("Error CDU NewsMediaPressKitComunicatiStampa", err);
      }
      this.setState({ loadingComunicati: false });
    }
  }

  handleComunicatiNavigation = (delta) => {
    this.setState({ comunicatiFrom: this.state.comunicatiFrom + delta * 9 });
  };

  fetchComunicati = async () => {
    const headers = {
      headers: { "Maestrale-Cache-Control": 1800 },
    };
    return await axios.post(
      "https://api.utilitalia.it/DMS/Documents/List",
      {
        documentClasses: ["COMUNICATI_STAMPA"],
        headingPath: "\\COM",
        folder: this.state.active?.year,
        pageStart: this.state.comunicatiFrom,
        pageSize: 9,
        documentValueOrders: [
          {
            code: "DATA",
            type: "5",
            direction: "desc",
          },
        ],
      },
      headers
    );
  };

  handleChange = (value, target) => {
    this.setState({ active: { year: value } });
  };

  // handleSubmit = (event) => {
  //   event.preventDefault();
  // };

  handleReset = () => {
    this.setState({
      active: {
        year: moment().format("YYYY"),
      },
    });
  };

  render() {
    const filters = [
      {
        name: "year",
        text: "Seleziona l'anno",
        values: years,
      },
    ];
    return (
      <div id="news-media-press-kit-comunicati-stampa">
        <IntroPressKit section="Comunicati stampa" />
        <div className="bg-white">
          <div className="container pad-mobile">
            <h2 className="general">
              Comunicati <span>stampa</span>
            </h2>
            <Filter
              filters={filters}
              change={this.handleChange}
              value={this.state.active}
              reset={this.handleReset}
            />
            <div className="comunicati-list">
              {this.state.loadingComunicati ? (
                <Placeholders number={9} type="rassegna" />
              ) : (
                this.state.comunicati.map((element, i) => (
                  <div className="comunicato" key={i}>
                    <div className="date">
                      {moment(
                        element.values.find((value) => value.code === "DATA")
                          .value
                      ).format("ll")}
                    </div>
                    <h3>
                      {truncate(
                        element.values.find((value) => value.code === "TITOLO")
                          .value,
                        80
                      )}
                    </h3>
                    {/* <h5>{element.values[2] && element.values[2].value}</h5> */}
                    <Link to={"/notizia/" + element.id} className="btn small">
                      Leggi di più
                    </Link>
                  </div>
                ))
              )}
            </div>

            <NavigationCircle
              disabled={this.state.comunicatiFrom === 1}
              color="var(--greyColor)"
              function={this.handleComunicatiNavigation}
            />
          </div>
        </div>
        <div className="container">
          <LinkPressKit data={link} />
        </div>
      </div>
    );
  }
}
