import "./LinkPressKit.scss";
import { ReactComponent as Folder } from "icons/folders-bold.svg";
import { ReactComponent as Document } from "icons/file-text-bold.svg";
import { FiVideo as Video } from "react-icons/fi";
import { ReactComponent as Arrow } from "icons/arrow-right.svg";
import { Link } from "react-router-dom";

const renderIcon = (icon) => {
  switch (icon) {
    case "document":
      return <Document />;
    case "video":
      return <Video className="different" />;
    default:
  }
};

const LinkPressKit = ({ data }) => (
  <div className="link-press-kit-component">
    <div className="boxes">
      <Link to="/news-media/press-kit" className="box">
        <div className="box-left">
          <Folder />
        </div>
        <div className="box-right">
          <div className="box-right-left">
            <h2 className="general">
              Media <span>Kit</span>
            </h2>
            <p>
              Loghi, brochure, foto e infografiche dei settori di Utilitalia
            </p>
          </div>
          <Arrow />
        </div>
      </Link>

      <Link to={data.to} className="box">
        <div className="box-left">{renderIcon(data.icon)}</div>
        <div className="box-right">
          <div className="box-right-left">
            <h2
              className="general"
              dangerouslySetInnerHTML={{
                __html: data.title,
              }}
            />
            <p>{data.text}</p>
          </div>
          <Arrow />
        </div>
      </Link>
    </div>
  </div>
);

export default LinkPressKit;
