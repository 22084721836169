import React, { Component } from "react";
import axios from "axios";
import IntroSection from "components/intro-section/IntroSection";
import { isMobile } from "react-device-detect";

const format = (title) => {
  if (title) {
    let arr = [];
    let exludeWords = ["e", "di"];
    arr = title.split("-");
    return arr
      .map((word, i) => {
        return exludeWords.includes(word) && i !== 0
          ? [word]
          : word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  } else {
    return null;
  }
};

const findValue = (arr, code) => {
  return arr?.find((obj) => obj.code === code) || {};
};

const fetchBanners = async () => {
  try {
    const response = await axios({
      url: "https://api.utilitalia.it/DMS/Documents/List",
      method: "POST",
      data: {
        documentClasses: ["CONTENUTO_WEB"],
        heading: "\\HOME",
        folder: "BANNERINI",
        documentValueDefs: [
          {
            code: "IMMAGINE",
          },
          {
            code: "LINK",
          },
        ],
        pageSize: "0",
        pageStart: "1",
        documentValueOrders: [
          {
            code: "POSIZIONE",
            type: "3",
            direction: "asc",
          },
        ],
      },
    });

    if (response?.data.status === "OK") {
      return response?.data?.data?.documents?.map((banner) => {
        const img = findValue(banner.values, "IMMAGINE")?.value?.url;
        const url = findValue(banner.values, "LINK")?.value?.url;
        return {
          img: `https://cms.utilitalia.it${img}`,
          url,
        };
      });
    }
    return [];
  } catch (err) {
    console.log("Error fetching banners BannersPage", err);
    return [];
  }
};

export default class BannersPage extends Component {
  state = { banners: [] };

  async componentDidMount() {
    const banners = await fetchBanners();
    this.setState({ banners });
  }

  render() {
    const { banners } = this.state;
    return (
      <div>
        <IntroSection
          category={format(this.props.match.url.split("/")[1])}
          subsector={format(this.props.match.url.split("/")[2])}
          subsubsector={format(this.props.match.url.split("/")[3])}
          subsubsubsector={format(this.props.match.url.split("/")[4])}
        />
        <div className="container pad-mobile">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: isMobile ? "column" : "row",
              flexWrap: "wrap",
            }}
          >
            {banners.map((banner) => (
              <div
                style={{
                  backgroundColor: "white",
                  padding: 16,
                  marginBottom: isMobile ? 16 : 40,
                }}
              >
                <a href={banner.url} target="_blank">
                  <img src={banner.img} style={{ width: "100%" }} />
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
