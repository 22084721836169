import React, { Component } from "react";
import { Helmet } from "react-helmet-async";
import "./NewsMediaUltimeNotizie.scss";
import Filter from "components/filter/Filter";
import NavigationCircle from "components/navigation/circle/NavigationCircle";
import NavigationCircleRight from "components/navigation/circle/NavigationCircleRight";
import { ReactComponent as ArrowRight } from "icons/arrow-right.svg";
import { ReactComponent as File } from "icons/file-bold.svg";
import RenderIcon from "components/render-icon/RenderIcon";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import Placeholders from "components/placeholders/Placeholders";
import Article from "components/article/Article";
const getId = element => {
    return typeof element?.talkingId !== 'undefined' && element?.talkingId.trim() !== '' && element?.talkingId !== null && element?.talkingId !== false  ? element.talkingId : element.id;
}

const links = [
  {
    title: `<h2 className="general">Press <span>Kit</span></h2>`,
    text: "All'interno i comunicati stampa, media kit e video.",
    to: "/news-media/press-kit",
  },
  {
    title: `<h2 className="general">Calendario <span>eventi</span></h2>`,
    text: "All'interno l'archivio di tutti gli eventi.",
    to: "/calendario-eventi",
  },
  {
    title: `<h2 className="general">Notizie <span>Antitrust</span></h2>`,
    text: `Nella sezione le news antitrust in collaborazione con lo Studio Legale "Rucellai&Raffaelli."`,
    to: "/area-comunicazione/notizie-antitrust/list",
  },
  {
    title: `<h2 className="general">Le ultime <span>notizie</span></h2>`,
    text: `La nostra newsletter giornaliera`,
    to: "/ultime",
  },
];

export default class NewsMediaUltimeNotizie extends Component {
  state = {
    homeNews: [],
    activeNew: 0,
    news: [],
    newsFrom: 1,
    rassegna: [],
    rassegnaFrom: 1,
    active: { year: moment().year() },
    loadingNew: true,
    loadingNews: true,
    loadingRassegna: true,
    years: [],
  };

  async componentDidMount() {
    // homeNews
    try {
      const news = await this.fetchBigNews();
      this.setState({
        homeNews: news || [],
      });
    } catch (err) {
      console.log("Error CDM NewsMediaUltimeNotizie fetchBigNews", err);
    }
    this.setState({ loadingNew: false });

    // news
    try {
      const response = await this.fetchNews();
      const news = response?.data?.data?.documents;
      this.setState({
        news: news || [],
      });
    } catch (err) {
      console.log("Error CDM NewsMediaUltimeNotizie fetchNews", err);
    }
    this.setState({ loadingNews: false });

    // years
    try {
      const response = await this.fetchYears();
      let years = response?.data?.data?.folders;
      years = years.map((element) => {
        return element.code;
      });
      years.sort((a, b) => (a > b ? -1 : b > a ? 1 : 0));
      this.setState({
        years,
      });
    } catch (err) {
      console.log("Error CDM NewsMediaUltimeNotizie fetchYears", err);
    }

    // rassegna
    try {
      const response = await this.fetchRassegna();
      const rassegna = response?.data?.data?.documents;
      this.setState({
        rassegna: rassegna || [],
      });
    } catch (err) {
      console.log("Error CDM NewsMediaUltimeNotizie fetchRassegna", err);
    }
    this.setState({ loadingRassegna: false });
  }

  async componentDidUpdate(oldProps, oldState) {
    if (
      this.state.newsFrom !== oldState.newsFrom ||
      this.state.active.year !== oldState.active.year
    ) {
      this.setState({ loadingNews: true });
      try {
        const response = await this.fetchNews();
        const news = response?.data?.data?.documents;
        if (news && news.length === 0) {
          this.setState({ newsFrom: 1 });
        }
        this.setState({
          news: news || [],
        });
      } catch (err) {
        console.log("Error fetching news CDU NewsMediaKitUltimeNotizie", err);
      }
      this.setState({ loadingNews: false });
    }
    if (this.state.rassegnaFrom !== oldState.rassegnaFrom) {
      this.setState({ loadingRassegna: true });
      try {
        const response = await this.fetchRassegna();
        const rassegna = response?.data?.data?.documents;
        if (rassegna && rassegna.length === 0) {
          this.setState({ rassegnaFrom: 1 });
        }
        this.setState({
          rassegna: rassegna || [],
        });
      } catch (err) {
        console.log(
          "Error fetching rassegna CDU NewsMediaKitUltimeNotizie",
          err
        );
      }
      this.setState({ loadingRassegna: false });
    }
  }

  fetchBigNews = async () => {
    const headers = {
      headers: { "Maestrale-Cache-Control": 1800 },
    };
    let news = await axios.post(
      "https://api.utilitalia.it/DMS/Documents/List",
      {
        documentClasses: ["NEWS"],
        heading: "\\COM\\NEWS\\ITALPRESS",
        folder: this.state.activeYear,
        pageStart: 1,
        pageSize: 3,
        years: [],
        documentValueOrders: [
          {
            code: "DATA",
            type: "5",
            direction: "desc",
          },
        ],
      },
      headers
    );
    news = news?.data?.data?.documents;
    news = await Promise.all(
      news?.map(async (sing) => {
        let foto = await axios.post(
          "https://api.utilitalia.it/DMS/Files/File",
          {
            fileId: sing?.values?.find((value) => value.code === "ALLFOTO")
              ?.value.id,
          },
          headers
        );
        foto = foto?.data?.data;
        return { ...sing, ...foto };
      })
    );
    return news;
  };

  fetchNews = async () => {
    const headers = {
      headers: { "Maestrale-Cache-Control": 1800 },
    };
    return await axios.post(
      "https://api.utilitalia.it/DMS/Documents/List",
      {
        documentClasses: ["NEWS"],
        headingPath: "\\COM\\NEWS",
        folder: this.state.active.year,
        pageStart: this.state.newsFrom,
        pageSize: 9,
        documentValueOrders: [
          {
            code: "DATA",
            type: "5",
            direction: "desc",
          },
        ],
      },
      headers
    );
  };

  fetchRassegna = async () => {
    const headers = {
      headers: { "Maestrale-Cache-Control": 1800 },
    };
    return await axios.post(
      "https://api.utilitalia.it/DMS/Documents/List",
      {
        documentClasses: ["RASSEGNA"],
        heading: "\\COM\\RS\\PREW",
        folder: this.state.active.activeYear,
        pageStart: this.state.rassegnaFrom,
        pageSize: 4,
        documentValueOrders: [
          {
            code: "DATA",
            type: "5",
            direction: "desc",
          },
        ],
      },
      headers
    );
  };

  fetchYears = async () => {
    return await axios.post("https://api.utilitalia.it/DMS/Folders/List", {
      path: "\\COM\\NEWS\\ADNKRONOS",
      orderBy: "number",
    });
  };

  handleNewNavigation = (delta) => {
    if (delta < 0 && this.state.activeNew === 0) {
      this.setState({ activeNew: 2 });
    } else if (delta > 0 && this.state.activeNew === 2) {
      this.setState({ activeNew: 0 });
    } else this.setState({ activeNew: this.state.activeNew + delta });
  };

  handleNewsNavigation = (delta) => {
    this.setState({ newsFrom: this.state.newsFrom + delta * 9 });
  };

  handleRassegnaNavigation = (delta) => {
    this.setState({ rassegnaFrom: this.state.rassegnaFrom + delta * 4 });
  };

  handleChange = (value, target) => {
    this.setState({ active: { ...this.state.active, [target]: value } });
  };

  handleReset = () => {
    this.setState({ active: { year: moment().year() } });
  };

  render() {
    const filters = [
      {
        name: "year",
        text: "Seleziona l'anno",
        values: this.state.years || [],
        value: this.state.active.year,
      },
    ];

    return (
      <div id="news-media-ultime-notizie">
        <Helmet>
          <title>Utilitalia - Ultime Notizie</title>
          <meta
            name="description"
            content="Visita il sito e scopri le ultime notizie gionalistiche, informazioni parlamentari e legislative delle ultime 24 ore"
          />
        </Helmet>
        <div className="container pad-mobile" style={{ paddingBottom: 60 }}>
          <h2 className="general" style={{ marginBottom: 0 }}>
            News & <span>media</span>
          </h2>
        </div>
        <div className="intro">
          <div className="container pad-mobile" style={{ paddingBottom: 40 }}>
            <h6>News In Evidenza</h6>

            {this.state.loadingNew || this.state.homeNews.length === 0 ? (
              <Placeholders number={1} type="new-singola" />
            ) : (
              <div className="new">
                {this.state.homeNews[this.state.activeNew]?.data && (
                  <img
                    className="image"
                    src={
                      "data:image/jpeg;base64, " +
                      this.state.homeNews[this.state.activeNew]?.data
                    }
                    alt=""
                  />
                )}
                <div className="content">
                  <div className="heading">
                    <div className="date">
                      {moment(
                        this.state.homeNews[this.state.activeNew].values.find(
                          (value) => value.code === "DATA"
                        ).value
                      ).format("ll")}
                    </div>
                  </div>
                  <h3>
                    {
                      this.state.homeNews[this.state.activeNew].values.find(
                        (value) => value.code === "TITOLO"
                      ).value
                    }
                  </h3>
                  <div className="module" style={{ marginBottom: "2rem" }}>
                    <p
                      className="fade"
                      dangerouslySetInnerHTML={{
                        __html: clearHtml(
                          this.state.homeNews[this.state.activeNew].values.find(
                            (value) => value.code === "THTML"
                          ).value
                        ),
                      }}
                    />
                  </div>
                  <Link
                    to={
                      "/notizia/" + getId(this.state.homeNews[this.state.activeNew])
                    }
                    className="btn white"
                    style={{ color: "var(--blueColor)" }}
                  >
                    Leggi di più
                  </Link>
                </div>
              </div>
            )}

            <NavigationCircle function={this.handleNewNavigation} margin={0} />
          </div>
        </div>

        <div className="bg-white">
          <div className="news container pad-mobile">
            <h2 className="general">News</h2>

            <Filter
              filters={filters}
              change={this.handleChange}
              reset={this.handleReset}
              value={this.state.active}
            />

            <div className="list-news">
              {this.state.loadingNews ? (
                <Placeholders number={9} type="new" />
              ) : (
                this.state.news.map((element, i) => (
                  <div key={i} className="new">
                    <div>
                      <div className="heading">
                        <div style={{ flex: 1 }}>
                          <RenderIcon
                            fetchDisabled
                            fonte={
                              element.values.find(
                                (value) => value.code === "FONTE"
                              ).value
                            }
                          />
                        </div>
                        <div style={{ flex: 1, textAlign: "center" }}>
                          <span className="date">
                            {moment(
                              element.values.find(
                                (value) => value.code === "DATA"
                              ).value
                            ).format("ll")}
                          </span>
                        </div>
                        <div style={{ flex: 1, textAlign: "end" }}>
                          <span className="general">NEWS</span>
                        </div>
                      </div>
                      <h4 style={{ paddingBottom: 0 }}>
                        <div className="module heading">
                          <div className="fade">
                            {
                              element.values.find(
                                (value) => value.code === "TITOLO"
                              ).value
                            }
                          </div>
                        </div>
                      </h4>
                      <div className="module">
                        <div className="fade">
                          {clearHtml(
                            element.values.find(
                              (value) => value.code === "ABSTRACT"
                            ).value
                          )}
                        </div>
                      </div>
                    </div>
                    <Link to={"/notizia/" + getId(element)} className="btn small">
                      Leggi di più
                    </Link>
                  </div>
                ))
              )}
            </div>
            <NavigationCircle
              function={this.handleNewsNavigation}
              disabled={this.state.newsFrom === 1}
            />
          </div>
        </div>
        <div className="container">
          {/*<div className="rassegna pad-mobile">
            <div className="heading" style={{ paddingBottom: "2rem" }}>
              <h2 className="general">
                Rassegna <span>stampa</span>
              </h2>
              <Link
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="heading-right"
                to="/news-media/rassegna-stampa"
              >
                <span className="general">Vedi tutte</span>
                <ArrowRight />
              </Link>
            </div>
            <div className="rassegna-list">
              {this.state.loadingRassegna ? (
                <Placeholders number={4} type="rassegna" />
              ) : (
                this.state.rassegna.map((article, i) => (
                  <Article key={i} article={article} i={i} />
                  // <div className="article" key={i}>
                  //   <span className="general">
                  //     {
                  //       article.values.find((value) => value.code === "TESTATA")
                  //         .value
                  //     }
                  //   </span>
                  //   <h4>
                  //     {
                  //       article.values.find((value) => value.code === "TITOLO")
                  //         .value
                  //     }
                  //   </h4>
                  //   <Link to={"/notizia/" + article.id} className="btn">
                  //     Leggi di più
                  //   </Link>
                  // </div>
                ))
              )}
            </div>
            <NavigationCircle
              function={this.handleRassegnaNavigation}
              disabled={this.state.rassegnaFrom === 1}
            />
          </div> */}
          <div className="links pad-mobile">
            {links.map((element, i) => (
              <Link to={element.to} className="link" key={i}>
                <File />
                <div className="link-top">
                  <h2
                    className="general"
                    dangerouslySetInnerHTML={{
                      __html: element.title,
                    }}
                  />
                  <p>{element.text}</p>
                </div>
                <NavigationCircleRight />
              </Link>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const clearHtml = (text) => {
  return text.split(/<[^>]*>?/gm).join("");
};
