import React from "react";
import "./Footer.scss";
import Logo from "img/logo/utilitalia-logo.png";
import Bespoken from "icons/bespoken.svg";
import { Link } from "react-router-dom";
import { menu } from "./items";

const Footer = () => (
  <div id="footer" className="no-print">
    <div className="footer-top">
      <Link className="footer-logo" to="/">
        <img src={Logo} alt="Logo" />
      </Link>

      <div className="footer-menu">
        {menu.map((element, i) => (
          <div key={i} className="menu">
            <h6>{element.title}</h6>
            <div className="voices">
              {element.voices
                ? element.voices.map((element, i) => (
                    <div key={i} className="voice">
                      {element.to ? (
                        <Link to={element.to}>{element.title}</Link>
                      ) : (
                        <a href={element.path} target="_blank">{element.title}</a>
                      )}
                    </div>
                  ))
                : element.icon}
            </div>
          </div>
        ))}
      </div>
    </div>

    <div className="footer-bottom">
      <div className="footer-bottom-left">
        <p>
          ©
          <Link className="footer-logo" to="/">
            Utilitalia
          </Link>{" "}
          - Piazza Cola di Rienzo 80/A - 00192 Roma - CF 97378220582 -
          <span>
            <a
              href="mailto:direzionegenerale@utilitalia.it"
              style={{ marginLeft: 4 }}
            >
              direzionegenerale@utilitalia.it
            </a>
          </span>
        </p>
      </div>

      <div className="footer-bottom-right">
        {/* <p>
          <Link to="/legal/privacy-policy">Privacy Policy</Link> ·{" "}
          <Link to="/legal/comunicazioni">Social Media Policy</Link> ·{" "}
          <Link to="/legal/note-legali">Note Legali</Link>
        </p> */}
        <a href="https://www.bspkn.it/">
          <img src={Bespoken} alt="Bespoken" />
        </a>
      </div>
    </div>
  </div>
);

export default Footer;
