/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  File,
  ChartLineUp,
  Question,
  Intersect,
  FileText,
  Envelope,
} from "phosphor-react";

export function renderIcon(element, color) {
  switch (element) {
    case "file":
      return (
        <File
          css={css`
            background: var(--${color});
          `}
        />
      );

    case "line":
      return (
        <ChartLineUp
          css={css`
            background: var(--${color});
          `}
        />
      );

    case "question":
      return (
        <Question
          css={css`
            background: var(--${color});
          `}
        />
      );

    case "intersect":
      return (
        <Intersect
          css={css`
            background: var(--${color});
          `}
        />
      );

    case "file-text":
      return (
        <FileText
          css={css`
            background: var(--${color});
          `}
        />
      );

    case "envelope":
      return (
        <Envelope
          css={css`
            background: var(--${color});
          `}
        />
      );
    default:
  }
}
