import "font/stylesheet.css";
import "App.scss";
import "placeholder-loading/dist/css/placeholder-loading.min.css";
import { useState } from "react";
import "./Navbar.scss";
import SocialList from "components/social-list/SocialList";
import axios from "axios";
import HamburgerMenu from "components/hamburger-menu/HamburgerMenu";
import Logo from "img/logo/utilitalia-logo.png";
import Search from "icons/search.svg";
import { List } from "phosphor-react";
import { Link, useHistory } from "react-router-dom";

const Navbar = () => {
  const [hamburger, setHamburger] = useState(false);
  const [searchBar, setSearchBar] = useState(false);
  const [query, setQuery] = useState("");
  const sessionId = sessionStorage.getItem("sessionId");
  const history = useHistory();

  const handleMenu = () => {
    if (hamburger === "show") {
      setHamburger("");
      document.body.classList.remove("no-scroll");
    } else {
      setHamburger("show");
      document.body.classList.add("no-scroll");
    }
  };

  const logout = async () => {
    const headers = {
      headers: { "Session-Id": sessionId },
    };

    try {
      await axios.post(
        "https://api.utilitalia.it/CMS/Session/Logout",
        {},
        headers
      );
      sessionStorage.removeItem("sessionId");
      window.location.href = "/";
      document.cookie =
        "sessionId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    } catch (err) {
      console.log("Error logging out", err);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      search(query);
    }
  };

  const search = () => {
    if (query !== "") {
      history.push({
        pathname: "/risultati-ricerca",
        state: { query },
      });
      setSearchBar(false);
    }
  };

  return (
    <div className="navbar no-print">
      <div onClick={handleMenu} className={hamburger + " overlay"}></div>

      <Link className="logo" to="/">
        <img src={Logo} alt="Logo" />
      </Link>

      <div className="navbar-links">
        <input
          className="search-bar extended"
          type="text"
          placeholder="Cerca..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyPress={handleKeyPress}
        />

        <div className="search-bar-mobile">
          <img onClick={() => setSearchBar(true)} src={Search} alt="" />

          {searchBar && (
            <input
              className="search-bar"
              type="text"
              placeholder="Cerca..."
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyPress={handleKeyPress}
            />
          )}
        </div>

        <SocialList />

        <div className="navbar-items">
          <Link to="/acqua/home">Acqua</Link>
          <Link to="/ambiente/home">Ambiente</Link>
          <Link to="/energia/home">Energia</Link>
        </div>
        <div className="line"></div>
        <div className="navbar-menu">
          {!sessionId ? (
            <Link to="/login" className="btn big">
              Accedi
            </Link>
          ) : (
            <div
              style={{ cursor: "pointer" }}
              onClick={logout}
              className="btn big"
            >
              Esci
            </div>
          )}
          <List
            // weight="bold"
            color="var(--black)"
            width={25}
            onClick={handleMenu}
            className={hamburger + " hamburger"}
          />
        </div>
      </div>

      <HamburgerMenu toggleVisibility={handleMenu} visibility={hamburger} />
    </div>
  );
};

export default Navbar;
