import React, { Component } from "react";
import "./Associate.scss";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import Filter from "components/filter/Filter";
import Pagination from "components/pagination/Pagination";
import Placeholders from "components/placeholders/Placeholders";

export default class Associate extends Component {
  state = {
    associate: [],
    loading: true,
    active: {
      city: "",
      sigla: "",
      settore: "",
      tipologia: 0,
    },
    cities: [],
    sigle: [],
    totalPages: 1,
  };

  async componentDidMount() {
    try {
      await this.fetchAssociate(1, 5000).then((response) => {
        response = response.data && response.data.data;
        let associate = response && response.associate;
        let totalPages = Math.ceil(response && response.totalRows / 20);

        let cities = [];
        associate.forEach((associata, i) => {
          if (cities.indexOf(associata.citta) === -1) {
            cities.push(associata.citta);
          }
        });

        let sigle = [];
        associate.forEach((associata, i) => {
          if (sigle.indexOf(associata.sigla) === -1) {
            sigle.push(associata.sigla);
          }
        });

        this.setState({
          associate: associate.slice(0, 20),
          loading: false,
          cities: cities,
          sigle: sigle.sort(),
          totalPages: totalPages,
          totalRows: response?.totalRows,
        });
      });
      await this.fetchTipologie().then((response) => {
        response = response.data && response.data.data;

        // console.log("RESPONESE", response.values);
        this.setState({
          tipologie: response.values,
        });
      });
      await this.fetchSettori().then((response) => {
        response = response.data && response.data.data;

        this.setState({
          settori: response.values,
        });
      });
      await this.fetchMatrice().then((response) => {
        response = response.data && response.data.data;
        // console.log("RESPONESE", response.cells);
        this.setState({
          celle: response.cells,
        });
      });
    } catch (err) {
      console.log("Error CDM Associate", err);
    }
  }

  async componentDidUpdate(oldProps, oldState) {
    if (
      this.state.active.city !== oldState.active.city ||
      this.state.active.sigla !== oldState.active.sigla ||
      this.state.active.settore !== oldState.active.settore ||
      this.state.active.tipologia !== oldState.active.tipologia
    ) {
      this.setState({ loading: true });
      try {
        const response = await this.fetchAssociate();
        this.setState({
          associate: response?.data?.data?.associate?.slice(0, 20),
          totalPages: Math.ceil((response?.data?.data?.totalRows || 0) / 20),
        });
      } catch (err) {
        console.log("Error CDU Associate", err);
      }
      this.setState({ loading: false });
    }
  }
  fetchAssociate = async (page, size) => {
    return await axios.post(
      "https://api.utilitalia.it/UTI/ASS/Associate/List",
      {
        citta: this.state.active.city,
        sigla: this.state.active.sigla,
        tipologia: this.state.active.tipologia,
        settore: this.state.active.settore,
        pageStart: page === 0 ? 1 : page,
        pageSize: size ? size : 20,
        orderDirection: "desc",
      }
    );
  };
  fetchSettori = async () => {
    return await axios.post(
      "https://api.utilitalia.it/UTI/ASS/Associate/SettoriLookup"
    );
  };
  fetchTipologie = async () => {
    return await axios.post(
      "https://api.utilitalia.it/UTI/ASS/Associate/TipologieLookup"
    );
  };
  fetchMatrice = async () => {
    return await axios.post(
      "https://api.utilitalia.it/UTI/ASS/Associate/MatriceLookup"
    );
  };
  handleChange = (value, target) => {
    this.setState({ active: { ...this.state.active, [target]: value } });
  };

  handleReset = () => {
    this.setState({ active: { city: "", sigla: "" }, loading: true });
  };

  handlePageClick = async (data) => {
    this.setState({ loading: true });
    try {
      let selected = data.selected;
      window.scrollTo(0, 0);

      const response = await this.fetchAssociate(selected * 20 + 1, 20);
      this.setState({
        associate: response?.data?.data?.associate,
      });
    } catch (err) {
      console.log("Error fetching associate", err);
    }
    this.setState({ loading: false });
  };

  findCell = (settore, tipologia) => {
    if (!!this.state.celle) {
      return this.state.celle.find((cella) => {
        return (
          cella.settore?.toLowerCase() === settore?.toLowerCase() &&
          cella?.tipologia === tipologia
        );
      });
    }
  };
  render() {
    // console.log("settori", this.state.settori);
    const filters = [
      {
        name: "city",
        text: "Seleziona la città",
        values: ["Città", ...this.state.cities],
      },
      {
        name: "sigla",
        text: "Seleziona la sigla",
        values: ["Sigla dell'azienda", ...this.state.sigle],
      },
    ];
    return (
      <div id="associate">
        <Helmet>
          <title>Utilitalia - Le Associate</title>
          <meta
            name="description"
            content="In questa sezione troverete tutte le realtà associate a Utilitalia. Visita il nostro sito"
          />
        </Helmet>
        <div className="hero"></div>
        <div className="container pad-mobile">
          <h2 className="general">
            Le <span>associate</span>
          </h2>
          <Filter
            filters={filters}
            change={this.handleChange}
            submit={this.handleSubmit}
            value={this.state.active}
            reset={this.handleReset}
            resetButton={
              (this.state.active.city !== "" ||
                this.state.active.area !== "") &&
              "show"
            }
          />
          <div className="table_associate">
            <div className="table_associate_row table_associate_header">
              <div className="table_associate_cell" style={{ flex: 2.5 }}>
                {" "}
              </div>
              {!!this.state.settori &&
                this.state.settori.map((settore, i) => (
                  <div className="table_associate_cell" key={`${i}_${settore}`}>
                    {settore}
                  </div>
                ))}
            </div>
            <div className="table_associate_body">
              {this.state.tipologie &&
                this.state.tipologie.map((tipologia, i) => {
                  // console.log("tipologia", tipologia);
                  return (
                    <div className="table_associate_row">
                      <div
                        className="table_associate_cell"
                        style={{ flex: 2.5 }}
                      >
                        {" "}
                        {tipologia?.Value}
                      </div>
                      {!!this.state.settori &&
                        this.state.settori.map((settore, i) => {
                          return (
                            <div
                              className="table_associate_cell"
                              key={`${i}_${settore}`}
                              onClick={() =>
                                this.setState({
                                  active: { tipologia: tipologia.Key, settore },
                                })
                              }
                            >
                              {this.findCell(settore, tipologia.Key) &&
                                this.findCell(settore, tipologia.Key).count}
                            </div>
                          );
                        })}
                    </div>
                  );
                })}
              <div className="table_associate_row">
                <div
                  className="table_associate_cell bold"
                  style={{ flex: 2.5 }}
                >
                  TOTALE PARZIALE
                </div>
                {!!this.state.settori &&
                  this.state.settori.map((settore, i) => {
                    return (
                      <div
                        className="table_associate_cell bold"
                        key={`${i}_${settore}`}
                        onClick={() =>
                          this.setState({
                            active: { settore },
                          })
                        }
                      >
                        {this.findCell(settore, null) &&
                          this.findCell(settore, null).count}
                      </div>
                    );
                  })}
              </div>
              <div className="table_associate_row">
                <div
                  className="table_associate_cell bold"
                  style={{ flex: 2.5 }}
                >
                  TOTALE ASSOCIATE UTILITALIA
                </div>
                <div
                  className="table_associate_cell"
                  style={{ border: "none" }}
                ></div>
                <div
                  className="table_associate_cell"
                  style={{ border: "none" }}
                ></div>
                <div
                  className="table_associate_cell bold"
                  style={{ border: "none" }}
                >
                  {this.state.totalRows}
                </div>
                <div
                  className="table_associate_cell"
                  style={{ border: "none" }}
                ></div>
                <div
                  className="table_associate_cell"
                  style={{ border: "none" }}
                ></div>
              </div>
            </div>
          </div>
          <div className="associate-list">
            {this.state.loading ? (
              <Placeholders number={9} type="associata" />
            ) : (
              this.state.associate &&
              this.state.associate.map((element, i) => (
                <div className="associata" key={i}>
                  <div style={{ flex: 2 }}>
                    <p
                      style={{
                        fontSize: 12,
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        paddingBottom: 8,
                      }}
                    >
                      {element.sigla}
                    </p>
                    <h3>{element.denominazione}</h3>
                  </div>
                  <div style={{ paddingBottom: 16, fontSize: 12, flex: 2 }}>
                    <div style={{ paddingBottom: 4 }}>{element.citta}</div>
                    <div style={{ paddingBottom: 16 }}>
                      {element.indirizzo} - {element.cap}
                    </div>

                    <div style={{ paddingBottom: 4 }}>
                      Tel {element.telefono}
                    </div>
                    <div>Fax {element.fax}</div>
                  </div>

                  <a
                    href={element.web}
                    className="btn"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Sito web
                  </a>
                </div>
              ))
            )}
          </div>
          {this.state.totalPages > 1 && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                paddingTop: 32,
              }}
            >
              <Pagination
                totalPages={this.state.totalPages}
                handlePageClick={this.handlePageClick}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
