import { ReactComponent as Water } from "icons/drop-bold.svg";
import { ReactComponent as Leaf } from "icons/leaf-bold.svg";
import { ReactComponent as Thunder } from "icons/lightning-bold.svg";
import { ReactComponent as Settings } from "icons/faders-bold.svg";
import { ReactComponent as Bag } from "icons/tote-bold.svg";
import { ReactComponent as Steam } from "icons/shield-chevron-bold.svg";
import { ReactComponent as Chat } from "icons/chats-circle-bold.svg";
import { ReactComponent as Book } from "icons/book-bold.svg";
import { ReactComponent as Target } from "icons/target-bold.svg";
import imgAcqua from "img/settori-aree/acqua.jpg";
import imgAmbiente from "img/settori-aree/ambiente.jpg";
import imgEnergia from "img/settori-aree/energia.jpg";
import imgAmministrazione from "img/settori-aree/amministrazione.jpg";
import imgGiuridico from "img/settori-aree/legislativa.jpg";
import imgLavoro from "img/settori-aree/lavoro.jpg";
import imgRegolazione from "img/settori-aree/affari-regolatori.jpg";
import imgComunicazione from "img/settori-aree/comunicazione.jpg";
import imgStrategie from "img/settori-aree/strategie.jpg";

export function renderCategory(category) {
  switch (category) {
    case "acqua":
    case "Acqua": {
      return {
        name: "acqua",
        image: imgAcqua,
        icon: <Water />,
        color: "acquaGradient",
        sector: true,
      };
    }
    case "ambiente":
    case "Ambiente": {
      return {
        name: "ambiente",
        image: imgAmbiente,
        icon: <Leaf />,
        color: "ambienteGradient",
        sector: true,
      };
    }

    case "Csr Sostenibilità": {
      // return {
      //   name: "Csr Sostenibilità",
      //   image: imgAmbiente,
      //   icon: <Leaf />,
      //   color: "ambienteGradient",
      //   sector: true,
      // };
      return {
        name: "Csr Sostenibilità",
        image: imgAmministrazione,
        icon: <Book />,
        color: "amministrazione",
        sector: true,
      };
    }
    case "energia":
    case "Energia": {
      return {
        name: "energia",
        image: imgEnergia,
        icon: <Thunder />,
        color: "energiaGradient",
        sector: true,
      };
    }
    case "affari":
    case "Area Affari Regolatori": {
      return {
        name: "affari regolatori",
        image: imgRegolazione,
        icon: <Settings />,
        color: "affari",
      };
    }
    case "lavoro":
    case "lavoro <span>e</span> relazioni industriali":
    case "Area Lavoro e Relazioni Industriali":
    case "Area Lavoro Relazioni Industriali": {
      return {
        name: "lavoro e relazioni industriali",
        image: imgLavoro,
        icon: <Bag />,
        color: "lavoro",
      };
    }
    case "giuridico":
    case "Area Giuridico Legislativa Fiscale":
    case "Giuridico Legislativa Fiscale":
    case "Area Fiscale e Tributario":
    case "Appalti e Concessioni": {
      return {
        name: "giuridico legislativa fiscale",
        image: imgGiuridico,
        icon: <Steam />,
        color: "giuridico",
      };
    }
    case "comunicazione":
    case "Area Comunicazione": {
      return {
        name: "comunicazione",
        image: imgComunicazione,
        icon: <Chat />,
        color: "comunicazione",
      };
    }
    case "direzione generale":
    case "direzione-generale":
    case "Direzione Generale":
      return {
        name: "Direzione generale",
        image: imgAmministrazione,
        icon: <Book />,
        color: "amministrazione",
      };
    case "organizzazione e gestione interna":
    case "Organizzazione e Gestione Interna":
      return {
        name: "Organizzazione e Gestione Interna",
        image: imgAmministrazione,
        icon: <Book />,
        color: "amministrazione",
      };
    case "pianificazione strategia sostenibilita":
    case "Pianificazione Strategia Sostenibilita":
      return {
        name: "Pianificazione Strategia Sostenibilita",
        image: imgAmministrazione,
        icon: <Book />,
        color: "amministrazione",
      };
    case "amministrazione":
    case "Amministrazione": {
      return {
        name: "amministrazione",
        image: imgAmministrazione,
        icon: <Book />,
        color: "amministrazione",
      };
    }
    case "Area Strategie Pianificazione":
    case "area strategia pianificazione": {
      return {
        name: "area strategia pianificazione",
        image: imgStrategie,
        icon: <Target />,
        color: "strategie",
      };
    }
    case "Circolare":
      return {
        name: "Circolare",
        image: imgAmministrazione,
        icon: <Book />,
        color: "amministrazione",
      };
    default:
      return {
        name: "",
        image: "",
        icon: <div />,
        color: "",
      };
  }
}
