import "./IntroPressKit.scss";

const IntroPressKit = ({ section }) => (
  <div className="intro-press-kit">
    <div className="overlay pad-mobile">
      <span>News & Media</span>

      <span style={{ fontSize: 48, opacity: 0.5 }}>·</span>

      <h3>Press Kit</h3>

      {section && <span style={{ fontSize: 48, opacity: 0.5 }}>·</span>}
      {section && <h3>{section}</h3>}
    </div>
  </div>
);

export default IntroPressKit;
