import "./AreaList.scss";
import { ReactComponent as Settings } from "icons/faders-bold.svg";
import { ReactComponent as Bag } from "icons/tote-bold.svg";
import { ReactComponent as Steam } from "icons/shield-chevron-bold.svg";
import { ReactComponent as Chat } from "icons/chats-circle-bold.svg";
import { ReactComponent as Book } from "icons/book-bold.svg";
import { ReactComponent as Target } from "icons/target-bold.svg";
import { Link } from "react-router-dom";

const areas = [
  {
    title: "Direzione generale e Organi",
    icon: <Book />,
    to: "/direzione-generale/home",
  },
  {
    title: "Area affari regolatori",
    icon: <Settings />,
    to: "/area-affari-regolatori/home",
  },
  {
    title: "Area lavoro e relazioni industriali",
    icon: <Bag />,
    to: "/area-lavoro-relazioni-industriali/home",
  },
  {
    title: "Area giuridico legislativa e fiscale",
    icon: <Steam />,
    to: "/area-giuridico-legislativa-fiscale/home",
  },
  {
    title: "Area Strategie e Pianificazione di settore",
    icon: <Target />,
    to: "/area-strategie-pianificazione/home",
  },
  {
    title: "Area comunicazione",
    icon: <Chat />,
    to: "/area-comunicazione/home",
  },
];

const AreaList = () => (
  <div className="list-categories-component">
    {window.location.pathname !== "/" && (
      <h6 className="pad-mobile">Servizi e Aree Correlate</h6>
    )}

    <div className="list">
      {areas.map((element, i) => (
        <Link to={element.to} key={i} className="category">
          {element.icon}
          <h4
            style={{ fontSize: 18 }}
            dangerouslySetInnerHTML={{ __html: element.title }}
          />
        </Link>
      ))}
    </div>
    <div className="list" style={{marginTop: 20}}>
      <a href="https://www.sefit.org" className="category category--sefit" target="_blank">
          <h4
            style={{ fontSize: 18 }}
            dangerouslySetInnerHTML={{ __html: 'Utilitalia Sefit' }}
          />
          <h4
            style={{ fontSize: 14, marginTop:10, opacity:0.6 }}
            dangerouslySetInnerHTML={{ __html: 'Servizi Funerari Italiani' }}
          />
      </a>
      <div className="list__spacer"/>
    </div>
  </div>
);

export default AreaList;
