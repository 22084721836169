export default function format() {
  let address = window.location.pathname.split("/");
  if (address[1] !== "") {
    let arr = [];
    let exludeWords = ["e", "di"];
    arr = address[1].split("-");
    return (
      " - " +
      arr
        .map((word, i) => {
          return exludeWords.includes(word) && i !== 0
            ? [word]
            : word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ")
    );
  } else {
    return "";
  }
}

const capitalize = (text) => {
  const excluded = ["e", "di"];
  if (text && text.length > 1 && !excluded.some((e) => e === text)) {
    return text[0].toUpperCase() + text.slice(1, text.length).toLowerCase();
  }
  return text;
};

export function formatSubCategory(text) {
  return text
    .split(/<[^>]*>?/gm)
    .join("")
    .split(" ")
    .map((t) => capitalize(t))
    .join(" ");
}
