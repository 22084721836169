import ReactPaginate from "react-paginate";
import "./Pagination.scss";
import { ArrowLeft, ArrowRight } from "phosphor-react";

export default function Pagination(props) {
  return (
    <div className="pagination-section-component">
      <ReactPaginate
        previousLabel={<ArrowLeft />}
        nextLabel={<ArrowRight />}
        pageCount={props.totalPages}
        forcePage={props.pageNumber}
        marginPagesDisplayed={0}
        previousClassName={"left-arrow"}
        nextClassName={"right-arrow"}
        onPageChange={(pageNumber) => props.handlePageClick(pageNumber)}
        containerClassName={"pagination"}
        pageClassName={"paginationItem"}
        breakClassName={"break"}
        pageLinkClassName={"inactive"}
        activeLinkClassName={"active"}
        disabledClassName={"disabled"}
      />
    </div>
  );
}
