import React, { Component, useState } from "react";
import { Helmet } from "react-helmet-async";
import "./ChiSiamoOrgani.scss";
import IntroChiSiamo from "components/intro-chi-siamo/IntroChiSiamo";
import { CaretDown, CaretUp } from "phosphor-react";
import axios from "axios";
import Placeholders from "components/placeholders/Placeholders";

export default class ChiSiamoOrgani extends Component {
  state = {
    organi: [],
    list: [],
    loading: true,
  };

  async componentDidMount() {
    let organi = await axios.post(
      "https://api.utilitalia.it/DMS/Documents/List",
      {
        documentClasses: ["ORGANI"],
        heading: "\\CHI_SIAMO",
        folder: "ORGANI",
        documentValueDefs: [
          {
            code: "LIVELLO",
          },
          {
            code: "POSIZIONE",
          },
          {
            code: "CARICA",
          },
          {
            code: "NOME",
          },
          {
            code: "RUOLO",
          },
          {
            code: "AZIENDA",
          },
          {
            code: "CITTA",
          },
          {
            code: "FOTO",
          },
          {
            code: "BIOGRAFIA",
          },
        ],
        documentValueOrders: [
          {
            code: "LIVELLO",
            type: "3",
            direction: "asc",
          },
          {
            code: "POSIZIONE",
            type: "3",
            direction: "asc",
          },
        ],
      }
    );

    organi = organi?.data?.data?.documents;

    if (organi) {
      organi = organi.map((element) => {
        const foto = `https://cms.utilitalia.it/Organi/${
          element.values?.find((value) => value?.code === "FOTO")?.value?.file
            ?.name
        }`;
        return { ...element, foto };
      });
      this.setState({
        organi: organi,
      });
    }

    try {
      let list = await axios.post(
        "https://api.utilitalia.it/ORG/Commissions/List",
        {
          types: [
            "Giunta esecutiva",
            "Consiglio",
            "Collegio dei Revisori",
            "Commissione Garanti",
          ],
          loadRoles: "true",
        }
      );
      list = list?.data?.data?.commissions;
      list = list?.map(async (element) => {
        let members = element?.roles?.map(async (role) => {
          let test = await axios.post(
            "https://api.utilitalia.it/ORG/Members/List",
            {
              commissions: [element?.id],
              roles: [role?.id],
              orderByName: "true",
              showCompanyInfo: "true",
              showCommission: "true",
              showRole: "true",
            }
          );
          return {
            ...role,
            members: test?.data?.data?.members,
          };
        });
        members = await Promise.all(members);
        return {
          ...element,
          members,
        };
      });
      list = await Promise.all(list);
      this.setState({
        list: list,
      });
    } catch (err) {
      console.log("Error fetching Commisions List ChiSiamoOrgani", err);
    }
    this.setState({ loading: false });
  }

  render() {
    return (
      <div id="chi-siamo-organi">
        <Helmet>
          <title>Utilitalia - Organi</title>
          <meta
            name="description"
            content="Visita il sito e scopri tutti i membri che fanno parte del team di Utilitalia."
          />
        </Helmet>

        <IntroChiSiamo section="Organi" />

        <div className="container pad-mobile">
          <h2 className="general">Organi</h2>
          <div className="organi-list">
            {this.state.loading ? (
              <Placeholders number={3} type="organo" />
            ) : (
              this.state.organi.map((element, i) => (
                <div className="organo" key={i}>
                  <div className="left">
                    <img src={element.foto} alt="" />
                    <div className="main-info">
                      <h4>
                        {element.values.find((st) => st.code === "RUOLO").value}
                      </h4>
                      <h2>
                        {element.values.find((st) => st.code === "NOME").value}
                      </h2>
                      <h3>
                        {element.values.find((st) => st.code === "CARICA")
                          .value +
                          " " +
                          element.values.find((st) => st.code === "AZIENDA")
                            .value +
                          " " +
                          element.values.find((st) => st.code === "CITTA")
                            .value}
                      </h3>
                    </div>
                  </div>
                  <div className="right">
                    <h3
                      dangerouslySetInnerHTML={{
                        __html:
                          element &&
                          element.values &&
                          element.values.find((st) => st.code === "BIOGRAFIA")
                            ? element.values.find(
                                (st) => st.code === "BIOGRAFIA"
                              ).value
                            : "",
                      }}
                    />
                  </div>
                </div>
              ))
            )}
          </div>
          <div className="organi-full">
            {this.state.list.map((element, i) => (
              <Organ element={element} number={i} key={i} />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

function Organ(props) {
  const [state, setState] = useState();

  return (
    <div className={`commissione ${state ? " active" : ""}`}>
      <div className="title" onClick={() => setState(!state)}>
        <div className="left">
          <div className="number">{props.number + 1}</div>
          <h3>{props.element.name}</h3>
        </div>

        {!state ? (
          <CaretDown size={36} weight={"bold"} />
        ) : (
          <CaretUp size={36} weight={"bold"} />
        )}
      </div>

      {state && (
        <div>
          {props.element.members.map((element, i) => (
            <Position key={i} subElement={element} />
          ))}
        </div>
      )}
    </div>
  );
}

function Position({ subElement }) {
  const [state, setState] = useState();

  return (
    <div className="role">
      <h4 onClick={() => setState(!state)}>{subElement.name}</h4>
      {state && (
        <div>
          {subElement.members.map((element, i) => (
            <div className="person" key={i}>
              <h5>
                - {element.firstName} {element.lastName}{" "}
                <span>
                  ({element.companyAcronym} - {element.companyName})
                </span>
              </h5>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
