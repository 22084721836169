import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import moment from "moment";
import "./Atti.scss";
import Pagination from "components/pagination/Pagination";
import { ReactComponent as Timer } from "icons/timer-bold.svg";
import Placeholders from "components/placeholders/Placeholders";
import { BsArrowRight as ArrowRight } from "react-icons/bs";
import Filter from "components/filter/Filter";

const isAllDay = (date1, date2) => {
  if (moment(date1).format("LLL") === moment(date2).format("LLL")) return true;
  else return false;
};

const isSameDate = (date1, date2) => {
  if (moment(date1).format("L") === moment(date2).format("L")) return true;
  else return false;
};

const colorEvent = (type) => {
  switch (type) {
    case "EVENTO INTERAMENTE UTILITALIA":
      return {
        color: "#eb640c",
        text: "Evento Utilitalia",
      };
    case "EVENTI ESTERNI DA SEGNALARE":
      return {
        color: "#00447b",
        text: "Utilitalia segnala",
      };
    case "EVENTO PATROCINATO O IN COLLABORAZIONE":
      return {
        color: "#07ca6e",
        text: "Patrocinio Utilitalia",
      };
    default:
      return {
        color: "#00447b",
        text: type,
      };
  }
};

const activeYear = moment().year();

const years = [];

for (let i = 2015; i <= activeYear; i++) {
  years.unshift(i);
}

const initialStateFilter = {
  eventi: [],
  from: 1,
  loading: true,
  startDate: activeYear + "-01-01",
  endDate: null,
  active: {
    year: "",
  },
  years,
};

export default class Atti extends Component {
  state = initialStateFilter;

  async componentDidMount() {
    const bakFilter = sessionStorage.getItem("atti_filter");
    if (bakFilter) {
      const value = bakFilter;
      this.setState({
        active: {
          year: value,
        },
        startDate: value + "-01-01",
        endDate: value + "-12-31",
      });
    }

    this.fetchEventi();
  }

  componentDidUpdate(oldProps, oldState) {
    if (this.state.startDate !== oldState.startDate) {
      sessionStorage.setItem("atti_filter", this.state.active.year);
      this.fetchEventi();
    }
  }

  fetchEventi = async () => {
    this.setState({ loading: true });
    try {
      const response = await axios.post(
        "https://api.utilitalia.it/AGE/Events/List",
        {
          calendars: ["Calendario interno"],
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          showOnlyWithTract: "true",
          orderDirection: "desc",
          pageStart: this.state.from,
          pageSize: "20",
        }
      );
      this.setState({
        eventi: response?.data?.data?.appointments || [],
      });
    } catch (err) {
      console.log("Error fetching eventi", err);
    }
    this.setState({ loading: false });
  };

  handleChange = (value, target) => {
    this.setState({
      active: {
        year: value,
      },
      startDate: value + "-01-01",
      endDate: value + "-12-31",
    });
  };

  handleReset = () => {
    this.setState(initialStateFilter);
  };

  render() {
    const filters = [
      {
        name: "year",
        text: "Seleziona l'anno",
        values: years,
      },
    ];
    return (
      <div id="atti">
        <Helmet>
          <title>Utilitalia - Atti</title>
          <meta
            name="description"
            content="In questa sezione trovi tutti gli atti pubblicati da Utilitalia. Scopri di più "
          />
        </Helmet>
        <div className="container pad-mobile">
          <div className="heading">
            <h2 className="general">Atti</h2>
            <Link to="/pubblicazioni/list">
              <span className="general">Pubblicazioni</span>
              <ArrowRight />
            </Link>
          </div>
          <Filter
            filters={filters}
            change={this.handleChange}
            reset={this.handleReset}
            value={this.state.active}
          />
          <div className="eventi-list">
            {this.state.loading ? (
              <Placeholders number={9} type="evento" />
            ) : (
              this.state.eventi.map((element, i) => {
                const id = element?.talkingId !== null && element?.talkingId.trim() !== '' && element?.talkingId !== false ? element?.talkingId : element.id; 
                return (
                  <div className="event" key={i}>
                    <div
                      className="heading"
                      style={{ background: colorEvent(element.type).color }}
                    >
                      <span>
                        {moment(element.startDate).format("DD MMM YYYY")}
                      </span>
                      <span>{colorEvent(element.type).text}</span>
                    </div>
                    <div className="body">
                      <div className="top">
                        <p>{element.subject}</p>
                      </div>
                      <div className="bottom">
                        <div className="time">
                          <Timer />{" "}
                          {isSameDate(element.startDate, element.endDate) ? (
                            isAllDay(element.startDate, element.endDate) ? (
                              <span>Tutto il giorno</span>
                            ) : (
                              <span>
                                {moment(element.startDate).format("HH:mm")} -{" "}
                                {moment(element.endDate).format("HH:mm")}
                              </span>
                            )
                          ) : (
                            <span>
                              {moment(element.startDate).format("DD/MM")} -{" "}
                              {moment(element.endDate).format("DD/MM")}
                            </span>
                          )}
                        </div>
                        <Link to={"/atto/" + id} className="btn small">
                          Dettaglio
                        </Link>
                      </div>
                    </div>
                  </div>
                )})
            )}
            {this.state.totalPages > 1 && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: 32,
                }}
              >
                <Pagination
                  totalPages={this.state.totalPages}
                  handlePageClick={this.handlePageClick}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
