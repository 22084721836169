import { useEffect, useState } from "react";
import axios from "axios";
import IntroChiSiamo from "components/intro-chi-siamo/IntroChiSiamo";
import "./LegalPage.scss";

const renderText = (text) => {
  switch (text) {
    case "/legal/privacy-policy": {
      return {
        string: "PRIVACY_POLICY",
        title: "Privacy <span>Policy</span>",
        sector: "Privacy Policy",
      };
    }
    case "/legal/note-legali": {
      return {
        string: "NOTE_LEGALI",
        title: "Note <span>Legali</span>",
        sector: "Note Legali",
      };
    }
    case "/legal/comunicazioni": {
      return {
        string: "SOCIAL_MEDIA",
        title: "Social Media <span>Policy</span>",
        sector: "Social Media Policy",
      };
    }
    case "/legal/disclaimer": {
      return {
        string: "DISCLAIMER",
        title: "<span>DISCLAIMER</span>",
        sector: "Disclaimer",
      };
    }
    default:
  }
};

export default function LegalPage(props) {
  const [content, setContent] = useState();

  useEffect(() => {
    axios
      .post("https://api.utilitalia.it/DMS/Documents/List", {
        documentClasses: ["CONTENUTO_WEB"],
        heading: "\\LEGAL",
        folder: renderText(props.match.url).string,
        documentValueOrders: [
          {
            code: "TITOLO",
            type: "1",
            direction: "asc",
          },
        ],
      })
      .then((response) => {
        const content = response?.data?.data?.documents[0]?.values?.find(
          (value) => value.code === "TESTO"
        )?.value;
        if (content) {
          setContent(content);
        }
      });
  }, [props.match.url]);

  return (
    <div className="legal-page">
      <IntroChiSiamo
        legal={true}
        section={renderText(props.match.url).sector}
      />

      <div className="container">
        <h2
          className="general"
          dangerouslySetInnerHTML={{
            __html: renderText(props.match.url).title,
          }}
        />

        <div
          className="privacy-container"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        {renderText(props.match.url).string === "DISCLAIMER" && (
          <div>
            <h3 style={{ paddingBottom: "0.5rem" }}>Informativa privacy</h3>
            <p style={{ paddingBottom: "1rem" }}>
              Questa email ha per destinatari dei contatti presenti negli
              archivi di Utilitalia. Tutte le informazioni vengono trattate e
              tutelate nel rispetto della normativa vigente sulla protezione dei
              dati personali (Reg. EU 2016/679) come da informative presenti sul sito <a href="https://www.utilitalia.it">www.utilitalia.it</a> nella sezione Legal. Per richiedere informazioni e/o
              variazioni e/o la cancellazione dei vostri dati presenti nei
              nostri archivi potete inviare una email a privacy@utilitalia.it
            </p>
            <h3 style={{ paddingBottom: "0.5rem" }}>Avviso di Riservatezza</h3>
            <p>
              Il contenuto di questa e-mail e degli eventuali allegati, è
              strettamente confidenziale e destinato alla/e persona/e a cui è
              indirizzato. Se avete ricevuto per errore questa e-mail, vi
              preghiamo di segnalarcelo immediatamente e di cancellarla dal
              vostro computer. E' fatto divieto di copiare e divulgare il
              contenuto di questa e-mail. Ogni utilizzo abusivo delle
              informazioni qui contenute da parte di persone terze o comunque
              non indicate nella presente e-mail, potrà essere perseguito ai
              sensi di legge.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
