export function renderAttribute(array, code) {
  return (
    array &&
    array.find((value) => value.code === code) &&
    array.find((value) => value.code === code).value
  );
}
export function filterAttribute(array, code) {
  return (
    array &&
    array.find((value) => value.code === code) &&
    array.filter((value) => value.code === code)
  );
}
