import React, { useEffect, useState } from "react";
import "./Switch.scss";

function Switch(props) {
  const [isChecked, setIsChecked] = useState(props.alwaysChecked || false);

  useEffect(() => {
    if (!props.alwaysChecked) {
      props.setCookieTypesStatus({
        ...props.cookieTypesStatus,
        [props.target]: isChecked,
      });
    }
  }, [isChecked]);

  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div
      className="switch"
      style={props.alwaysChecked && { opacity: 0.4, pointerEvents: "none" }}
    >
      <input
        onChange={() => handleChange()}
        type="checkbox"
        id={props.name}
        checked={isChecked}
      />
      <label htmlFor={props.name}></label>
    </div>
  );
}

export default Switch;
