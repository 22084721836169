import moment from "moment";
import { Link } from "react-router-dom";
import "./CardEvent.scss";

export default function CardEvent({ element }) {
  return (
    <div className="card-event">
      <div className="heading" style={{ background: "#eb640c" }}>
        <span>{moment(element.date).format("DD MMM YYYY")}</span>

        <span></span>
      </div>

      <div className="body">
        <div className="top">
          <p>{element.subject}</p>
        </div>

        <div className="bottom">
          <div className="time"></div>

          <Link to={"/evento/" + element.id} className="btn small">
            Dettaglio
          </Link>
        </div>
      </div>
    </div>
  );
}

// const isAllDay = (date1, date2) => {
//   if (moment(date1).format("LLL") === moment(date2).format("LLL")) return true;
//   else return false;
// };
// const isSameDate = (date1, date2) => {
//   if (moment(date1).format("L") === moment(date2).format("L")) return true;
//   else return false;
// };

// const colorEvent = (type) => {
//   switch (type) {
//     case "EVENTO INTERAMENTE UTILITALIA":
//       return {
//         color: "#eb640c",
//         text: "Evento Utilitalia",
//       };
//     case "EVENTI ESTERNI DA SEGNALARE":
//       return {
//         color: "#00447b",
//         text: "Utilitalia segnala",
//       };
//     case "EVENTO PATROCINATO O IN COLLABORAZIONE":
//       return {
//         color: "#07ca6e",
//         text: "Patrocinio Utilitalia",
//       };
//     default:
//       return {
//         color: "#00447b",
//         text: type,
//       };
//   }
// };
