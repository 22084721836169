import "./DocumentsList.scss";
import { ReactComponent as FileBold } from "icons/file-bold.svg";
import { RiPushpin2Line as Pin } from "react-icons/ri";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Placeholders from "components/placeholders/Placeholders";
import { truncate } from "functions/formatTextFunctions";

function renderIcon(element) {
  switch (element) {
    case "CIRCOLARI":
      return (
        <FileBold
          className="category"
          style={{
            background: "var(--blueColor)",
          }}
        />
      );
    default:
  }
}

export default function CircularsList(props) {
  return (
    <div
      className="circulars-list-component"
      style={{
        gridTemplateColumns: `repeat(${props.col}, minmax(0, 1fr))`,

        // height: isMobile && 400,
      }}
    >
      {props.loading ? (
        <Placeholders type="associata" number={props.col === "2" ? 2 : 9} />
      ) : (
        props.documents &&
        props.documents.map((element, i) => {
            const id = element?.talkingId !== null && element?.talkingId.trim() !== '' && element.talkingId !== false ? element?.talkingId : element?.id;
            return (
                  <div key={i} className="circular">
                    <Pin className="pin" />
                    {element.values[1].value && (
                      <div
                        style={{
                          position: "absolute",
                          top: 16,
                          left: isMobile ? 16 : 16,
                          color: "#888",
                        }}
                      >
                        {element.values[0].value && (
                          <>
                            <b>{element.values[0].value}</b>
                            {" - "}
                          </>
                        )}
                        {new Date(element.values[1].value).toLocaleDateString("it-IT")}
                      </div>
                    )}
                    {renderIcon(element.documentClass)}
                    <div className="content">
                      <h5 style={{ marginBottom: 8 }}>
                        <div style={{ height: isMobile ? "5.6rem" : "4.2rem" }}>
                          <div className="fade">
                            {truncate(element.values[2].value, isMobile ? 80 : 150)}
                            {/* {element.oggetto} */}
                          </div>
                        </div>
                      </h5>
                      <div className="incipit" style={{ marginBottom: 48 }}>
                        <div style={{ height: isMobile ? "5.6rem" : "8.4rem" }}>
                          <div className="fade">
                            {truncate("", isMobile ? 50 : 120)}
                            {/* {element.incipit} */}
                          </div>
                        </div>
                      </div>
                      <Link
                        to={"/circolare/archivio/" + id}
                        className="btn small"
                      >
                        Leggi di più
                      </Link>
                    </div>
                  </div>
                )})
      )}
    </div>
  );
}
