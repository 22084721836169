export function truncate(str, length) {
  if (str.length > length) {
    let trimmedString = str.substr(0, length);
    trimmedString = trimmedString.substr(
      0,
      Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
    );
    return trimmedString.concat("...");
  } else return str;
}

export function urlify(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const splitRegex = /\n/g;
  const stringWithUrl = text.replace(urlRegex, function (url) {
    return '<a href="' + url + '">' + url + "</a>";
  });

  return stringWithUrl.replace(splitRegex, function () {
    return "<br/>";
  });
}
