import axios from "axios";
import { ReactComponent as Water } from "icons/drop-bold.svg";
import { ReactComponent as Leaf } from "icons/leaf-bold.svg";
import { ReactComponent as Thunder } from "icons/lightning-bold.svg";
import { ReactComponent as Settings } from "icons/faders-bold.svg";
import { ReactComponent as Bag } from "icons/tote-bold.svg";
import { ReactComponent as Steam } from "icons/shield-chevron-bold.svg";
import { ReactComponent as Chat } from "icons/chats-circle-bold.svg";
import { ReactComponent as Book } from "icons/book-bold.svg";
import { ReactComponent as Target } from "icons/target-bold.svg";
import imgAcqua from "img/settori-aree/acqua.jpg";
import imgAmbiente from "img/settori-aree/ambiente.jpg";
import imgEnergia from "img/settori-aree/energia.jpg";
import imgAmministrazione from "img/settori-aree/amministrazione.jpg";
import imgGiuridico from "img/settori-aree/legislativa.jpg";
import imgLavoro from "img/settori-aree/lavoro.jpg";
import imgRegolazione from "img/settori-aree/affari-regolatori.jpg";
import imgComunicazione from "img/settori-aree/comunicazione.jpg";
import imgStrategie from "img/settori-aree/strategie.jpg";

export function renderCategory(category) {
  switch (category) {
    case "/acqua/home":
    case "/acqua/home/": {
      return {
        name: "acqua",
        image: imgAcqua,
        icon: <Water />,
        gradient: "acquaGradient",
        color: "acqua",
        sector: true,
        motto: true,
        subcategory: false,
        title: "Utilitalia - Acqua",
        description:
          "Utilitalia rappresenta la quasi totalità degli operatori dei servizi idrici in Italia. Visita il nostro sito per avere maggiori informazioni",
        features: [
          {
            title: "Associa gli operatori",
            text: "Utilitalia associa gli operatori dei servizi Idrici, dall’acquedotto alla depurazione: captazione, adduzione, potabilizzazione, distribuzione, fognatura, collettamento e infine depurazione dei reflui. Associa gli Enti e le strutture che a diverso titolo pianificano e progettano i sistemi idrici su scala locale.",
          },
          {
            title: "Rappresenta gli operatori",
            text: "Utilitalia rappresenta la quasi totalità degli operatori dei servizi idrici in Italia.",
          },
          {
            title: "Supporta le imprese associate",
            text: "Offre supporto alle associate sulle questioni normative, regolatorie, tariffarie e di sviluppo tecnologico e nella predisposizione di analisi, piani economici e finanziari.",
          },
        ],
        boxes: [
          {
            icon: "file",
            title: "Tutte le <span>circolari</span>",
            text: "All'interno della sezione tutte le circolari dell'Area",
            to: "/acqua/circolari",
          },
          {
            icon: "intersect",
            title: "Studi e <span>ricerche</span>",
            text: "All'interno della sezione gli studi dell'Area",
            to: "/acqua/studi-e-ricerche/list",
          },
          {
            icon: "envelope",
            title: "I nostri <span>contatti</span>",
            email: "acqua@utilitalia.it",
            tel: "06 945282.30",
            fax: "06 94528202",
            color: "var(--acqua)",
            to: "/acqua/contatti",
          },
        ],
      };
    }
    case "/ambiente/home":
    case "/ambiente/home/": {
      return {
        name: "ambiente",
        image: imgAmbiente,
        icon: <Leaf />,
        gradient: "ambienteGradient",
        color: "ambiente",
        motto: true,
        sector: true,
        subcategory: false,
        title: "Utilitalia - Ambiente",
        description:
          "Rappresenta le imprese dei servizi pubblici di igiene ambientale presso autorità europee, nazionali e regionali, le Commissioni parlamentari e i Ministeri competenti.",
        features: [
          {
            title: "Rappresenta le imprese",
            text: "Rappresenta le imprese dei servizi pubblici di igiene ambientale presso autorità europee, nazionali e regionali, le Commissioni parlamentari e i Ministeri competenti.",
          },
          {
            title: "Realizza studi",
            text: "Realizza studi di carattere tecnico, scientifico, economico e finanziario sui molteplici aspetti del ciclo di gestione dei rifiuti urbani.",
          },
          {
            title: "Assistenza",
            text: "Informa le imprese associate sulle evoluzioni e le novità legislative e normative di settore.",
          },
          {
            title: "Informa le imprese associate",
            text: "Offre assistenza tecnico amministrativa sui vari aspetti del ciclo integrato dei rifiuti urbani: pulizia e decoro urbano, raccolta differenziata, autorizzazione e gestione impianti, applicazione degli accordi e normativa di settore e promuove la corretta informazione e la sensibilizzazione di tutti i soggetti responsabili a diverso titolo della produzione e della gestione dei rifiuti urbani.",
          },
        ],
        boxes: [
          {
            icon: "file",
            title: "Tutte le <span>circolari</span>",
            text: "All'interno della sezione tutte le circolari dell'Area",
            to: "/ambiente/circolari",
          },
          {
            icon: "question",
            title: "Tutti i <span>quesiti</span>",
            text: "Tutte le risposte ai quesiti posti dalle nostre associate",
            to: "/ambiente/quesiti",
          },
          {
            icon: "intersect",
            title: "Studi e <span>ricerche</span>",
            text: "All'interno della sezione gli studi dell'Area",
            to: "/ambiente/studi-e-ricerche/list",
          },
          // {
          //   icon: "file-text",
          //   title: "CSR e <span>sostenibilita</span>",
          //   text: "La sezione del sito di Utilitalia dedicata alla csr e alla Sostenibilita",
          //   to: "/ambiente/csr-sostenibilita/home",
          // },
          {
            icon: "envelope",
            title: "I nostri <span>contatti</span>",
            email: "ambiente@utilitalia.it",
            tel: "06 945282.90",
            //fax: "06 945282.00",
            color: "var(--ambiente)",
            to: "/ambiente/contatti",
          },
        ],
      };
    }
    // case "/ambiente/csr-sostenibilita/home":
    // case "/ambiente/csr-sostenibilita/home/": {
    //   return {
    //     name: "ambiente",
    //     image: imgAmbiente,
    //     icon: <Leaf />,
    //     gradient: "ambienteGradient",
    //     color: "ambiente",
    //     subcategory: "csr <span>e</span> sostenibilita",
    //     sector: true,
    //     features: [
    //       {
    //         title: "Rappresenta le imprese",
    //         text: "Benvenuti nella sezione del sito di Utilitalia dedicata alla CSR e alla Sostenibilita, temi ormai strategici per tutte le Associate.",
    //       },
    //       {
    //         title: "Realizza studi",
    //         text: "L’azione di Utilitalia è finalizzata ad incoraggiare, affiancare e promuovere presso le Associate l’attività di rendicontazione non finanziaria, attraverso percorsi informativi, formativi e di condivisione di buone pratiche.",
    //       },
    //     ],
    //     boxes: [
    //       {
    //         icon: "file",
    //         title: "Misurarsi per <span>migliorare</span>",
    //         text: "All'interno della sezione tutte le circolari dell'Area",
    //         to: "/ambiente/csr-sostenibilita/misurarsi-per-migliorare/category-home",
    //       },
    //       {
    //         icon: "line",
    //         title: "Bilanci di <span>sostenibilita</span>",
    //         text: "In questa sezione sono raccolti per annualità i Bilanci di Sostenibilita delle Utility associate.",
    //         to: "/ambiente/csr-sostenibilita/bilanci-sostenibilita/list",
    //       },
    //       {
    //         icon: "question",
    //         title: "Attività del <span>GDL</span>",
    //         text: "Nella sezione sono raccolti i verbali delle riunioni del gruppo di lavoro, i principali documenti di interesse e tutte le comunicazioni riguardanti il gruppo di lavoro. ",
    //         to: "/ambiente/csr-sostenibilita/attivita-gdl/category-home",
    //       },
    //       {
    //         icon: "intersect",
    //         title: "Studi e <span>documentazione</span>",
    //         text: "All'interno della sezione gli studi e le ricerche dell'Area.",
    //         to: "/ambiente/csr-sostenibilita/studi-e-documentazione/list",
    //       },
    //       {
    //         icon: "file-text",
    //         title: "Convegni e <span>seminari</span>",
    //         text: "Utilitalia, in collaborazione con il GdL “Csr e Sostenibilita”, ha realizzato appuntamenti convegnistici e formativi sui temi di principale interesse.",
    //         to: "/ambiente/csr-sostenibilita/convegni-e-seminari/list",
    //       },
    //       {
    //         icon: "envelope",
    //         title: "I nostri <span>contatti</span>",
    //         email: "ambiente@utilitalia.it",
    //         tel: "06 945282.90",
    //         fax: "06 945282.00",
    //         color: "var(--ambiente)",
    //         to: "/ambiente/csr-sostenibilita/contatti",
    //       },
    //     ],
    //   };
    // }
    case "/energia/home":
    case "/energia/home/": {
      return {
        name: "energia",
        image: imgEnergia,
        icon: <Thunder />,
        gradient: "energiaGradient",
        color: "energia",
        motto: true,
        sector: true,
        subcategory: false,
        title: "Utilitalia - Energia",
        description:
          "Utilitalia favorisce tutti i processi di innovazione tecnologica e di processo e supporta l’attività delle Associate nella prospettiva dello sviluppo dei servizi smart e delle smart grids",
        features: [
          {
            title: "Rappresenta le imprese",
            text: "L'impegno dell'Area Energia è volto al confronto con le Istituzioni per promuovere - attraverso un quadro normativo e regolatorio coerente e certo - il rispetto delle dinamiche concorrenziali, lo sviluppo del sistema energetico nazionale e la sostenibilita ambientale, come definita negli impegni europei e internazionali assunti dal nostro Paese.",
          },
          {
            title: "Sfrutta il potenziale",
            text: "La Federazione si adopera inoltre perché venga sfruttato pienamente il potenziale offerto dall’efficienza energetica, capace di combinare sostenibilita ambientale, innovazione tecnologica e risparmio.",
          },
          {
            title: "Favorisce i processi di innovazione tecnologica",
            text: "Utilitalia favorisce tutti i processi di innovazione tecnologica e di processo e supporta l’attività delle Associate nella prospettiva dello sviluppo dei servizi smart e delle smart grids, attraverso la promozione di sperimentazione di applicazioni tecnologicamente avanzate.",
          },
        ],
        boxes: [
          {
            icon: "file",
            title: "Tutte le <span>circolari</span>",
            text: "All'interno della sezione tutte le circolari dell'Area",
            to: "/energia/circolari",
          },
          {
            icon: "intersect",
            title: "Portale <span>Ambiti</span>",
            text: "<strong style=\"color:red;text-transform:uppercase\">Servizio temporaneamente sospeso</strong><br/>Fornisce agli Associati della Federazione notizie e aggiornamenti utili sugli ambiti di gara relativi al settore della distribuzione del gas. Una piattaforma semplice e intuitiva che permette di ottenere una fotografia del contesto normativo e competitivo nazionale.",
            to: "/energia/portale-ambiti/page",
          },
          {
            icon: "file-text",
            title: "Bollettino <span>finanziamenti</span>",
            text: "Focus mensile dei programmi e delle scadenze dei programmi di finanziamento agevolato per le Imprese",
            to: "/energia/bollettino-finanziamenti/list",
          },
          {
            icon: "envelope",
            title: "I nostri <span>contatti</span>",
            email: "reti.energia@utilitalia.it",
            tel: "06 945282.40",
            fax: "06 94528202",
            color: "var(--energia)",
            to: "/energia/contatti",
          },
        ],
      };
    }
    case "/area-affari-regolatori/home":
    case "/area-affari-regolatori/home/": {
      return {
        name: "affari regolatori",
        image: imgRegolazione,
        icon: <Settings />,
        gradient: "affari",
        color: "affari",
        subcategory: false,
        title: "Utilitalia - Affari Regolatori",
        description:
          "L'Area Affari Regolatori nasce sul finire del 2017 per creare un presidio organizzativo. Visita il sito e scopri di più",
        features: [
          {
            title: "Origine",
            text: "L'Area Affari Regolatori nasce sul finire del 2017 per creare un presidio organizzativo trasversale sulle tematiche relative all’attività di regolamentazione delle infrastrutture e dei mercati posta in capo ad ARERA (Autorità di Regolazione per Energia Reti e Ambiente).",
          },
          {
            title: "Scopo",
            text: "L'Area si occupa di monitorare, valutare e analizzare l'evoluzione degli aspetti di regolazione economico-tariffaria, e della qualità tecnica e commerciale dei servizi regolati nei settori dell'energia elettrica, del gas, dei servizi idrici e ambientali.",
          },
          {
            title: "Attività",
            text: "L'attività dell'Area consente quindi di raccogliere e coordinare le posizioni dei propri Associati, guidando i processi di cambiamento promossi dalla regolazione di settore, valutandone l’impatto sul sistema associativo e rafforzando il ruolo di rappresentanza di Utilitalia presso gli stakeholder nazionali e comunitari.",
          },
        ],
        boxes: [
          {
            icon: "file",
            title: "Tutte le <span>circolari</span>",
            text: "All'interno della sezione tutte le circolari dell'Area",
            to: "/area-affari-regolatori/circolari",
          },
          {
            icon: "file-text",
            title: "Norme e <span>regole</span>",
            text: "Sezione dedicata alla gestione delle tematiche regolatorie...",
            to: "/area-affari-regolatori/norme-e-regole/home",
          },
          {
            icon: "envelope",
            title: "I nostri <span>contatti</span>",
            email: "regolatorio@utilitalia.it",
            tel: "06 945282.50",
            fax: "06 94528202",
            color: "var(--affari)",
            to: "/area-affari-regolatori/contatti",
          },
        ],
      };
    }
    case "/area-affari-regolatori/norme-e-regole/home":
    case "/area-affari-regolatori/norme-e-regole/home/": {
      return {
        name: "affari regolatori",
        image: imgRegolazione,
        icon: <Settings />,
        gradient: "affari",
        color: "affari",
        subcategory: "Norme e Regole",
        features: [
          {
            text: " Gestione delle tematiche regolatorie, in grado di raccogliere e coordinare le posizioni dei propri associati rispetto alle questioni ed alle proposte introdotte dal Regolatore, consentirà pertanto di rafforzare il ruolo di rappresentanza di Utilitalia nei confronti di alcuni stakeholder istituzionali, rispetto ad un'area tematica trasversale al proprio tessuto associativo. ",
          },
        ],
        boxes: [
          {
            icon: "file",
            title: "Antitrust",
            text: "All'interno della sezione tutte le tematiche regolatorie dell'Antitrust",
            to: "/area-affari-regolatori/norme-e-regole/antitrust/list",
          },
          {
            icon: "file",
            title: "UE",
            text: "All'interno della sezione tutte le tematiche regolatorie dell' Unione Europea",
            to: "/area-affari-regolatori/norme-e-regole/ue/list",
          },
          {
            icon: "file",
            title: "Acqua",
            text: "All'interno della sezione tutte le tematiche regolatorie sull'acqua",
            to: "/area-affari-regolatori/norme-e-regole/acqua/list",
          },
          {
            icon: "file",
            title: "Energia",
            text: "All'interno della sezione tutte le tematiche regolatorie inerenti l'energia",
            to: "/area-affari-regolatori/norme-e-regole/energia/list",
          },
          {
            icon: "file",
            title: "Ambiente",
            text: "All'interno della sezione tutte le tematiche regolatorie dell'ambiente",
            to: "/area-affari-regolatori/norme-e-regole/ambiente/list",
          },
        ],
      };
    }
    case "/area-lavoro-relazioni-industriali/home":
    case "/area-lavoro-relazioni-industriali/home/": {
      return {
        name: "lavoro <span>e</span> relazioni industriali",
        image: imgLavoro,
        icon: <Bag />,
        gradient: "lavoro",
        color: "lavoro",
        subcategory: false,
        title: "Utilitalia - Lavoro e Relazioni Industriali",
        description:
          "Nel settore energetico, ambientale e idrico, Utilitalia è un punto di riferimento per quanto concerne i contratti collettivi nazionali di lavoro e i rapporti con le Organizzazioni sindacali",
        features: [
          {
            title: "Mission",
            text: "Nel settore energetico, ambientale e idrico, Utilitalia è un punto di riferimento per quanto concerne i contratti collettivi nazionali di lavoro e i rapporti con le Organizzazioni sindacali.",
          },
          {
            title: "CCNL SETTORE GAS-ACQUA",
            text: "Il CCNL del settore Gas-Acqua, che Utilitalia sottoscrive insieme alle associazioni settoriali di Confindustria, è applicato a circa 47.000 lavoratori complessivi, di cui oltre 41.000 dipendenti delle imprese associate.",
          },
          {
            title: "CCNL SETTORE ELETTRICO",
            text: "Utilitalia è firmataria - con l’Associazione settoriale di Confindustria e con Enel, Terna, Sogin e GSE - anche del CCNL del settore Elettrico, applicato a poco più di 52.600 addetti complessivi, di cui circa 13.900 dipendenti delle imprese associate.",
          },
          {
            title: "CCNL SERVIZI AMBIENTALI",
            text: "Utilitalia è infine titolare del CCNL dei servizi ambientali, applicato a circa 47.000 lavoratori delle imprese associate.",
          },
        ],
        boxes: [
          {
            icon: "file",
            title: "Tutte le <span>circolari</span>",
            text: "All'interno della sezione tutte le circolari dell'Area",
            to: "/area-lavoro-relazioni-industriali/circolari",
          },
          {
            icon: "file-text",
            title: "Dati <span>Statistici</span>",
            text: "I dati elaborati trattano argomenti quali il costo del lavoro e l’occupazione nel settore.",
            to: "/area-lavoro-relazioni-industriali/dati-statistici/list",
          },
          {
            icon: "file-text",
            title: "Quesiti",
            text: "Tutte le risposte ai quesiti posti dalle nostre associate",
            to: "/area-lavoro-relazioni-industriali/quesiti",
          },
          {
            icon: "file-text",
            title: "Normativa",
            text: "Segnalazioni delle ultime novità normative e delle recenti pronunce giurisprudenziali",
            to: "/area-lavoro-relazioni-industriali/normativa/list",
          },
          {
            icon: "file-text",
            title: "Giurisprudenza",
            text: "Segnalazioni delle ultime novità normative e delle recenti pronunce giurisprudenziali",
            to: "/area-lavoro-relazioni-industriali/giurisprudenza/list",
          },
          {
            icon: "file-text",
            title: "Contratti <span>Collettivi</span>",
            text: "La federazione è firmataria del CCNL del settore Gas-Acqua, del CCNL del settore Elettrico e del CCNL dei servizi ambientali.",
            to: "/area-lavoro-relazioni-industriali/contratti/list",
          },
          {
            icon: "envelope",
            title: "I nostri <span>contatti</span>",
            email: "lavoro@utilitalia.it",
            tel: "06 945282.60",
            fax: "06 94528200",
            color: "var(--lavoro)",
            to: "area-lavoro-relazioni-industriali/contatti",
          },
        ],
      };
    }
    case "/area-giuridico-legislativa-fiscale/home":
    case "/area-giuridico-legislativa-fiscale/home/": {
      return {
        name: "giuridico legislativa fiscale",
        image: imgGiuridico,
        icon: <Steam />,
        gradient: "giuridico",
        color: "giuridico",
        subcategory: false,
        title: "Utilitalia - Giuridico Legislativo Fiscale",
        description:
          "Utilitalia svolge per le proprie associate un ampio ed attento monitoraggio delle maggiori novità normative e giurisprudenziali in materia di servizi di interesse economico generale a rete (SIEG)",
        features: [
          {
            title: "Monitoraggio",
            text: "Utilitalia svolge per le proprie associate un ampio ed attento monitoraggio delle maggiori novità normative e giurisprudenziali in materia di servizi di interesse economico generale a rete (SIEG), fornendo il necessario supporto alla risoluzione delle conseguenti problematiche interpretative ed attuative. In particolare, effettua un costante approfondimento della disciplina inerente l’organizzazione dei servizi (modalità di affidamento, governance, vincoli retributivi, etc.), assicurando un valido ausilio alle associate.",
          },
          {
            title: "Assistenza",
            text: "L'area, cui fa capo il Gruppo Tecnico Trasversale Giuridico-legislativo e Fiscale (GTT Giuridico), offre assistenza alle associate anche sui temi inerenti la disciplina degli appalti e delle concessioni, nonché quella fiscale e tributaria.",
          },
        ],
        boxes: [
          {
            icon: "file",
            title: "Tutte le <span>circolari</span>",
            text: "All'interno della sezione tutte le circolari dell'Area",
            to: "/area-giuridico-legislativa-fiscale/circolari",
          },
          {
            icon: "file-text",
            title: "Fiscale e <span>Tributario</span>",
            text: "Nella sezione sono raccolte le circolari fiscali e tributarie, nonché i principali documenti di interesse",
            to: "/area-giuridico-legislativa-fiscale/fiscale-e-tributario",
          },
          // {
          //   icon: "file-text",
          //   title: "Dal <span>Parlamento</span>",
          //   text: "All’interno le notizie relative all’iter di approvazione dei principali provvedimenti di interesse trasversale per il mondo delle utility.",
          //   to: "/area-giuridico-legislativa-fiscale/dal-parlamento/list",
          // },
          {
            icon: "file-text",
            title: "Appalti e <span>Concessioni</span>",
            text: "Nella sezione sono raccolte le circolari in materia di appalti e concessioni, nonché i principali documenti di interesse.",
            to: "/area-giuridico-legislativa-fiscale/appalti-e-concessioni/category-home",
          },
          {
            icon: "file-text",
            title: "Norme e <span>Regole</span>",
            text: "Una raccolta dei principali documenti di interesse: provvedimenti normativi, pronunce della magistratura, altri atti di istituzioni o Autorità.",
            to: "/area-giuridico-legislativa-fiscale/norme-e-regole/list",
          },
          {
            icon: "envelope",
            title: "I nostri <span>contatti</span>",
            email: "giuridico.legislativo@utilitalia.it",
            tel: "06 945282.90.10",
            //fax: "06 94528200",
            color: "var(--giuridico)",
            to: "/area-giuridico-legislativa-fiscale/contatti",
          },
        ],
      };
    }
    case "/area-comunicazione/home":
    case "/area-comunicazione/home/": {
      return {
        name: "comunicazione",
        image: imgComunicazione,
        icon: <Chat />,
        gradient: "comunicazione",
        color: "comunicazione",
        subcategory: false,
        title: "Utilitalia - Comunicazione",
        description:
          "L'Area Comunicazione si occupa della promozione di Utilitalia. Sviluppa progetti per il raggiungimento degli obiettivi associativi e per la realizzazione di iniziative nazionali",
        features: [
          {
            title: "Promozione",
            text: `L'Area Comunicazione si occupa della promozione di Utilitalia e dell'intero comparto associativo presso i diversi interlocutori esterni, contribuendo alla definizione di messaggi e strumenti. Sviluppa progetti per il raggiungimento degli obiettivi associativi e per la realizzazione di iniziative nazionali. Tra le iniziative nazionali periodiche promosse Utilitalia, il Festival dell’Acqua (www.festivalacqua.org) ed Ecomondo (www.ecomondo.com) appuntamento internazionale di riferimento per la green e circular economy.`,
          },
          {
            title: "Relazioni",
            text: "L'Area Comunicazione gestisce le relazioni esterne con le principali testate giornalistiche nazionali e locali, le concessionarie pubblicitarie, le Università e i centri di ricerca, nell'ambito della realizzazione di servizi giornalistici, campagne di sensibilizzazione, presentazioni di ricerche o di eventi divulgativi.",
          },
          {
            title: "Supporto",
            text: `Supporta le Aree e i Settori della federazione nella progettazione e realizzazione di eventi convegnistici e seminariali. Ha ideato e promosso “Utili all'Italia”, primo censimento delle migliori pratiche dei servizi pubblici, con i progetti realizzati delle imprese. Ha ideato e gestisce ULTIME, il "quotidiano dei servizi pubblici" dedicato ad acqua, ambiente ed energia. Ha ideato e gestisce i FOCUS ON, collana di approfondimenti su temi monografici interessanti per il mondo dei servizi pubblici locali. Raccoglie atti di convegni promossi dalla Federazione.
            Si occupa infine di promuovere le attività, gli eventi e le iniziative della Federazione e di tutte le aziende associate, attraverso strategie, strumenti di comunicazione e social network (Twitter, LinkedIn, Instagram, Telegram).`,
          },
        ],
        boxes: [
          {
            icon: "file-text",
            title: "Progetti di <span>Comunicazione</span>",
            // text: "All'interno l'archivio di tutti gli eventi",
            to: "/area-comunicazione/progetti-di-comunicazione",
          },
          {
            icon: "file-text",
            title: "Gallery <span>Eventi</span>",
            text: "All'interno l'archivio di tutti gli eventi",
            to: "/calendario-eventi",
          },
          {
            icon: "file-text",
            title: "Press <span>Kit</span>",
            text: "All'interno i comunicati stampa, media kit e video",
            to: "/news-media/press-kit",
          },
          {
            icon: "file-text",
            title: "Rassegna <span>Stampa</span>",
            text: "La Rassegna Stampa quotidiana in collaborazione con P.Review.",
            to: "/news-media/rassegna-stampa",
          },
          {
            icon: "file-text",
            title: "<span>Ultime</span>",
            text: "Il quotidiano di Utilitalia. Circolari della federazione, notizie giornalistiche, informazioni parlamentari e legislative delle ultime 24 ore. ",
            to: "/ultime",
          },
          {
            icon: "file-text",
            title: "<span>Pubblicazioni</span>",
            text: `La collana di approfondimenti proposta da UTILITALIA su temi
            monografici interessanti per il mondo dei servizi pubblici locali.`,
            to: "/pubblicazioni/list",
          },
          {
            name: "youtube",
            icon: "file-text",
            title: "Youtube <span>Utilitalia</span>",
            text: "Il canale youtube dedicato al Mondo Utilitalia",
            //to: "https://www.youtube.com/user/FederutilityStampa",
            to: "https://www.youtube.com/@YoutubeUtilitalia/featured",
          },
          {
            icon: "envelope",
            title: "I nostri <span>contatti</span>",
            email: "areacomunicazione@utilitalia.it",
            color: "var(--comunicazione)",
            to: "/area-comunicazione/contatti",
          },
          // {
          //   icon: "file-text",
          //   title: "Focus <span>On</span>",
          //   text: `Focus On è la collana di approfondimenti proposta da UTILITALIA su temi
          //   monografici interessanti per il mondo dei servizi pubblici locali.`,
          //   to: "/area-comunicazione/focus-on/list",
          // },
        ],
        // banners: [
        //   { img: imgYoutility, url: "https://www.educazionedigitale.it/youtilities/" },
        //   { img: imgUtiliAllItalia, url: "http://censimento.utilitalia.it/" },
        //   { img: imgRubinetto, url: "http://eventi.utilitalia.it/campagna_rubinetto.html" },
        // ],
        // banners: fetchBanners,
      };
    }
    case "/direzione-generale/home":
    case "/direzione-generale/home/": {
      return {
        name: "Direzione generale",
        image: imgAmministrazione,
        icon: <Book />,
        gradient: "amministrazione",
        color: "amministrazione",
        subcategory: false,
        sector: false,
        title: "Utilitalia - Direzione generale",
        description: "",
        disableWhatWeDo: true,
        features: [
          // {
          //   title: "Attività 1",
          //   text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
          // },
          // {
          //   title: "Attività 2",
          //   text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
          // },
          // {
          //   title: "Attività 3",
          //   text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
          // },
          // {
          //   title: "Attività 4",
          //   text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
          // },
        ],
        boxes: [
          {
            icon: "file-text",
            title: "<span>Amministrazione</span>",
            // text: `L’Amministrazione si occupa della contabilità e del bilancio di Utilitalia, di Utilitalia Servizi S.r.l e della Confservizi.`,
            to: "/direzione-generale/amministrazione/home",
          },
          {
            icon: "file-text",
            title: "<span>Organizzazione</span> e gestione interna",
            // text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
            to: "/direzione-generale/organizzazione-e-gestione-interna/home",
          },
          {
            icon: "file",
            title: "Tutte le <span>circolari</span>",
            text: "All'interno della sezione tutte le circolari dell'Area",
            to: "/direzione-generale/circolari",
          },
          /*      {
            icon: "file-text",
            title: "Pianificazione, Strategia, Sostenibilità",
            // text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
            to: "/direzione-generale/pianificazione-strategia-sostenibilita/home",
          }, */
          {
            icon: "envelope",
            title: "I nostri <span>contatti</span>",
            email: "direzionegenerale@utilitalia.it",
            tel: "06 945282.10",
            //fax: "06 945282.00",
            color: "var(--comunicazione)",
            to: "/direzione-generale/contatti",
          },
          {
            icon: "file-text",
            title: "Notizie <span>Antitrust</span>",
            text: `Nella sezione le news antitrust in collaborazione con lo Studio Legale "Rucellai&Raffaelli"`,
            to: "/direzione-generale/notizie-antitrust/list",
          },
          {
            icon: "file-text",
            title: "CSR e <span>sostenibilita</span>",
            text: "La sezione del sito di Utilitalia dedicata alla csr e alla Sostenibilita",
            to: "/direzione-generale/csr-sostenibilita/home",
          },
        ],
      };
    }
    case "/direzione-generale/organizzazione-e-gestione-interna/home":
    case "/direzione-generale/organizzazione-e-gestione-interna/home/": {
      return {
        name: "Direzione generale",
        image: imgAmministrazione,
        icon: <Book />,
        gradient: "amministrazione",
        color: "amministrazione",
        sector: false,
        disableWhatWeDo: false,
        subcategory: "Organizzazione e gestione interna",
        title:
          "Utilitalia - Direzione generale - Organizzazione e gestione interna",
        description:
          "L'area Organizzazione e gestione interna si occupa di lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        features: [
          {
            title: "LE ATTIVITÀ",
            text: `Organizzazione e gestione interna fornisce supporto tecnico e di segreteria nelle attività operative e organizzative della Federazione. Cura la gestione del personale e i rapporti con i fornitori.  Cura altresì la gestione tecnica degli uffici, i servizi ICT, la Sicurezza e Privacy interna e i Servizi multimediali. Coordina lo Staff di Segreteria dedicata alle attività di supporto operativo alla struttura federale.`,
          },
          {
            title: "MY.UTILITALIA.IT",
            text: `Gestisce il portale dedicato alle associate per esprimere la propria rappresentanza all'interno degli Organismi tecnici di Utilitalia.`,
          },
        ],
        boxes: [
          {
            icon: "envelope",
            title: "I nostri <span>contatti</span>",
            email: "direzionegenerale@utilitalia.it",
            tel: "06 945282.10",
            //fax: "06 94528200",
            color: "var(--comunicazione)",
            to: "/organizzazione-e-gestione-interna/contatti",
          },
        ],
      };
    }
    case "/direzione-generale/pianificazione-strategia-sostenibilita/home":
    case "/direzione-generale/pianificazione-strategia-sostenibilita/home/": {
      return {
        name: "Direzione generale",
        image: imgAmministrazione,
        icon: <Book />,
        gradient: "amministrazione",
        color: "amministrazione",
        sector: false,
        disableWhatWeDo: false,
        subcategory: "Pianificazione, Strategia, Sostenibilità",
        title:
          "Utilitalia - Direzione generale - Pianificazione, Strategia, Sostenibilità",
        description:
          "L'area Organizzazione e gestione interna si occupa di lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        features: [
          {
            title: "Attività 1",
            text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
          },
          {
            title: "Attività 2",
            text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
          },
          {
            title: "Attività 3",
            text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
          },
          {
            title: "Attività 4",
            text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
          },
        ],
        boxes: [
          {
            icon: "envelope",
            title: "I nostri <span>contatti</span>",
            email: "direzionegenerale@utilitalia.it",
            tel: "06 945282.10",
            fax: "06 945282.00",
            color: "var(--comunicazione)",
            to: "/pianificazione-strategia-sostenibilita/contatti",
          },
        ],
      };
    }
    case "/direzione-generale/csr-sostenibilita/home":
    case "/direzione-generale/csr-sostenibilita/home/": {
      return {
        name: "Direzione Generale",
        image: imgAmministrazione,
        icon: <Book />,
        gradient: "amministrazione",
        color: "amministrazione",
        subcategory: "csr <span>e</span> sostenibilita",
        sector: true,
        disableWhatWeDo: false,
        features: [
          {
            title: "Rappresenta le imprese",
            text: "Benvenuti nella sezione del sito di Utilitalia dedicata alla CSR e alla Sostenibilita, temi ormai strategici per tutte le Associate.",
          },
          {
            title: "Realizza studi",
            text: "L’azione di Utilitalia è finalizzata ad incoraggiare, affiancare e promuovere presso le Associate l’attività di rendicontazione non finanziaria, attraverso percorsi informativi, formativi e di condivisione di buone pratiche.",
          },
        ],
        boxes: [
          {
            icon: "file",
            title: "Misurarsi per <span>migliorare</span>",
            text: "All'interno della sezione tutte le circolari dell'Area",
            to: "/direzione-generale/csr-sostenibilita/misurarsi-per-migliorare/category-home",
          },
          {
            icon: "line",
            title: "Bilanci di <span>sostenibilita</span>",
            text: "In questa sezione sono raccolti per annualità i Bilanci di Sostenibilita delle Utility associate.",
            to: "/direzione-generale/csr-sostenibilita/bilanci-sostenibilita/list",
          },
          {
            icon: "question",
            title: "Attività del <span>GDL</span>",
            text: "Nella sezione sono raccolti i verbali delle riunioni del gruppo di lavoro, i principali documenti di interesse e tutte le comunicazioni riguardanti il gruppo di lavoro. ",
            to: "/direzione-generale/csr-sostenibilita/attivita-gdl/category-home",
          },
          {
            icon: "intersect",
            title: "Studi e <span>documentazione</span>",
            text: "All'interno della sezione gli studi e le ricerche dell'Area.",
            to: "/direzione-generale/csr-sostenibilita/studi-e-documentazione/list",
          },
          {
            icon: "file-text",
            title: "Convegni e <span>seminari</span>",
            text: "Utilitalia, in collaborazione con il GdL “Csr e Sostenibilita”, ha realizzato appuntamenti convegnistici e formativi sui temi di principale interesse.",
            to: "/direzione-generale/csr-sostenibilita/convegni-e-seminari/list",
          },
          {
            icon: "envelope",
            title: "I nostri <span>contatti</span>",
            email: "direzionegenerale@utilitalia.it",
            tel: "06 945282.10",
            fax: "06 945282.00",
            color: "var(--comunicazione)",
            to: "/direzione-generale/csr-sostenibilita/contatti",
          },
        ],
      };
    }
    case "/direzione-generale/amministrazione/home":
    case "/direzione-generale/amministrazione/home/":
    case "/amministrazione/home":
    case "/amministrazione/home/": {
      return {
        name: "Direzione generale",
        image: imgAmministrazione,
        icon: <Book />,
        gradient: "amministrazione",
        color: "amministrazione",
        sector: false,
        disableWhatWeDo: false,
        subcategory: "Amministrazione",
        title: "Utilitalia - Direzione generale - Amministrazione",
        description:
          "L’Amministrazione si occupa della contabilità e del bilancio di Utilitalia, di Utilitalia Servizi S.r.l e della Confservizi.",
        features: [
          {
            title: "Contabilità",
            text: `L’Amministrazione si occupa della contabilità e del bilancio di Utilitalia, di Utilitalia Servizi S.r.l e della Confservizi.`,
          },
          {
            title: "Adesione",
            text: `E’ preposta alla determinazione ed esazione dei contributi associativi e risponde ai quesiti delle imprese in materia di calcolo delle quote di adesione. Cura gli aspetti legati all’adesione dei nuovi associati predisponendo per la Giunta Esecutiva la formalizzazione delle richieste di adesione.`,
          },
          {
            title: "Gestione",
            text: `Si occupa anche della gestione amministrativa degli Organi federali e del personale, supportando le attività del Collegio dei Revisori e della Società di revisione per la certificazione del bilancio. Si interfaccia e supporta, adottando il controllo di gestione, le attività delle Aree e Servizi Federali. Cura anche la gestione amministrativa dei permessi sindacali retribuiti riferiti al CCNL Servizi Ambientali.`,
          },
        ],
        boxes: [
          {
            icon: "file-text",
            title: "Associate",
            text: `Sezione deI soggetti associati ad Utilitalia, forniscono acqua attualmente a circa l’80% della popolazione, distribuiscono gas ad oltre il 30% degli abitanti ed energia elettrica a circa il 15 % della popolazione italiana e offrono servizi ambientali a circa
            il 55% degli abitanti.dicata alla gestione delle tematiche regolatorie...`,
            to: "/associate",
          },
          {
            icon: "file",
            title: "Come <span>aderire</span>",
            text: "Per aderire alla Federazione dovrà essere compilata e inviata a mezzo e-mail amministrazione@utilitalia.it la scheda rilevamento dati...",
            to: "/amministrazione/come-aderire/page",
          },
          {
            icon: "file",
            title: "Modulistica",
            to: "/amministrazione/modulistica/page",
          },
          {
            icon: "envelope",
            title: "I nostri <span>contatti</span>",
            email: "amministrazione@utilitalia.it",
            tel: "06 945282.20",
            fax: "06 94528201",
            color: "var(--comunicazione)",
            to: "/amministrazione/contatti",
          },
        ],
      };
    }
    case "/area-affari-regolatori/norme-e-regole":
    case "/area-affari-regolatori/norme-e-regole/": {
      return {
        name: "affari regolatori",
        image: imgRegolazione,
        icon: <Book />,
        color: "affari",
        subcategory: false,
        features: [
          {
            text: " Gestione delle tematiche regolatorie, in grado di raccogliere e coordinare le posizioni dei propri associati rispetto alle questioni ed alle proposte introdotte dal Regolatore, consentirà pertanto di rafforzare il ruolo di rappresentanza di Utilitalia nei confronti di alcuni stakeholder istituzionali, rispetto ad un'area tematica trasversale al proprio tessuto associativo. ",
          },
        ],
        boxes: [
          {
            icon: "file",
            title: "Antitrust",
            text: "All'interno della sezione tutte le tematiche regolatorie dell'Antitrust",
            to: "/area-affari-regolatori/norme-e-regole/antitrust/list",
          },
          {
            icon: "file",
            title: "UE",
            text: "All'interno della sezione tutte le tematiche regolatorie dell' Unione Europea",
            to: "/area-affari-regolatori/norme-e-regole/ue/list",
          },
          {
            icon: "file",
            title: "Acqua",
            text: "All'interno della sezione tutte le tematiche regolatorie sull'acqua",
            to: "/area-affari-regolatori/norme-e-regole/acqua/list",
          },
          {
            icon: "file",
            title: "Energia",
            text: "All'interno della sezione tutte le tematiche regolatorie inerenti l'energia",
            to: "/area-affari-regolatori/norme-e-regole/energia/list",
          },
          {
            icon: "file",
            title: "Ambiente",
            text: "All'interno della sezione tutte le tematiche regolatorie dell'ambiente",
            to: "/area-affari-regolatori/norme-e-regole/ambiente/list",
          },
        ],
      };
    }
    case "/area-strategie-pianificazione/home":
    case "/area-strategie-pianificazione/home/": {
      return {
        name: "Strategie <span>e</span> Pianificazione <span>di</span> Settore",
        image: imgStrategie,
        icon: <Target />,
        gradient: "strategie",
        color: "strategie",
        /* subcategory: "Norme e Regole", */
        features: [
          {
            title: "DEFINISCE LE STRATEGIE",
            text: `Definisce il Business Plan della Federazione con specifico focus su sostenibilità, policy del comparto energetico, innovazione e Sud`,
          },
          {
            title: "REALIZZA STUDI",
            text: `Realizza studi di carattere tecnico, scientifico, economico e finanziario.`,
          },
          {
            title: "INFORMA",
            text: `Informa le imprese associate sulle evoluzioni e le novità normative, nazionali ed internazionali, regolatorie  e tecniche di settore.`,
          },
          {
            title: "SUPPORTA",
            text: `Supporta le Associate durante il percorso verso la transizione ecologica e digitale.`,
          },
        ],
        boxes: [
          {
            icon: "file",
            title: "Tutte le <span>circolari</span>",
            text: "All'interno della sezione tutte le circolari dell'Area",
            to: "/area-strategie-pianificazione/circolari",
          },
          {
            icon: "intersect",
            title: "Studi e <span>ricerche</span>",
            text: "All’interno della sezione gli studi e le ricerche dell’Area con un focus sulle policy del comparto energetico, a supporto del settore energia, Business Plan, innovazione e Sud",
            to: "/area-strategie-pianificazione/studi-e-documentazione/list",
          },
          {
            icon: "envelope",
            title: "I nostri <span>contatti</span>",
            email: "sostenibilita@utilitalia.it",
            tel: "06 945282.90",
            color: "var(--strategie)",
            to: "/area-strategie-pianificazione/contatti",
          },
        ],
      };
    }
    default:
  }
}
