export const renderContent = (category) => {
  switch (category) {
    case "/ambiente/csr-sostenibilita/misurarsi-per-migliorare/9c2c560d-78cf-4e6e-8714-633cd7035c4c":
    case "/direzione-generale/csr-sostenibilita/misurarsi-per-migliorare/9c2c560d-78cf-4e6e-8714-633cd7035c4c": {
      return {
        subsector: "CSR & Sostenibilita",
        subsubsector: "Misurarsi per Migliorare",
        title: `Misurarsi per <span>Migliorare</span>`,
      };
    }

    case "/ambiente/csr-sostenibilita/misurarsi-per-migliorare/27f1c483-ed9f-491d-bd20-aa110c55bbb8":
    case "/direzione-generale/csr-sostenibilita/misurarsi-per-migliorare/27f1c483-ed9f-491d-bd20-aa110c55bbb8": {
      return {
        subsector: "CSR & Sostenibilita",
        subsubsector: "Misurarsi per Migliorare",
        title: `Misurarsi per <span>Migliorare</span>`,
      };
    }

    case "/energia/portale-ambiti/page": {
      return {
        subsector: "CSR & Sostenibilita",
        title: `Portale <span>Ambiti</span>`,
        text: `<p>
        <strong style="color:red;text-transform:uppercase">Servizio temporaneamente sospeso</strong><br/>
        Il Portale Ambiti è un servizio informativo realizzato da
        Utilitalia, in collaborazione con Utiliteam. Fornisce agli Associati
        della Federazione notizie e aggiornamenti utili sugli ambiti di gara
        relativi al settore della distribuzione del gas. Una piattaforma
        semplice e intuitiva, che permette di ottenere una fotografia del
        contesto normativo e competitivo nazionale.
      </p>
      <p>
        Le Aziende associate potranno effettuare stime e calcoli
        approfonditi negli ambiti di interesse, valutare il proprio
        posizionamento competitivo, la reale dimensione della gara d’ambito
        e i valori industriali ed economici che la caratterizzano.
      </p>
      <div style = 'display : flex;'>
      <a href="https://portale.utilitalia.it/area_energia/portaleambiti" class="btn" target="_blank" style="margin-top:2rem; align-self:flex-start; color: #fff;">
        Accedi al portale
      </a>
      </div>`,
      };
    }

    case "/amministrazione/come-aderire/page": {
      return {
        subsector: "Come Aderire",
        title: `Come <span>Aderire</span>`,
        folder: "ADESIONE",
      };
    }

    case "/amministrazione/modulistica/page": {
      return {
        subsector: "Modulistica",
        title: `Modulistica`,
        folder: "MODULI",
      };
    }
    default:
  }
};
