import { useEffect, useState } from "react";
import "./IntroSection.scss";
import { renderCategory } from "./renderCategory";

export default function IntroSection(props) {
  const [state, setState] = useState({});

  useEffect(() => {
    setState(renderCategory(props.category));
  }, [props.category]);

  return (
    <div className="intro-section-component">
      <div className="hero" style={{ backgroundImage: `url(${state.image})` }}>
        <div
          className="overlay"
          style={{ background: `var(--${state.color})` }}
        >
          <div className="section pad-mobile">
            {state.icon}
            <span dangerouslySetInnerHTML={{ __html: state.name }} />
            {props.subsector && (
              <span style={{ fontSize: 48, opacity: 0.5 }}>·</span>
            )}
            {props.subsector && <span>{props.subsector}</span>}
            {props.subsubsector && props.subsubsector !== "List" && (
              <span style={{ fontSize: 48, opacity: 0.5 }}>·</span>
            )}
            {props.subsubsector !== "List" &&
              props.subsector !== "Archivio" && (
                <span style={{ fontWeight: "bold" }}>{props.subsubsector}</span>
              )}
            {props.subsubsubsector &&
              props.subsubsubsector !== "List" &&
              props.subsector !== "Archivio" && (
                <span style={{ fontSize: 48, opacity: 0.5 }}>·</span>
              )}
            {props.subsubsubsector !== "List" &&
              props.subsector !== "Archivio" && (
                <span>{props.subsubsubsector}</span>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}
