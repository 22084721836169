import { truncate } from "functions/formatTextFunctions";
import { isMobile } from "react-device-detect";
import { RiPushpin2Line as Pin } from "react-icons/ri";
import { Link } from "react-router-dom";
import "./CardCircular.scss";
import { ReactComponent as Water } from "icons/drop-bold.svg";
import { ReactComponent as Leaf } from "icons/leaf-bold.svg";
import { ReactComponent as Thunder } from "icons/lightning-bold.svg";
import { ReactComponent as Settings } from "icons/faders-bold.svg";
import { ReactComponent as Bag } from "icons/tote-bold.svg";
import { ReactComponent as Steam } from "icons/shield-chevron-bold.svg";
import { ReactComponent as Chat } from "icons/chats-circle-bold.svg";
import { ReactComponent as Book } from "icons/book-bold.svg";

export default function CardCircular({ element }) {
  return (
    <div className="card-circular">
      <Pin className="pin" />

      {renderIcon(element.findingSubType1.name)}

      <div style={{ position: "absolute", top: 16, left: isMobile ? 16 : 16, color: '#888' }}>
        {new Date(element.date).toLocaleDateString("it-IT")}
      </div>

      <div className="content">
        <h5 style={{ marginBottom: 8 }}>
          <div style={{ height: isMobile ? "5.6rem" : "4.2rem" }}>
            <div className="fade">{truncate(element.subject, isMobile ? 80 : 150)}</div>
          </div>
        </h5>

        <div className="incipit" style={{ marginBottom: 48 }}>
          <div style={{ height: isMobile ? "5.6rem" : "8.4rem" }}>
            <div className="fade">
              {element.description && element.description !== "___"
                ? truncate(element.description, isMobile ? 50 : 120)
                : ""}
            </div>
          </div>
        </div>

        <Link to={"/circolare/" + element.id} className="btn small">
          Leggi di più
        </Link>
      </div>
    </div>
  );
}

function renderIcon(element) {
  switch (element) {
    case "Acqua":
      return (
        <Water
          className="category"
          style={{
            background: "var(--acquaGradient)",
          }}
        />
      );
    case "Ambiente":
      return (
        <Leaf
          className="category"
          style={{
            background: "var(--ambienteGradient)",
          }}
        />
      );
    case "Area Affari Regolatori":
      return (
        <Settings
          className="category"
          style={{
            background: "var(--affari)",
          }}
        />
      );
    case "Area Comunicazione":
      return (
        <Chat
          className="category"
          style={{
            background: "var(--comunicazione)",
          }}
        />
      );
    case "Direzione Generale":
      return (
        <Book
          className="category"
          style={{
            background: "var(--amministrazione)",
          }}
        />
      );
    case "Energia":
      return (
        <Thunder
          className="category"
          style={{
            background: "linear-gradient(135deg, #FF8801 0%, #FFBD0D 50%, #EB650C 100%)",
          }}
        />
      );
    case "Giuridico-Legislativo":
      return (
        <Steam
          className="category"
          style={{
            background: "var(--giuridico)",
          }}
        />
      );
    case "Lavoro":
      return (
        <Bag
          className="category"
          style={{
            background: "var(--lavoro)",
          }}
        />
      );
    case "Relazioni Esterne":
      return (
        <Thunder
          className="category"
          style={{
            background: "linear-gradient(135deg, #FF8801 0%, #FFBD0D 50%, #EB650C 100%)",
          }}
        />
      );
    default:
  }
}
