import moment from "moment";
import { Link } from "react-router-dom";
import "./CardGeneral.scss";

export default function CardGeneral({ element, link }) {
  return (
    <div className="card-general">
      {
        <div className="heading">
          {element.date && (
            <span>{moment(element.date).format("DD MMM YYYY")}</span>
          )}

          <span></span>
        </div>
      }

      <div className="body">
        {element?.subject.length > 150 ? (
          <div className="top">
            <p>{element.subject.substring(0, 150) + "..."} </p>
          </div>
        ) : (
          <div className="top">
            <p>{element.subject}</p>
          </div>
        )}

        <div className="bottom">
          <div className="time"></div>

          <Link to={link + element.id} className="btn small">
            Dettaglio
          </Link>
        </div>
      </div>
    </div>
  );
}
