import React, { Component } from "react";
import axios from "axios";
import "./NewsMediaPressKitVideo.scss";
import IntroPressKit from "components/intro-press-kit/IntroPressKit";
import LinkPressKit from "components/link-press-kit/LinkPressKit";
import NavigationCircle from "components/navigation/circle/NavigationCircle";
import { ReactComponent as Play } from "icons/play-bold.svg";
import Video from "img/video-press-kit.png";
import { Link } from "react-router-dom";
import Placeholders from "components/placeholders/Placeholders";

const link = {
  icon: "document",
  title: "Comunicati <span>stampa</span>",
  text: "All'interno tutti i comunicati stampa redatti dall' Area Comunicazione",
  to: "/news-media/press-kit/comunicati-stampa",
};

export default class NewsMediaPressKitComunicatiStampa extends Component {
  state = {
    videos: [],
    videosFrom: 1,
    loadingVideos: true,
  };

  async componentDidMount() {
    try {
      const response = await this.fetchVideos();
      const videos = response?.data?.data?.documents;
      this.setState({
        videos: videos || [],
      });
    } catch (err) {
      console.log("Error CDM NewsMediaPressKitVideo", err);
    }
    this.setState({ loadingVideos: false });
  }

  async componentDidUpdate(oldProps, oldState) {
    if (this.state.videosFrom !== oldState.videosFrom) {
      this.setState({ loadingVideos: true });
      try {
        const response = await this.fetchVideos();
        const videos = response?.data?.data?.documents;
        if (videos && videos.length === 0) {
          this.setState({ videosFrom: 1 });
        }
        this.setState({
          videos: videos || [],
        });
      } catch (err) {
        console.log("Error CDU NewsMediaPressKitVideo", err);
      }
      this.setState({ loadingVideos: false });
    }
  }

  handleVideosNavigation = (delta) => {
    this.setState({ videosFrom: this.state.videosFrom + delta * 9 });
  };

  fetchVideos = async () => {
    return await axios.post("https://api.utilitalia.it/DMS/Documents/List", {
      documentClasses: ["VIDEO"],
      headingPath: "\\COM",
      pageStart: this.state.videosFrom,
      documentValueDefs: [
        {
          code: "TITOLO",
        },
        {
          code: "DESCRIZIONE",
        },
      ],
      pageSize: 9,
      documentValueOrders: [
        {
          code: "POSIZIONE",
          type: "3",
          direction: "desc",
        },
      ],
    });
  };

  render() {
    return (
      <div id="news-media-press-kit-comunicati-stampa">
        <IntroPressKit section="Video" />
        <div className="container pad-mobile">
          <h2 className="general">Video</h2>
          <div className="video-list">
            {this.state.loadingVideos ? (
              <Placeholders number={9} type="video" />
            ) : (
              this.state.videos.map((element, i) => (
                <Link to={"/video/" + element.id} className="video" key={i}>
                  <div className="sample">
                    <img src={Video} alt="" />
                    <div className="play">
                      <Play />
                    </div>
                  </div>
                  <h3>
                    {
                      element.values.find((value) => value.code === "TITOLO")
                        .value
                    }
                  </h3>
                </Link>
              ))
            )}
          </div>
          <NavigationCircle
            function={this.handleVideosNavigation}
            color="var(--greyDarkestColor)"
            disabled={this.state.videosFrom === 1}
          />

          <LinkPressKit data={link} />
        </div>
      </div>
    );
  }
}
