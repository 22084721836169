import React, { Component } from "react";
import axios from "axios";
import "./ContattiPage.scss";
import IntroSection from "components/intro-section/IntroSection";
import { renderAttribute } from "functions/renderAttribute";
import Placeholders from "components/placeholders/Placeholders";
import { isMobile } from "react-device-detect";

const format = (title) => {
  let arr = [];
  let exludeWords = ["e", "di"];
  arr = title.split("-");
  return arr
    .map((word, i) => {
      return exludeWords.includes(word) && i !== 0
        ? [word]
        : word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};

const setSector = (address, subAddress) => {
  switch (address) {
    case "ambiente":
      if (subAddress === "csr-sostenibilita") {
        return {
          heading: "\\AREA AMBIENTE\\SOSTENIBILITA\\CONTATTI",
          folder: "GENERALE",
        };
      } else
        return { heading: "\\AREA AMBIENTE\\CONTATTI", folder: "CONTATTI" };
    case "energia":
      return { heading: "\\AREA_ENERGIA\\CONTATTI", folder: "CONTATTI" };
    case "acqua":
      return { heading: "\\AREA ACQUA\\CONTATTI", folder: "CONTATTI" };
    case "area-affari-regolatori":
      return {
        heading: "\\AREA AFFARI REGOLATORI\\CONTATTI",
        folder: "CONTATTI",
      };
    case "area-lavoro-relazioni-industriali":
      return {
        heading: "\\AREA LAVORO\\CONTATTI",
        folder: "CONTATTI",
      };
    case "area-giuridico-legislativa-fiscale":
      return {
        heading: "\\GL\\CONTATTI",
        folder: "CONTATTI",
      };
    case "area-comunicazione":
      return {
        heading: "\\COM\\CONTATTI",
        folder: "GENERALE",
      };
    case "amministrazione":
      return {
        heading: "\\AREA AMMINISTRAZIONE",
        folder: "CONTATTI",
      };
    case "area-strategie-pianificazione":
      return {
        heading: "\\SPS\\CONTATTI",
        folder: "CONTATTI",
      };
    default:
      return {
        heading: "\\CHI_SIAMO",
        folder: "CONTATTI",
      };
  }
};

export default class ContattiPage extends Component {
  state = {
    organico: [],
    contatti: [],
    loadingOrganico: true,
    loadingContatti: true,
  };

  async componentDidMount() {
    const sector = setSector(
      this.props.match.url.split("/")[1],
      this.props.match.url.split("/")[2]
    );
    await this.fetchOrganico();
    await axios
      .post("https://api.utilitalia.it/DMS/Documents/List", {
        documentClasses: ["CONTATTI"],
        heading: sector.heading,
        folder: sector.folder,
        pageSize: "0",
        pageStart: "1",
        documentValueOrders: [
          {
            code: "POSIZIONE",
            type: "3",
            direction: "asc",
          },
        ],
      })
      .then((response) => {
        const contatti = response?.data?.data?.documents;
        if (contatti) {
          this.setState({
            contatti,
            loadingContatti: false,
          });
        }
      });
  }

  fetchOrganico = async () => {
    try {
      const [category, subcategory] = await this.props.match.url
        .substring(1)
        .split("/");
      let organico;

      if (category === "organizzazione-e-gestione-interna") {
        const response = await axios.post(
          "https://api.utilitalia.it/DMS/Documents/List",
          {
            documentClasses: ["STRUTTURA"],
            heading: "\\CHI_SIAMO",
            folder: "STRUTTURA",
            documentValueOrders: [
              {
                code: "LIVELLO",
                type: "3",
                direction: "asc",
              },
              {
                code: "POSIZIONE",
                type: "3",
                direction: "asc",
              },
            ],
          }
        );
        organico = response?.data?.data?.documents || [];
        organico = organico.filter(
          (organi) =>
            organi.values
              .find((value) => value.code === "SETTORE")
              .value.toLowerCase() === "organizzazione e gestione interna"
        );
      } else {
        const sector = setSector(category, subcategory);
        const response = await axios.post(
          "https://api.utilitalia.it/DMS/Documents/List",
          {
            documentClasses: ["CONTATTI"],
            heading: sector.heading,
            folder: "ORGANICO",
            pageSize: "0",
            pageStart: "1",
            documentValueOrders: [
              {
                code: "POSIZIONE",
                type: "3",
                direction: "asc",
              },
            ],
          }
        );
        organico = response?.data?.data?.documents || [];
      }
      this.setState({
        organico,
      });
    } catch (err) {
      console.log("Error fetching organico ContattiPage", err);
    }
    this.setState({ loadingOrganico: false });
  };

  splitTel = (data) => {
    if (data) {
      if (data.includes("-")) {
        const dataArray = data.split("-");
        return dataArray;
      } else if (data.includes("–")) {
        const dataArray = data.split("–");
        return dataArray;
      } else {
        return [data];
      }
    } else {
      return [];
    }
  };

  formatContatti = (contatti) => {
    const match = format(this.props.match.url.split("/")[1]);
    if (
      match === "Direzione Generale" ||
      match === "Pianificazione Strategia Sostenibilita"
    ) {
      return contatti.filter(
        (contatto) =>
          contatto.values
            .find((val) => val.code === "TITOLO")
            .value.toLowerCase() === "direzione generale"
      );
    } else if (match.toLowerCase() === "organizzazione e gestione interna") {
      return contatti.filter(
        (contatto) =>
          contatto.values
            .find((val) => val.code === "TITOLO")
            .value.toLowerCase() === "organizzazione e gestione interna"
      );
    }
    return contatti;
  };

  render() {
    return (
      <div id="contatti">
        <IntroSection
          category={format(this.props.match.url.split("/")[1])}
          subsector={format(this.props.match.url.split("/")[2])}
          subsubsector={
            this.props.match.url.split("/")[3] &&
            format(this.props.match.url.split("/")[3])
          }
        />
        <div className="container pad-mobile">
          <h2 className="general">
            I nostri <span>contatti</span>
          </h2>
          {this.state.loadingContatti || this.state.loadingOrganico ? (
            <div className="contacts-list">
              <Placeholders number={2} type="contatto" />
            </div>
          ) : (
            <div className="contacts-list">
              {this.state.organico.length > 0 && (
                <div className="organico">
                  {/* <h6>Organico</h6> */}
                  {this.state.organico.map((element, i) => {
                    const texts = renderAttribute(element.values, "TESTO")
                      ? renderAttribute(element.values, "TESTO")
                          .split("\r")
                          .join("")
                          .split("\n")
                      : renderAttribute(element.values, "STAFF")
                      ? renderAttribute(element.values, "STAFF")
                          .split("\r")
                          .join("")
                          .split("\n")
                      : [];
                    return (
                      <React.Fragment key={`organico_${i}`}>
                        <h5
                          style={{ marginBottom: "1rem" }}
                          dangerouslySetInnerHTML={{
                            __html: renderAttribute(element.values, "TITOLO"),
                          }}
                        />
                        {texts.map((text) => (
                          <p
                            style={text === "" ? { height: "0.5rem" } : {}}
                            dangerouslySetInnerHTML={{
                              __html: text,
                            }}
                          />
                        ))}
                      </React.Fragment>
                    );
                  })}
                </div>
              )}
              {this.state.contatti.length > 1 && (
                <>
                  <div className="contatti">
                    {/* <h6>Contatti</h6> */}
                    {this.formatContatti(this.state.contatti).map(
                      (element, i) => (
                        <React.Fragment key={`contatti_${i}`}>
                          <h5>{renderAttribute(element.values, "TITOLO")}</h5>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: renderAttribute(element.values, "TESTO"),
                            }}
                          />
                          {this.splitTel(
                            renderAttribute(element.values, "TEL")
                          ).map((tel, ind) => (
                            <p key={`telefono_${i}_${ind}`}>
                              <a href={`tel:${tel}`}>{tel}</a>
                            </p>
                          ))}
                          <p>
                            <a
                              style={{ cursor: "pointer" }}
                              href={`mailto:${renderAttribute(
                                element.values,
                                "EMAIL"
                              )}`}
                            >
                              {renderAttribute(element.values, "EMAIL")}
                            </a>
                          </p>
                        </React.Fragment>
                      )
                    )}
                  </div>
                </>
              )}
              {this.state.contatti.length === 1 && (
                <div className="contatti">
                  {/* <h6>Contatti</h6> */}
                  {this.state.contatti[0] &&
                    this.state.contatti[0].values.find(
                      (value) => value.code === "TEL"
                    ) && (
                      <p>
                        Tel:{" "}
                        <a
                          href={`tel:${renderAttribute(
                            this.state.contatti[0].values,
                            "TEL"
                          )}`}
                        >
                          {renderAttribute(
                            this.state.contatti[0].values,
                            "TEL"
                          )}
                        </a>
                      </p>
                    )}
                  {this.state.contatti[0] &&
                    this.state.contatti[0].values.find(
                      (value) => value.code === "EMAIL"
                    ) && (
                      <p>
                        Email:{" "}
                        <a
                          style={{ cursor: "pointer" }}
                          href={`mailto:${renderAttribute(
                            this.state.contatti[0].values,
                            "EMAIL"
                          )}`}
                        >
                          {renderAttribute(
                            this.state.contatti[0].values,
                            "EMAIL"
                          )}
                        </a>
                      </p>
                    )}
                  {this.state.contatti[0] &&
                    this.state.contatti[0].values.find(
                      (value) => value.code === "FAX"
                    ) && (
                      <p>
                        Fax:{" "}
                        {
                          this.state.contatti[0].values.find(
                            (value) => value.code === "FAX"
                          ).value
                        }
                      </p>
                    )}
                </div>
              )}
              {!isMobile && this.state.contatti.length > 0 && (
                <>
                  <div className="left">
                    <div className="orange"></div>
                    <div className="blue"></div>
                    <div className="green"></div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
