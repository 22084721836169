import { Fragment, useEffect, useState } from "react";

function renderPlaceholder(type) {
  switch (type) {
    case "new":
      return (
        <div className="ph-item direzione-generale">
          <div className="ph-col-12">
            <div className="ph-row">
              <div className="ph-col-3"></div>
              <div className="ph-col-2 empty"></div>
              <div className="ph-col-2"></div>
              <div className="ph-col-3 empty"></div>
              <div className="ph-col-2"></div>
              <div className="ph-col-12 empty"></div>
              <div className="ph-col-12 big"></div>
              <div className="ph-col-12 big"></div>
              <div className="ph-col-12 empty"></div>
              <div className="ph-col-8 empty"></div>
              <div className="ph-col-4"></div>
            </div>
          </div>
        </div>
      );
    case "rassegna":
      return (
        <div className="ph-item article" style={{ height: 200 }}>
          <div className="ph-col-12">
            <div className="ph-row">
              <div className="ph-col-12 empty"></div>
              <div className="ph-col-12 empty"></div>
              <div className="ph-col-12 empty"></div>
              <div className="ph-col-12"></div>
              <div className="ph-col-12"></div>
              <div className="ph-col-12"></div>
              <div className="ph-col-12 empty"></div>
              <div className="ph-col-12 empty"></div>
            </div>
          </div>
        </div>
      );
    case "utilities":
      return (
        <div className="ph-item" style={{ height: 200 }}>
          <div className="ph-col-12">
            <div className="ph-row">
              <div className="ph-col-12 empty"></div>
              <div className="ph-col-12 empty"></div>
              <div className="ph-col-12 empty"></div>
              <div className="ph-col-12"></div>
              <div className="ph-col-12"></div>
              <div className="ph-col-12"></div>
              <div className="ph-col-12 empty"></div>
              <div className="ph-col-12 empty"></div>
            </div>
          </div>
        </div>
      );
    case "new-singola":
      return (
        <div className="ph-item" style={{ background: "inherit" }}>
          <div className="ph-col-4">
            <div className="ph-picture" style={{ height: 300 }}></div>
          </div>
          <div className="ph-col-8">
            <div className="ph-row">
              <div className="ph-col-12 empty"></div>
              <div className="ph-col-12 empty"></div>
              <div className="ph-col-12 empty"></div>
              <div className="ph-col-12"></div>
              <div className="ph-col-12"></div>
              <div className="ph-col-12"></div>
              <div className="ph-col-12 empty"></div>
              <div className="ph-col-12 empty"></div>
            </div>
          </div>
        </div>
      );
    case "atto":
      return (
        <div className="ph-item">
          <div className="ph-col-4">
            <div className="ph-picture"></div>
          </div>
          <div className="ph-col-8">
            <div className="ph-row">
              <div className="ph-col-6 big"></div>
              <div className="ph-col-6 empty"></div>
              <div className="ph-col-12 empty"></div>
              <div className="ph-col-12 empty"></div>
              <div className="ph-col-12"></div>
              <div className="ph-col-12"></div>
              <div className="ph-col-6"></div>
            </div>
          </div>
        </div>
      );
    case "new-prima":
      return (
        <div className="ph-item first-new">
          <div className="ph-col-12">
            <div className="ph-picture" style={{ height: 500 }}></div>
            <div className="ph-row">
              <div className="ph-col-12 empty"></div>
              <div className="ph-col-8 big"></div>
              <div className="ph-col-12"></div>
              <div className="ph-col-12"></div>
              <div className="ph-col-12"></div>
              <div className="ph-col-12"></div>
              <div className="ph-col-6"></div>
            </div>
          </div>
        </div>
      );
    case "evento-home":
      return (
        <div className="ph-item">
          <div className="ph-col-2" style={{ height: 50 }}>
            <div className="ph-picture"></div>
          </div>
          <div className="ph-col-8">
            <div className="ph-row">
              <div className="ph-col-4"></div>
              <div className="ph-col-8"></div>
              <div className="ph-col-6"></div>
            </div>
          </div>
        </div>
      );
    case "evento":
      return (
        <div className="ph-item">
          <div className="ph-col-12">
            <div className="ph-row">
              <div className="ph-col-12 big"></div>
              <div className="ph-col-12 empty"></div>
              <div className="ph-col-12"></div>
              <div className="ph-col-12"></div>
              <div className="ph-col-12"></div>
              <div className="ph-col-6"></div>
              <div className="ph-col-12 empty"></div>
              <div className="ph-col-12 empty"></div>
              <div className="ph-col-2"></div>
              <div className="ph-col-8 empty"></div>
              <div className="ph-col-2"></div>
            </div>
          </div>
        </div>
      );
    case "associata":
      return (
        <div className="ph-item">
          <div className="ph-col-12">
            <div className="ph-row">
              <div className="ph-col-12 big"></div>
              <div className="ph-col-12 empty"></div>
              <div className="ph-col-6"></div>
              <div className="ph-col-8"></div>
              <div className="ph-col-12 empty"></div>
              <div className="ph-col-6"></div>
              <div className="ph-col-6 empty"></div>
              <div className="ph-col-6"></div>
              <div className="ph-col-6 empty"></div>
              <div className="ph-col-12 empty"></div>
              <div className="ph-col-12 empty"></div>
              <div className="ph-col-4 empty"></div>
              <div className="ph-col-4"></div>
              <div className="ph-col-4 empty"></div>
            </div>
          </div>
        </div>
      );
    case "video":
      return (
        <div className="ph-item video">
          <div className="ph-picture" style={{ height: 200 }}></div>
        </div>
      );
    case "video-pagina":
      return (
        <div className="ph-item" style={{ width: "70%" }}>
          <div className="ph-picture" style={{ height: 200 }}></div>
        </div>
      );
    case "contatto":
    case "codice-etico":
      return (
        <div className="ph-col contatti">
          <div className="ph-row">
            <div className="ph-col-6"></div>
            <div className="ph-col-6 empty"></div>
            <div className="ph-col-6"></div>
            <div className="ph-col-6 empty"></div>
            <div className="ph-col-12"></div>
            <div className="ph-col-12"></div>
            <div className="ph-col-8"></div>
            <div className="ph-col-4 empty"></div>
            <div className="ph-col-8"></div>
            <div className="ph-col-4 empty"></div>
            <div className="ph-col-8"></div>
            <div className="ph-col-4 empty"></div>
            <div className="ph-col-12 empty"></div>
            <div className="ph-col-12 empty"></div>
          </div>
        </div>
      );
    case "organo":
      return (
        <div className="ph-item">
          <div className="ph-col-4">
            <div className="ph-picture"></div>
          </div>
          <div className="ph-col-8">
            <div className="ph-row">
              <div className="ph-col-6"></div>
              <div className="ph-col-6 empty"></div>
              <div className="ph-col-6"></div>
              <div className="ph-col-6 empty"></div>
              <div className="ph-col-12"></div>
              <div className="ph-col-12"></div>
              <div className="ph-col-8"></div>
              <div className="ph-col-4 empty"></div>
              <div className="ph-col-8"></div>
              <div className="ph-col-4 empty"></div>
            </div>
          </div>
        </div>
      );
    case "statuto":
      return (
        <div className="ph-item">
          <div className="ph-col-12">
            <div className="ph-row">
              <div className="ph-col-12 empty"></div>
              <div className="ph-col-12 big"></div>
              <div className="ph-col-12 empty"></div>
            </div>
          </div>
        </div>
      );
    case "quesito":
      return (
        <div className="ph-item" style={{ padding: 0, border: 0 }}>
          <div className="ph-col-12" style={{ padding: 0, margin: 0 }}>
            <div className="ph-row" style={{ margin: 0 }}>
              <div className="ph-col-12 big"></div>
            </div>
          </div>
        </div>
      );
    default:
  }
}

export default function Placeholders({ type, number }) {
  const [placeholders, setPlaceholders] = useState([]);

  useEffect(() => {
    const list = [];

    for (let i = 0; i < number; i++) {
      list.push(renderPlaceholder(type));
    }

    setPlaceholders(list);
  }, [number, type]);

  return placeholders.map((element, i) => (
    <Fragment key={i}>{element}</Fragment>
  ));
}
