export const switchCircolari = () => {
  const fullPathName = window.location.pathname.split("/");
  const sectorName = fullPathName[1];
  const subsectorName = fullPathName && fullPathName[2] && fullPathName[2];
  // let subsector = address && address[2] && address[2].replaceAll("-", " ");
  const test = sectorName + " " + subsectorName;

  switch (test) {
    case "acqua circolari": {
      return {
        name: "acqua",
        servizi: ["ar/a", "a"],
        serviziSecondari: ["a"],
        aree: null,
        areeSecondarie: null,
      };
    }
    case "ambiente circolari": {
      return {
        name: "ambiente",
        servizi: ["ar/amb"],
        serviziSecondari: null,
        aree: ["ambiente"],
        areeSecondarie: ["ambiente"],
      };
    }
    case "energia circolari": {
      return {
        name: "energia",
        servizi: ["ar/g", "ar/e"],
        serviziSecondari: null,
        aree: ["Energia"],
        areeSecondarie: ["Energia"],
      };
    }
    case "area-affari-regolatori circolari": {
      return {
        name: "Area Affari Regolatori",
        servizi: null,
        serviziSecondari: null,
        aree: ["Area Affari Regolatori"],
        areeSecondarie: ["Area Affari Regolatori"],
      };
    }
    case "area-lavoro-relazioni-industriali circolari": {
      return {
        name: "Area Lavoro Relazioni Industriali",
        servizi: null,
        serviziSecondari: null,
        aree: ["lavoro"],
        areeSecondarie: ["lavoro"],
      };
    }
    case "area-giuridico-legislativa-fiscale circolari": {
      return {
        name: "Area Giuridico Legislativa Fiscale",
        aree: ["Giuridico-Legislativo"],
        areeSecondarie: ["Giuridico-Legislativo"],
        servizi: null,
        serviziSecondari: null,
      };
    }
    case "area-giuridico-legislativa-fiscale fiscale-e-tributario": {
      return {
        name: "Area Fiscale e Tributario",
        aree: null,
        areeSecondarie: null,
        servizi: ["ft"],
        serviziSecondari: ["ft"],
      };
    }
    case "direzione-generale circolari": {
      return {
        name: "Direzione Generale",
        aree: ["Direzione Generale"],
        areeSecondarie: ["Direzione Generale"],
        servizi: null,
        serviziSecondarie: null,
      };
    }
    case "csr-sostenibilita": {
      return {
        name: "Csr Sostenibilità",
        servizi: null,
        serviziSecondari: ["amb/s"],
        aree: null,
        areeSecondarie: null,
      };
    }
    case "appalti_e_concessioni circolari": {
      return {
        name: "Appalti e Concessioni",
        servizi: ["ac"],
        serviziSecondari: ["ac"],
        aree: null,
        areeSecondarie: null,
      };
    }
    case "area-strategie-pianificazione circolari": {
      return {
        name: "Area Strategie Pianificazione",
        servizi: ["SPS"],
        serviziSecondari: null,
        aree: ["SPS"],
        areeSecondarie: ["SPS"],
      };
    }

    default:
  }
};
