import "./NavigationCircleRight.scss";
import { BsArrowRight as ArrowRight } from "react-icons/bs";

export default function NavigationCircleRight(props) {
  return (
    <ArrowRight
      className={"arrow-right " + (props.swiper && "next")}
      style={{ borderColor: props.color }}
    />
  );
}
