import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "./Error.scss";

export default function Error() {
  return (
    <div id="pagina-non-trovata">
      <Helmet>
        <title>404 - Pagina non trovata</title>
      </Helmet>
      <p>Non troviamo la pagina che hai richiesto.</p>
      <Link to="/">Torna alla Homepage</Link>
    </div>
  );
}
