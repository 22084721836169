import React, { Component } from "react";
import moment from "moment";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import "./Evento.scss";
import NavigationCircleLeft from "components/navigation/circle/NavigationCircleLeft";
import { ReactComponent as Timer } from "icons/timer-bold.svg";
import { AiOutlineCalendar as Calendar } from "react-icons/ai";
import AttachmentButton from "components/buttons/attachmentButton";
import Placeholders from "components/placeholders/Placeholders";
import { urlify } from "functions/formatTextFunctions";

const colorEvent = (type) => {
  switch (type) {
    case "EVENTO INTERAMENTE UTILITALIA":
      return {
        color: "#eb640c",
        text: "Evento Utilitalia",
      };
    case "EVENTI ESTERNI DA SEGNALARE":
      return {
        color: "#00447b",
        text: "Utilitalia segnala",
      };
    case "EVENTO PATROCINATO O IN COLLABORAZIONE":
      return {
        color: "#07ca6e",
        text: "Patrocinio Utilitalia",
      };
    default:
  }
};

export default class Evento extends Component {
  state = { loading: true };

  async componentDidMount() {
    const eventoId = this.props.match.params.id;
    try {
      const response = await axios.post(
        "https://api.utilitalia.it/AGE/Events/Event",
        {
          appointmentId: eventoId,
          includePublicAttachments: true,
          includePrivateAttachments: true,
          includePublicTracts: true,
          includePrivateTracts: true,
          includeGalleryPictures: true,
        }
      );
      const evento = response?.data?.data?.appointment;
      this.setState({
        evento,
      });
    } catch (err) {
      console.log("Error fetching event", err);
    }
    try {
      const response = await axios.post(
        "https://api.utilitalia.it/AGE/Events/ICS",
        {
          appointmentId: eventoId,
        }
      );
      const ics = response?.data?.data?.data;
      this.setState({
        ics,
      });
    } catch (err) {
      console.log("Error fetching ics", err);
    }
    this.setState({ loading: false });
  }

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    const category = colorEvent(this.state.evento && this.state.evento.type);
    // console.log("evento", this.state.evento?.description);
    return (
      <div id="evento">
        <Helmet>
          <title>
            {`Utilitalia - Evento |
            ${this.state.evento?.subject || ""}`}
          </title>
          <meta
            name="description"
            content={this.state.evento?.description || ""}
          />
        </Helmet>
        <div className="container pad-mobile heading">
          {/* {this.state.evento && (
            <> */}
          <div className="back" onClick={this.goBack}>
            <NavigationCircleLeft />
            <span className="general">Torna a Calendario Eventi</span>
          </div>
          <div className="add">
            {this.state.evento &&
              moment().isBefore(moment(this.state.evento?.startDate)) &&
              this.state.ics && (
                <a
                  download={this.state.evento?.subject}
                  className="btn"
                  href={`data:text/calendar;base64,${this.state.ics}`}
                >
                  <Calendar />
                  <span>Aggiungi al calendario</span>
                </a>
              )}
          </div>
          <div className="details">
            <span className="date">
              {this.state.evento &&
                moment(this.state.evento?.startDate).format("ll")}
            </span>
            {this.state.evento &&
            moment(this.state.evento?.startDate).format("LT") !== "00:00" ? (
              <div className="time">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Timer />
                  <span>
                    {moment(this.state.evento?.startDate).format("LT")} -{" "}
                    {moment(this.state.evento?.endDate).format("LT")}
                  </span>
                </div>
              </div>
            ) : this.state.evento ? (
              <span className="date" style={{ marginLeft: 4 }}>
                {" - "}
                {moment(this.state.evento?.endDate).format("ll")}
              </span>
            ) : null}
            <span
              className="category"
              style={{ color: category && category.color }}
            >
              {category && category.text}
            </span>
          </div>
          {/* </>
          )} */}
        </div>
        <div className="bg-white">
          <div
            className="container pad-mobile content"
            style={{ maxWidth: "970px" }}
          >
            {this.state.loading ? (
              <Placeholders number={1} type="new" />
            ) : (
              <>
                <h1>{this.state.evento?.subject}</h1>
                <p
                  dangerouslySetInnerHTML={{
                    __html: urlify(this.state.evento?.description),
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingBottom: 32,
                    flexWrap: "wrap",
                  }}
                >
                  {this.state.evento?.urlWeb && (
                    <a href={this.state.evento?.urlWeb} className="btn">
                      Approfondisci
                    </a>
                  )}
                  {this.state.evento?.appointmentFiles &&
                    this.state.evento?.appointmentFiles
                      ?.filter((allegato) => allegato.fileType !== "GALLERY")
                      ?.map((allegato) => (
                        <AttachmentButton
                          text={
                            allegato.filePointer.description ||
                            allegato.filePointer?.file?.name ||
                            "Allegato"
                          }
                          id={allegato.filePointer.id}
                        />
                      ))}
                  {this.state.evento &&
                    moment(this.state.evento?.inscriptionStartDate) <
                      moment() &&
                    moment(this.state.evento?.inscriptionEndDate) >
                      moment() && (
                      <a
                        href={`https://portale.utilitalia.it/eventi/iscrizione?${this.state.evento?.id}`}
                        className="btn"
                      >
                        Iscriviti
                      </a>
                    )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
