import React, { Component } from "react";
import "./Notizia.scss";
import NavigationCircleLeft from "components/navigation/circle/NavigationCircleLeft";
import { FaFacebookF as Facebook } from "react-icons/fa";
import { FaLinkedinIn as Linkedin } from "react-icons/fa";
import Twitter from "../../../icons/twitter.svg";
import axios from "axios";
import { Helmet } from "react-helmet-async";
// import RenderIcon from "components/render-icon/RenderIcon";
import moment from "moment";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import Placeholders from "components/placeholders/Placeholders";
import AttachmentButton from "components/buttons/attachmentButton";
import RenderIcon from "components/render-icon/RenderIcon";

export default class Notizia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeNew: null,
      foto: null,
      loading: true,
      adnImg: null,
      italpressImg: null,
      quotidianoImg: null,
    };
  }

  async componentDidMount() {
    const newsId = this.props.match.params.id;
    const headers = {
      headers: { "Maestrale-Cache-Control": 1800 },
    };
    try {
      let activeNew = await axios.post(
        "https://api.utilitalia.it/DMS/Documents/Document",
        {
          documentId: newsId,
        },
        headers
      );

      activeNew = activeNew.data.data.document.values;

      this.setState({
        activeNew: activeNew,
      });
      if (activeNew && activeNew.find((value) => value.code === "ALLFOTO")) {
        let foto = await axios.post(
          "https://api.utilitalia.it/DMS/Files/File",
          {
            fileId: activeNew.find((value) => value.code === "ALLFOTO").value
              .id,
          },
          headers
        );
        foto = foto.data.data.data;
        this.setState({ foto: foto });
      }
      if (
        activeNew &&
        activeNew.find((value) => value.code === "CODICE") &&
        activeNew
          .find((value) => value.code === "CODICE")
          .value.startsWith("AS")
      ) {
        await axios
          .post(
            "https://api.utilitalia.it/CMS/Files/File",
            {
              fileName:
                "/newsletter/le ultime/newsletter-" +
                (activeNew &&
                  activeNew.find((value) => value.code === "CODICE").value) +
                ".png",
            },
            headers
          )
          .then((response) => {
            const logo = response?.data?.data?.data;
            if (logo) {
              this.setState({
                logo,
              });
            }
          });
      }
    } catch (err) {
      console.log("Error CDM Notizia", err);
    }
    this.setState({ loading: false });
  }

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <div id="notizia">
        <Helmet>
          <title>
            {`Utilitalia - Notizia |
            ${
              this.state.activeNew &&
              this.state.activeNew.find((value) => value.code === "TITOLO")
                .value
            }`}
          </title>
          <meta
            name="description"
            content={
              this.state.activeNew &&
              this.state.activeNew.find((value) => value.code === "THTML") &&
              cleanHtml(
                this.state.activeNew.find((value) => value.code === "THTML")
                  .value
              )
            }
          />
          <meta
            property="og:title"
            content={
              this.state.activeNew &&
              this.state.activeNew.find((value) => value.code === "TITOLO") &&
              this.state.activeNew.find((value) => value.code === "TITOLO")
                .value
            }
          />
          <meta
            property="og:description"
            content={
              this.state.activeNew &&
              this.state.activeNew.find((value) => value.code === "THTML") &&
              cleanHtml(
                this.state.activeNew.find((value) => value.code === "THTML")
                  .value
              )
            }
          />
          <meta
            property="twitter:title"
            content={
              this.state.activeNew &&
              this.state.activeNew.find((value) => value.code === "TITOLO") &&
              this.state.activeNew.find((value) => value.code === "TITOLO")
                .value
            }
          />
          <meta
            property="twitter:description"
            content={
              this.state.activeNew &&
              this.state.activeNew.find((value) => value.code === "THTML") &&
              cleanHtml(
                this.state.activeNew.find((value) => value.code === "THTML")
                  .value
              )
            }
          />
          <meta name="twitter:card" content="summary_large_image"></meta>
          {/* <meta
            property="og:image"
            content="http://euro-travel-example.com/thumbnail.jpg"
          /> */}
          <meta property="og:url" content="https://utilitalia.it" />
        </Helmet>
        <div className="container pad-mobile heading">
          <div className="back" onClick={this.goBack}>
            <NavigationCircleLeft />
            <span className="general">Torna a Notizie</span>
          </div>
          <div></div>
          {/* {!this.state.loading && (
            <div className="center">
              {this.state.activeNew.find((value) => value.code === "FONTE") && (
                <RenderIcon
                  fonte={
                    this.state.activeNew.find((value) => value.code === "FONTE")
                      .value
                  }
                />
              )}
            </div>
          )} */}
          <div className="details">
            {!this.state.loading && (
              <>
                <span className="date">
                  {moment(
                    this.state.activeNew &&
                      this.state.activeNew.find(
                        (value) => value.code === "DATA"
                      ).value
                  ).format("D MMM YYYY")}
                </span>
                <span className="category">UTILITALIA</span>
              </>
            )}
          </div>
        </div>
        <div
          className="bg-white"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            className="container pad-mobile content"
            style={{ width: "970px", paddingTop: "2rem" }}
          >
            {this.state.loading ? (
              <Placeholders number={1} type="new" />
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="date">
                    {this.state.activeNew &&
                      !!this.state.activeNew?.find(
                        (value) => value.code === "FONTE"
                      ) &&
                      this.state.activeNew.find(
                        (value) => value.code === "FONTE"
                      )?.value}
                  </span>
                  {!!this.state.activeNew?.find(
                    (value) => value.code === "FONTE"
                  ) && (
                    <RenderIcon
                      fonte={
                        this.state.activeNew.find(
                          (value) => value.code === "FONTE"
                        ).value
                      }
                    />
                  )}
                  {!!this.state.activeNew?.find(
                    (value) => value.code === "TESTATA"
                  ) && (
                    <RenderIcon
                      fonte={
                        this.state.activeNew.find(
                          (value) => value.code === "TESTATA"
                        ).value
                      }
                    />
                  )}
                </div>
                <span className="date">
                  {this.state.activeNew &&
                    !!this.state.activeNew.find(
                      (value) => value.code === "TESTATA"
                    ) &&
                    this.state.activeNew.find(
                      (value) => value.code === "TESTATA"
                    )?.value}
                </span>
                <h1>
                  {this.state.activeNew &&
                    this.state.activeNew.find(
                      (value) => value.code === "TITOLO"
                    ).value}
                </h1>
              </>
            )}

            {!this.state.loading &&
              this.state.activeNew &&
              this.state.activeNew.find((value) => value.code === "THTML") && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: this.state.activeNew.find(
                      (value) => value.code === "THTML"
                    ).value,
                  }}
                />
              )}
            {!this.state.loading &&
              this.state.activeNew &&
              this.state.activeNew.find((value) => value.code === "TESTO") && (
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      this.state.activeNew &&
                      this.state.activeNew.find(
                        (value) => value.code === "TESTO"
                      ).value,
                  }}
                />
              )}
            <div
              className="pdf-list"
              style={{ flexWrap: "wrap", marginBottom: 16 }}
            >
              {!this.state.loading &&
                this.state.activeNew
                  ?.filter((value) => value.code === "ALLDOC")
                  ?.map((news) => (
                    <AttachmentButton
                      text={news.value.description}
                      id={news.value.id}
                    />
                  ))}
              {!this.state.loading &&
                this.state.activeNew
                  ?.filter((value) => value.code === "ALLEGATO")
                  ?.map((news) => (
                    <AttachmentButton
                      text={news.value.description}
                      id={news.value.id}
                    />
                  ))}
            </div>
            <div className="share">
              <span className="general">Condividi su</span>
              <div className="social">
                <FacebookShareButton
                  url={"https://www.utilitalia.it" + this.props.match.url}
                  children={<Facebook />}
                />
                <TwitterShareButton
                  url={"https://www.utilitalia.it" + this.props.match.url}
                  children={<img src={Twitter} Alt="X" />}
                />
                <LinkedinShareButton
                  url={"https://www.utilitalia.it" + this.props.match.url}
                  children={<Linkedin />}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const cleanHtml = (html) => {
  if (!html) return "";
  return htmlDecode(html)
    .split(/<[^>]*>?/gm)
    .join("");
};

const htmlDecode = (html) => {
  var e = document.createElement("textarea");
  e.innerHTML = html;
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
};
