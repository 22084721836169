import React, { Component } from "react";
import { Helmet } from "react-helmet-async";
import "./ChiSiamoPartner.scss";
import IntroChiSiamo from "components/intro-chi-siamo/IntroChiSiamo";
import axios from "axios";
import Placeholders from "components/placeholders/Placeholders";

export default class ChiSiamoPartner extends Component {
  state = { loading: true, partners: [] };

  async componentDidMount() {
    try {
      let partners = await axios.post(
        "https://api.utilitalia.it/DMS/Documents/List",
        {
          documentClasses: ["CONTENUTO_WEB"],
          heading: "\\CHI_SIAMO",
          folder: "PARTNER",
          documentValueOrders: [
            {
              code: "POSIZIONE",
              type: "3",
              direction: "asc",
            },
          ],
        }
      );
      partners = partners?.data?.data?.documents;

      if (partners) {
        partners = partners.map(async (element) => {
          if (
            element?.values?.find(
              (value) => value.code === "ALLEGATO_PRINCIPALE"
            )
          ) {
            let foto = await axios.post(
              "https://api.utilitalia.it/DMS/Files/File",
              {
                fileId: element?.values?.find(
                  (value) => value?.code === "ALLEGATO_PRINCIPALE"
                )?.value?.id,
              }
            );
            foto = foto?.data?.data?.data;
            return { ...element, foto };
          } else {
            return { ...element };
          }
        });

        partners = await Promise.all(partners);
        this.setState({
          partners,
        });
      }
    } catch (err) {
      console.log("Error CDM ChiSiamoPartner", err);
    }
    this.setState({ loading: false });
  }

  render() {
    return (
      <div id="chi-siamo-partner">
        <Helmet>
          <title>Utilitalia - Partner</title>
          <meta
            name="description"
            content="Utilitalia partecipa con Asstra (Associazione delle aziende di trasporto pubblico locale) alla Confservizi, Confederazione per i servizi di tipo industriale di pubblica utilità."
          />
        </Helmet>
        <IntroChiSiamo section="Partner" />
        <div className="container">
          {this.state.loading ? (
            <Placeholders number={1} type="codice-etico" />
          ) : (
            this.state.partners?.map((element, i) => (
              <div className="item" key={i}>
                {element.foto && (
                  <img src={"data:image/jpeg;base64," + element.foto} alt="" />
                )}
                <h3
                  style={{ marginBottom: "1rem", fontSize: "1.2rem" }}
                  dangerouslySetInnerHTML={{
                    __html: element.values.find(
                      (value) => value.code === "TITOLO"
                    ).value,
                  }}
                />
                <div
                  style={{ marginBottom: "2rem" }}
                  dangerouslySetInnerHTML={{
                    __html: element.values.find(
                      (value) => value.code === "TESTO"
                    ).value,
                  }}
                />
              </div>
            ))
          )}
        </div>
      </div>
    );
  }
}
