import { useEffect, useState } from "react";
import axios from "axios";
import "./ChiSiamoBrochure.scss";
import IntroChiSiamo from "components/intro-chi-siamo/IntroChiSiamo";
import Spinner from "components/spinner/Spinner";
import { ReactComponent as Download } from "icons/file-arrow-down-bold.svg";
import Brochure from "img/brochure-img.png";
import AttachmentButton from "components/buttons/attachmentButton";

export default function ChiSiamoBrochure() {
  const [brochures, setBrochures] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchBrochures();
  }, []);

  const fetchBrochures = async () => {
    try {
      const response = await axios.post(
        "https://api.utilitalia.it/DMS/Documents/List",
        {
          documentClasses: ["CONTENUTO_WEB"],
          heading: "\\CHI_SIAMO",
          folder: "BROCHURE",
          documentValueOrders: [
            {
              code: "TITOLO",
              type: "1",
              direction: "asc",
            },
          ],
        }
      );
      const brochure = response?.data?.data?.documents[0]?.values;
      if (brochure) {
        setBrochures(brochure);
      }
    } catch (err) {
      console.log("Error fetching brochrues ChiSiamoBrochure", err);
    }
    setLoading(false);
  };

  return (
    <div className="chi-siamo-brochure-page">
      <IntroChiSiamo section="Brochure" background="blue" />

      <div className="container pad-mobile">
        <h2 className="general">
          Le nostre <span>Brochure</span>
        </h2>

        {loading ? (
          <Spinner />
        ) : (
          <div className="brochures-list">
            {brochures.slice(1, 3).map((element, i) => (
              <div className="brochure" key={i}>
                <Download />
                <img src={Brochure} alt="Brochure" />
                <p>{element.value.file.name}</p>

                <AttachmentButton text="Scarica" id={element.value.id} />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
