import React, { Component } from "react";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import NavigationCircleLeft from "components/navigation/circle/NavigationCircleLeft";
import NavigationCircleRight from "components/navigation/circle/NavigationCircleRight";
import AttachmentButton from "components/buttons/attachmentButton";
import Placeholders from "components/placeholders/Placeholders";
import { isMobile } from "react-device-detect";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Atto.scss";
import Bluebird from "bluebird";

const colorEvent = (type) => {
  switch (type) {
    case "EVENTO INTERAMENTE UTILITALIA":
      return {
        color: "#eb640c",
        text: "Evento Utilitalia",
      };
    case "EVENTI ESTERNI DA SEGNALARE":
      return {
        color: "#00447b",
        text: "Utilitalia segnala",
      };
    case "EVENTO PATROCINATO O IN COLLABORAZIONE":
      return {
        color: "#07ca6e",
        text: "Patrocinio Utilitalia",
      };
    default:
      return type;
  }
};

export default class Atto extends Component {
  state = {
    gallery: [],
    loadingGallery: {},
  };

  async componentDidMount() {
    this.setState({ loading: true });
    try {
      const eventoId = this.props.match.params.id;
      const response = await axios.post(
        "https://api.utilitalia.it/AGE/Events/Event",
        {
          appointmentId: eventoId,
          includePublicTracts: "true",
          includePrivateTracts: "true",
          includeGalleryPictures: "true",
        }
      );
      const evento = response?.data?.data?.appointment;
      this.setState({
        evento,
      });
      this._loadGallery(
        evento?.appointmentFiles?.filter(
          (allegato) => allegato.fileType === "GALLERY"
        )
      );
    } catch (err) {
      console.log("Error CDM Atto", err);
    }
    this.setState({ loading: false });
  }

  _loadGallery = async (gallery) => {
    try {
      if (gallery && gallery.length > 0) {
        const loadingGallery = gallery.reduce((acc, curr) => {
          const fileId = curr?.filePointer?.id;
          return {
            ...acc,
            [fileId]: true,
          };
        }, {});
        this.setState({ gallery, loadingGallery });
        await Bluebird.mapSeries(gallery, async (element) => {
          const fileId = element?.filePointer?.id;
          let foto = await axios.post(
            "https://api.utilitalia.it/DMS/Files/File",
            {
              fileId,
            }
          );
          foto = foto?.data?.data?.data;
          foto = "data:image/jpeg;base64," + foto;
          this.setState({
            gallery: this.state.gallery.map((image) => {
              const compId = image?.filePointer?.id;
              if (compId === fileId) {
                return { ...image, foto };
              }
              return image;
            }),
            loadingGallery: {
              ...this.state.loadingGallery,
              [fileId]: false,
            },
          });
        });
      }
    } catch (err) {
      console.log("Error loading gallery Atto", err);
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    const category = colorEvent(this.state.evento && this.state.evento.type);
    return (
      <div id="evento">
        <Helmet>
          <title>
            {`Utilitalia - Atto
            ${this.state.evento ? " | " + this.state.evento.subject : ""}`}
          </title>
          <meta
            name="description"
            content={this.state.evento && this.state.evento.description}
          />
        </Helmet>
        <div
          className="container pad-mobile heading"
          style={{ justifyContent: "space-between" }}
        >
          {this.state.evento && (
            <>
              <div className="back" onClick={this.goBack}>
                <NavigationCircleLeft />
                <span className="general">Torna alla lista</span>
              </div>

              <div className="details">
                <span className="date">
                  {moment(this.state.evento.startDate).format("ll")}
                </span>
                <span className="category" style={{ color: category.color }}>
                  {category.text}
                </span>
              </div>
            </>
          )}
        </div>
        <div className="bg-white">
          <div
            className="container pad-mobile content"
            style={{ maxWidth: 970 }}
          >
            {this.state.loading ? (
              <Placeholders number={1} type="new" />
            ) : this.state.evento ? (
              <div>
                <h1>{this.state.evento.subject}</h1>
                <p>{this.state.evento.description}</p>
                {this.state.evento.urlVideo && (
                  <div
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <embed
                      className="video"
                      src={this.state.evento.urlVideo}
                      autostart="false"
                      //   width="60%"
                      //   height="400px"
                    ></embed>
                  </div>
                )}
                {/* <div>
                  {this.state.gallery.map((image) => {
                    return <img src={image.foto} alt={image.filePointer.file.name} style={{maxWidth: '100%'}} />;
                  })}
                </div> */}
                <Gallery
                  loading={this.state.loadingGallery}
                  gallery={this.state.gallery}
                />
                <div className="pdf-list" style={{ flexWrap: "wrap" }}>
                  {this.state.evento.urlWeb && (
                    <a href={this.state.evento.urlWeb} className="btn">
                      Approfondisci
                    </a>
                  )}
                  {this.state.evento.appointmentFiles &&
                    this.state.evento.appointmentFiles
                      .filter((allegato) => allegato.fileType !== "GALLERY")
                      .map((allegato, i) => (
                        <AttachmentButton
                          key={i}
                          text={
                            allegato.filePointer.description ||
                            allegato.filePointer.file.name
                          }
                          id={allegato.filePointer.id}
                        />
                      ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const Gallery = (props) => {
  const { loading, gallery } = props;
  return (
    <Swiper
      effect={"fade"}
      fadeEffect={{ crossFade: true }}
      speed={800}
      // autoplay={{ delay: 5000, disableOnInteraction: false }}
      initialSlide={1}
      loop={true}
      navigation={{
        nextEl: ".next",
        prevEl: ".prev",
      }}
      style={{ background: "#ccc", margin: "2rem 0" }}
    >
      {gallery.map((image, i) => {
        const fileId = image.filePointer && image.filePointer.id;
        return (
          <SwiperSlide key={i}>
            {!!loading[fileId] ? (
              <div
                className="ph-item"
                style={{ padding: 0, border: 0, margin: 0 }}
              >
                <div
                  className="ph-col-12"
                  style={{ padding: 0, border: 0, margin: 0 }}
                >
                  <div
                    className="ph-picture"
                    style={{
                      height: isMobile ? "30vh" : "60vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: isMobile ? 16 : 24,
                      fontWeight: "bold",
                      color: "#999",
                      textAlign: "center",
                    }}
                  >
                    {image.filePointer.file.name}
                  </div>
                </div>
              </div>
            ) : (
              <img
                src={image.foto}
                alt={image.filePointer.file.name}
                style={{
                  width: "100%",
                  height: isMobile ? "30vh" : "60vh",
                  objectFit: "contain",
                }}
              />
            )}
          </SwiperSlide>
        );
      })}
      <div className="controls">
        <NavigationCircleLeft
          swiper={true}
          color="#fff"
          style={{ cursor: "pointer" }}
        />
        <NavigationCircleRight
          swiper={true}
          color="#fff"
          style={{ cursor: "pointer" }}
        />
      </div>
    </Swiper>
  );
};
