import React, { Component } from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import "./Contatti.scss";
import { renderAttribute } from "functions/renderAttribute";
import Placeholders from "components/placeholders/Placeholders";

export default class Contatti extends Component {
  state = {
    contacts: [],
    loading: true,
  };

  async componentDidMount() {
    try {
      const response = await axios.post(
        "https://api.utilitalia.it/DMS/Documents/List",
        {
          documentClasses: ["CONTATTI"],
          heading: "\\CHI_SIAMO",
          folder: "CONTATTI",
          documentValueOrders: [
            {
              code: "POSIZIONE",
              type: "3",
              direction: "asc",
            },
          ],
        }
      );
      const contacts = response?.data?.data?.documents;
      this.setState({
        contacts: contacts || [],
      });
    } catch (err) {
      console.log("Error CDM Contatti", err);
    }
    this.setState({ loading: false });
  }

  renderEmail = (email) => {
    if (!email) return "";
    return email.split(`\r\n`).map((em) => {
      return (
        <p>
          <strong>Email:</strong>{" "}
          <a style={{ cursor: "pointer" }} href={`mailto:${em}`}>
            {em}
          </a>
        </p>
      );
    });
  };

  render() {
    return (
      <div id="contatti">
        <Helmet>
          <title>Utilitalia - Contatti</title>
          <meta
            name="description"
            content="Entra in contatto con Utilitalia e scrivici per ricevere ulteriori informazioni​. Siamo a tua completa disposizione"
          />
        </Helmet>
        <div className="hero"></div>
        <div className="container pad-mobile">
          <h2 className="general">
            I nostri <span>contatti</span>
          </h2>
          <div className="boxes">
            {this.state.loading ? (
              <Placeholders number={9} type="contatto" />
            ) : (
              this.state.contacts.map((element, i) => (
                <div className="box" key={i}>
                  <h4 style={{ flex: 1 }}>
                    {renderAttribute(element.values, "TITOLO")}
                  </h4>
                  <div style={{ flex: 3 }}>
                    {renderAttribute(element.values, "EMAIL") &&
                      this.renderEmail(
                        renderAttribute(element.values, "EMAIL")
                      )}

                    {renderAttribute(element.values, "PEC") && (
                      <p>
                        <strong>Pec:</strong>{" "}
                        {renderAttribute(element.values, "PEC")}
                      </p>
                    )}
                    {renderAttribute(element.values, "TEL") && (
                      <p>
                        <strong>Tel:</strong>{" "}
                        <a
                          href={`tel:${renderAttribute(element.values, "TEL")}`}
                        >
                          {renderAttribute(element.values, "TEL")}
                        </a>
                      </p>
                    )}
                    {renderAttribute(element.values, "FAX") && (
                      <p>
                        <strong>Fax:</strong>{" "}
                        {renderAttribute(element.values, "FAX")}
                      </p>
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    );
  }
}
