import "./Compatibility.scss";
import Logo from "img/logo/utilitalia-logo.png";

const Compatibility = () => (
  <div className="compatibility-page">
    <div className="content">
      <img src={Logo} alt="" />

      <h1>
        Il tuo browser non è più supportato.
        <br />
        Utilizza un altro browser.
      </h1>
    </div>
  </div>
);

export default Compatibility;
