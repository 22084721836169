import React, { Component } from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import "./NewsMediaRassegnaStampa.scss";
import NavigationCircle from "components/navigation/circle/NavigationCircle";
import Filter from "components/filter/Filter";
import { Link } from "react-router-dom";
import Placeholders from "components/placeholders/Placeholders";
import moment from "moment";

const activeYear = moment().year();

const years = [];

for (let i = 2015; i <= activeYear; i++) {
  years.unshift(i);
}

const getId = element => {
    return typeof element?.talkingId !== 'undefined' && element?.talkingId.trim() !== '' && element?.talkingId !== null && element?.talkingId !== false  ? element.talkingId : element.id;
}

export default class NewsMediaRassegnaStampa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rassegna: [],
      rassegnaFrom: 1,
      loadingRassegna: true,
      active: this.initialStateFilter,
      startYear: 2017,
      years,
    };
  }

  initialStateFilter = {
    year: activeYear,
  };

  async componentDidMount() {
    try {
      const response = await this.fetchRassegna();
      const rassegna = response?.data?.data?.documents;
      this.setState({
        rassegna: rassegna || [],
      });
      const bakFilter = sessionStorage.getItem("rassegna_stampa_filter");
      if (bakFilter) {
        this.setState({
          ...this.state,
          active: JSON.parse(bakFilter),
        });
      }
    } catch (err) {
      console.log("Error CDM NewsMediaRassegnaStampa", err);
    }
    this.setState({ loadingRassegna: false });
  }

  async componentDidUpdate(oldProps, oldState) {
    window.location.href = '/';
    if (
      this.state.rassegnaFrom !== oldState.rassegnaFrom ||
      this.state.active.year !== oldState.active.year
    ) {
      window.scrollTo(0, 0);
      this.setState({ loadingRassegna: true });
      sessionStorage.setItem(
        "rassegna_stampa_filter",
        JSON.stringify(this.state.active)
      );
      try {
        const response = await this.fetchRassegna();
        const rassegna = response?.data?.data?.documents;
        if (rassegna && rassegna.length === 0) {
          this.setState({ rassegnaFrom: 1 });
        }
        this.setState({
          rassegna: rassegna || [],
        });
      } catch (err) {
        console.log("Err CDU NewsMediaRassegnaStampa", err);
      }
      this.setState({ loadingRassegna: false });
    }
  }

  handleFilter = (year) => {
    this.setState({ year });
  };

  fetchRassegna = async () => {
    const headers = {
      headers: { "Maestrale-Cache-Control": 3600 },
    };
    return await axios.post(
      "https://api.utilitalia.it/DMS/Documents/List",
      {
        documentClasses: ["RASSEGNA"],
        heading: "\\COM\\RS\\PREW",
        folder: this.state.active.year,
        pageStart: this.state.rassegnaFrom,
        pageSize: 16,
        documentValueOrders: [
          {
            code: "DATA",
            type: "5",
            direction: "desc",
          },
        ],
      },
      headers
    );
  };

  handleRassegnaNavigation = (delta) => {
    this.setState({ rassegnaFrom: this.state.rassegnaFrom + delta * 16 });
  };

  handleChange = (value, target) => {
    this.setState({ active: { ...this.state.active, [target]: value } });
  };

  handleSubmit = () => {
    this.setState({ loadingRassegna: true });
    this.fetchRassegna().then((response) => {
      const rassegna = response?.data?.data?.documents;
      if (rassegna) {
        this.setState({
          rassegna,
          loadingRassegna: false,
        });
      }
    });
  };

  handleReset = () => {
    this.setState({
      active: this.initialStateFilter,
    });
  };

  render() {
    const filters = [
      {
        name: "year",
        text: "Seleziona l'anno",
        values: this.state.years,
        value: this.state.active.year,
      },
    ];
    // console.log("news", this.state.rassegna);
    return (
      <div id="news-media-rassegna-stampa">
        <Helmet>
          <title>Utilitalia - Rassegna Stampa</title>
          <meta
            name="description"
            content="Visita il sito e scopri tutti i comunicati stampa redatti dall'Area comunicazione di Utilitalia"
          />
        </Helmet>
        <div className="container pad-mobile">
          <h2 className="general">
            Rassegna <span>Stampa</span>
          </h2>
          <Filter
            filters={filters}
            change={this.handleChange}
            reset={this.handleReset}
            value={this.state.active}
          />
          <div className="rassegna-list">
            {this.state.loadingRassegna ? (
              <Placeholders number={12} type="rassegna" />
            ) : (
              this.state.rassegna.map((article, i) => (
                <div
                  className="article"
                  key={i}
                  style={{
                    backgroundColor: "white",
                    padding: 24,
                  }}
                >
                  <span className="date">
                    {moment(
                      article.values.find((value) => value.code === "DATA")
                        .value
                    ).format("DD/MM/YYYY")}
                  </span>
                  <span className="general">
                    {
                      article.values.find((value) => value.code === "TESTATA")
                        .value
                    }
                  </span>
                  <h4
                    style={{
                      borderTop: "2px solid #eee",
                      borderBottom: "2px solid #eee",
                    }}
                  >
                    {
                      article.values.find((value) => value.code === "TITOLO")
                        .value
                    }
                  </h4>
                  <Link to={"/notizia/" + getId(article)} className="btn">
                    Leggi di più
                  </Link>
                </div>
              ))
            )}
          </div>
          {this.state.rassegna.length === 0 && !this.state.loadingRassegna && (
            <div style={{ backgroundColor: "white", padding: 24 }}>
              Nessun risultato trovato
            </div>
          )}
          {this.state.rassegna.length > 0 && (
            <NavigationCircle
              function={this.handleRassegnaNavigation}
              disabled={this.state.rassegnaFrom === 1}
            />
          )}
        </div>
      </div>
    );
  }
}
