import Switch from "components/switch/Switch";
import React, { useState } from "react";
import "./CookieBanner.scss";
import { CaretDown, CaretUp } from "phosphor-react";

const CookieTypes = [
  {
    name: "Strettamente necessari",
    description:
      "Questi strumenti di tracciamento sono strettamente necessari per garantire il funzionamento e la fornitura del servizio che ci hai richiesto e, pertanto, non richiedono il tuo consenso.",
    alwaysChecked: true,
    target: "essential",
  },
  //   {
  //     name: "Interazioni e funzionalità semplici",
  //     description:
  //       "Questi strumenti di tracciamento abilitano semplici interazioni e funzionalità che ti permettono di accedere a determinate risorse del nostro servizio e ti consentono di comunicare più facilmente con noi.",
  //     target: "simple",
  //   },
  //   {
  //     name: "Miglioramento dell’esperienza",
  //     description:
  //       "Questi strumenti di tracciamento ci permettono di offrire una user experience personalizzata migliorando la gestione delle impostazioni e consentendo l'interazione con network e piattaforme esterne.",
  //     target: "enhanced",
  //   },
  {
    name: "Misurazione",
    description:
      "Questi strumenti di tracciamento ci permettono di misurare il traffico e analizzare il tuo comportamento con l'obiettivo di migliorare il nostro servizio.",
    target: "tracking",
  },
  //     {
  //       name: "Targeting e Pubblicità",
  //       description:
  //         "Questi strumenti di tracciamento ci consentono di fornirti contenuti commerciali personalizzati in base al tuo comportamento e di gestire, fornire e tracciare gli annunci pubblicitari.",
  //     },
];

const CookieBanner = (props) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
    document.body.style.position = "fixed";
    document.body.style.top = `-${window.scrollY}px`;
  };
  const handleClosePopup = () => {
    setIsPopupOpen(false);
    const scrollY = document.body.style.top;
    document.body.style.position = "relative";
    document.body.style.top = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
  };
  return (
    <div class="cookie-consent-banner">
      {isPopupOpen && (
        <CookiePopup
          handleAcceptAllCookies={props.handleAcceptAllCookies}
          handleRejectAllCookies={props.handleRejectAllCookies}
          handleAcceptCustomCookies={props.handleAcceptCustomCookies}
          handleClosePopup={handleClosePopup}
        />
      )}
      <div className="cookie-consent-banner__inner">
        <div className="cookie-consent-banner__copy">
          <div className="cookie-consent-banner__header">INFORMATIVA</div>
          <div className="cookie-consent-banner__description">
            Questo sito o gli strumenti terzi da questo utilizzati si avvalgono
            di cookie necessari al funzionamento ed utili alle finalità
            illustrate nella pagina: Privacy Policy. Scorrendo questa pagina,
            chiudendo il banner, cliccando su un link o proseguendo la
            navigazione in altra maniera, si acconsente all’uso dei cookie.
          </div>
        </div>

        <div className="cookie-consent-banner__actions">
          <a
            onClick={() => handleOpenPopup()}
            className="btn"
            style={{ textTransform: "uppercase", cursor: "pointer" }}
          >
            SCOPRI DI PIù E PERSONALIZZA
          </a>
          <div style={{ display: "flex", alignItems: "center" }}>
            <a
              onClick={() => props.handleRejectAllCookies(CookieTypes)}
              href="#"
              className="btn"
              style={{ marginRight: "12px" }}
            >
              RIFIUTA
            </a>
            <a
              onClick={() => props.handleAcceptAllCookies(CookieTypes)}
              href="#"
              className="btn"
            >
              ACCETTA
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;

const CookiePopup = (props) => {
  const [cookieTypesStatus, setCookieTypesStatus] = useState([]);
  return (
    <div id="popup1" className="overlay-banner">
      <div className="popup">
        <h2 style={{ marginBottom: "1rem" }}>
          Le tue preferenze relative al consenso
        </h2>
        <div className="close" onClick={() => props.handleClosePopup()}>
          &times;
        </div>
        <div className="content">
          Il seguente pannello ti consente di esprimere le tue preferenze di
          consenso alle tecnologie di tracciamento che adottiamo per offrire le
          funzionalità e svolgere le attività sotto descritte. Per ottenere
          ulteriori informazioni in merito all'utilità e al funzionamento di
          tali strumenti di tracciamento, fai riferimento alla cookie policy.
          Puoi rivedere e modificare le tue scelte in qualsiasi momento. Tieni
          presente che il rifiuto del consenso per una finalità particolare può
          rendere le relative funzioni non disponibili.
        </div>
        <div
          className="cookie-consent-banner__actions"
          style={{ paddingBottom: "1rem" }}
        >
          <div></div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <a
              onClick={() => {
                props.handleClosePopup();
                props.handleRejectAllCookies(CookieTypes);
              }}
              className="btn"
              style={{ marginRight: "12px", cursor: "pointer" }}
            >
              RIFIUTA TUTTO
            </a>
            <a
              onClick={() => {
                props.handleClosePopup();
                props.handleAcceptAllCookies(CookieTypes);
              }}
              className="btn"
              style={{ cursor: "pointer" }}
            >
              ACCETTA TUTTO
            </a>
          </div>
        </div>
        {CookieTypes?.map((type, i) => (
          <SwitchRow
            type={type}
            key={`TYPE_${i}`}
            setCookieTypesStatus={setCookieTypesStatus}
            cookieTypesStatus={cookieTypesStatus}
          />
        ))}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            paddingTop: "1rem",
          }}
        >
          <a
            onClick={() => {
              props.handleClosePopup();
              props.handleAcceptCustomCookies(cookieTypesStatus);
            }}
            className="btn"
            style={{ textTransform: "uppercase", cursor: "pointer" }}
          >
            SALVA E CONTINUA
          </a>
        </div>
      </div>
    </div>
  );
};

const SwitchRow = ({ type, setCookieTypesStatus, cookieTypesStatus }) => {
  const [isToggleOpen, setIsToggleOpen] = useState(false);
  return (
    <>
      <div className="switch-row">
        <div className="cookie-type">{type.name}</div>
        <div className="right-section">
          <p
            className="show-description"
            onClick={() => setIsToggleOpen(!isToggleOpen)}
          >
            {isToggleOpen ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <span>Nascondi descrizione</span>{" "}
                <CaretUp size={16} weight={"bold"} />
              </div>
            ) : (
              <div style={{ display: "flex", alignItems: "center" }}>
                <span>Mostra descrizione</span>{" "}
                <CaretDown size={16} weight={"bold"} />
              </div>
            )}
          </p>
          <Switch
            name={type.name}
            target={type.target}
            alwaysChecked={type.alwaysChecked}
            setCookieTypesStatus={setCookieTypesStatus}
            cookieTypesStatus={cookieTypesStatus}
          />
        </div>
      </div>
      {isToggleOpen && <div className="toggle-section">{type.description}</div>}
    </>
  );
};
