import React, { Component } from "react";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import moment from "moment";
import CircularsList from "components/circulars-list/CircularsList";
import DocumentsList from "components/documents-list/DocumentsList";
import Filter from "components/filter/Filter";
import Pagination from "components/pagination/Pagination";

const activeYear = new Date().getFullYear();

const years = [];
const documentsYears = [
  2015, 2014, 2013, 2012, 2011, 2010, 2009, 2008, 2007, 2006, 2005, 2004, 2003,
  2002, 2001, 2000, 1999, 1998, 1996,
];

for (let i = 2015; i <= activeYear; i++) {
  years.unshift(i);
}

export default class Circolari extends Component {
  state = {
    circolari: [],
    documenti: [],
    loading: true,
    active: {
      year: moment().format("YYYY"),
      archiveYear: "2015",
      area: "SELEZIONA",
      archiveValue: "",
    },
    totalPages: 1,
    pageNumber: 0,
  };

  async componentDidMount() {
    try {
      const areaFilter = await this.fetchAreaFilters();
      const resCircolari = await this.fetchCircolari(this.state.pageNumber);
      const circolari = resCircolari?.data?.data;
      this.setState({
        areaFilter: areaFilter?.data?.data?.values,
        circolari: circolari?.circolari,
        totalPages: circolari?.totalPages,
      });
      const bakFilter = sessionStorage.getItem("circolari_filtri");
      if (bakFilter) {
        this.setState({
          ...this.state,
          active: JSON.parse(bakFilter),
        });
      }
    } catch (err) {
      console.log("Error CDM Circolari", err);
    }

    this.setState({ loading: false });
  }

  async componentDidUpdate(oldProps, oldState) {
    if (
      this.state.active.year !== oldState.active.year ||
      this.state.active.area !== oldState.active.area
    ) {
      sessionStorage.setItem(
        "circolari_filtri",
        JSON.stringify(this.state.active)
      );
      this.setState({
        loading: true,
      });
      try {
        const response = await this.fetchCircolari(0);
        this.setState({
          circolari: response?.data?.data?.circolari,
          totalPages: response?.data?.data?.totalPages,
          pageNumber: 0,
        });
      } catch (err) {
        console.log("Error fetching circolari Circolari", err);
      }
      this.setState({ loading: false });
    } else if (
      this.state.active.archiveYear !== oldState.active.archiveYear ||
      (!!this.state.active.archiveValue &&
        this.state.active.archiveValue !== "SELEZIONA" &&
        this.state.active.archiveValue !== oldState.active.archiveValue)
    ) {

      this.setState({
        ...this.state,
        active: {
          ...this.state.active,
          area: "SELEZIONA",
          archiveYear: this.state.active.archiveYear,
        },
      });
      sessionStorage.setItem(
        "circolari_filtri",
        JSON.stringify(this.state.active)
      );
      try {
        const response = await this.fetchArchive(0);
        this.setState({
          documenti: response?.data?.data?.documents,
          totalPages: response?.data?.data?.totalPages,
          pageNumber: 0,
        });
      } catch (err) {
        console.log("Error fetching circolari Circolari", err);
      }
      this.setState({ loading: false });
    }
  }

  fetchCircolari = async (page) => {
    return await axios.post(
      "https://api.utilitalia.it/UTI/CIR/Circolari/List",
      {
        aree:
          this.state.active.area && this.state.active.area !== "SELEZIONA"
            ? [this.state.active.area]
            : null,
        startDate: this.state.active.year
          ? this.state.active.year + "-01-01"
          : moment().format("YYYY") + "-01-01",
        endDate: this.state.active.year
          ? moment(this.state.active.year).add(1, "y").format("YYYY-MM-DD")
          : moment().add(1, "y").format("YYYY-MM-DD"),
        pageSize: "20",
        pageStart: (page || 0) + 1,
        orderDirection: "desc",
      }
    );
  };

  fetchArchive = async (page) => {
    return await axios.post("https://api.utilitalia.it/DMS/Documents/List", {
      documentClasses: ["CIRCOLARI"],
      heading:
        this.state.active.archiveValue &&
        this.state.active.archiveValue !== "SELEZIONA"
          ? `\\ARCHIVIO\\${this.state.active.archiveValue?.toUpperCase()}`
          : "",
      folder: this.state.active.archiveYear,
      documentValueDefs: [
        {
          code: "NUMERO",
        },
        {
          code: "DATA",
        },
        {
          code: "OGGETTO",
        },
      ],
      pageSize: 20,
      pageStart: (page || 0) + 1,
      documentValueOrders: [
        {
          code: "DATA",
          type: "5",
          direction: "desc",
        },
      ],
    });
  };

  fetchAreaFilters = async () => {
    return await axios.post(
      "https://api.utilitalia.it/UTI/CIR/Circolari/AreaLookup",
      {
        orderDirection: "asc",
      }
    );
  };

  handleChange = (value, target) => {
    this.setState({ active: { ...this.state.active, [target]: value } });
  };

  // handleSubmit = (event) => {
  //   event.preventDefault();
  // };

  handleReset = async () => {
    this.setState({
      active: {
        year: moment().format("YYYY"),
        archiveYear: "2015",
        area: "SELEZIONA",
        archiveValue: "SELEZIONA",
      },
      documenti: [],
      circolari: [],
    });
    // delete sessionStorage.circolari_filtri;

    sessionStorage.removeItem("circolari_filtri");

    const response = await this.fetchCircolari(0);
    this.setState({
      circolari: response?.data?.data?.circolari,
      totalPages: response?.data?.data?.totalPages,
      pageNumber: 0,
    });
  };

  handlePageClick = async (data) => {
    this.setState({ loading: true });
    try {
      window.scrollTo(0, 0);
      let { selected } = data;
      if(!!this.state.active.archiveValue &&
        this.state.active.archiveValue !== "SELEZIONA" ) {

      const response = await this.fetchArchive((selected || 0) * 20);
      this.setState({
        documenti: response?.data?.data?.documents,
        pageNumber: data?.selected,
      });
      } else {
        const response = await this.fetchCircolari((selected || 0) * 20);
          this.setState({
            circolari: response?.data?.data?.circolari,
            pageNumber: data?.selected,
          });
      }
    } catch (err) {
      console.log("Error fetching circolari handlePageClick Circolari", err);
    }
    this.setState({ loading: false });
  };

  render() {
    const filters = [
      {
        name: "year",
        text: "Seleziona l'anno",
        values: years,
      },
      {
        name: "area",
        text: "Settore/Area",
        values: this.state.areaFilter ? [...this.state.areaFilter] : [],
      },
      {
        name: "archiveValue",
        text: "Archivio Storico",
        values: ["Federambiente", "Federutility"],
        placeholder: "ARCHIVIO STORICO",
      },
    ];
    const archiveFilters = [
      {
        name: "archiveYear",
        text: "Seleziona l'anno",
        values: documentsYears,
      },
      {
        name: "archiveValue",
        text: "Archivio Storico",
        values: ["Federambiente", "Federutility"],
        placeholder: "ARCHIVIO STORICO",
      },
    ];
    return (
      <div id="circolari">
        <Helmet>
          <title>Utilitalia - Circolari</title>
          <meta
            name="description"
            content="In questa sezione è possibile visionare e scaricare tutte le circolari rilasciate da Utilitalia. Visita il sito "
          />
        </Helmet>
        <div className="container pad-mobile">
          <h2 className="general">Circolari</h2>
          <Filter
            filters={
              !!this.state.active.archiveValue &&
              this.state.active.archiveValue !== "SELEZIONA"
                ? archiveFilters
                : filters
            }
            change={this.handleChange}
            value={this.state.active}
            reset={this.handleReset}
            resetButton={
              (this.state.active.year !== moment().format("YYYY") ||
                this.state.active.area !== "Tutti") &&
              "show"
            }
          />
          {}
          {this.state.documenti &&
          this.state.active.area === "SELEZIONA" &&
          this.state.documenti?.length > 0 ? (
            <DocumentsList
              documents={this.state.documenti}
              loading={this.state.loading}
              col={4}
            />
          ) : (
            <CircularsList
              circulars={this.state.circolari}
              loading={this.state.loading}
              col={4}
            />
          )}
          {this.state.totalPages > 1 && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                paddingTop: 32,
              }}
            >
              <Pagination
                totalPages={this.state.totalPages}
                handlePageClick={this.handlePageClick}
                pageNumber={this.state.pageNumber}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
