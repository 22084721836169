import React, { Component } from "react";
import axios from "axios";
import IntroSection from "components/intro-section/IntroSection";
import "components/expand/Expand.scss";
import moment from "moment";
import { FaAngleDown as Down } from "react-icons/fa";
import { FaAngleUp as Up } from "react-icons/fa";
import { Link } from "react-router-dom";
const getId = element => {
    return typeof element?.talkingId !== 'undefined' && element?.talkingId.trim() !== '' && element?.talkingId !== null && element?.talkingId !== false  ? element.talkingId : element.id;
}

export default class AmbienteQuesiti extends Component {
  state = {
    dati: [],
    active: "",
    activeSubCat: "",
    loading: true,
  };

  async componentDidMount() {
    try {
      let quesiti = await axios.post(
        "https://api.utilitalia.it/DMS/Headings/List",
        {
          path: "\\AREA AMBIENTE\\QUESITI",
          statusCode: "PUBBL",
        }
      );

      quesiti = quesiti.data.data.headings;
      let pointQuesiti = await axios.post(
        "https://api.utilitalia.it/DMS/Folders/List",
        {
          path: "\\AREA AMBIENTE\\QUESITI\\.",
          statusCode: "PUBBL",
        }
      );
      pointQuesiti.data.data.folders.forEach((quest) => {
        quesiti = [...quesiti, quest];
      });

      quesiti.sort((a, b) => {
        var valueA = a.code.toUpperCase();
        var valueB = b.code.toUpperCase();
        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      });
      let quesitiFiltered = [];
      quesiti.forEach((quesito) => {
        if (quesito.name !== ".") {
          quesitiFiltered.push(quesito);
        }
      });

      quesitiFiltered = await Promise.all(quesitiFiltered);

      this.setState({ dati: quesitiFiltered });
    } catch (err) {
      console.log("Error CDM AmbienteQuesiti", err);
    }
  }

  render() {
    return (
      <div id="lavoro-dati">
        <IntroSection category="ambiente" subsector="Quesiti" />
        <div className="container pad-mobile">
          <h2 className="general">Quesiti</h2>
          <div className="list">
            <ExpandQuestion questions={this.state.dati} />
          </div>
        </div>
      </div>
    );
  }
}

class ExpandQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: null,
    };
  }

  componentDidMount() {
    const bakState = sessionStorage.getItem("quesiti_bak");

    if (bakState) {
      this.setState({ ...JSON.parse(bakState) });
    }
  }

  componentDidUpdate(oldProps, oldState) {
    if (oldProps.questions.length !== this.props.questions.length) {
      const bakScroll = sessionStorage.getItem("quesiti_scroll");
      if (bakScroll) {
        window.scrollTo({ top: bakScroll, left: 0, behavior: "smooth" });
      }
    }
    if (
      oldState.active !== this.state.active ||
      oldState.activeCategory !== this.state.activeCategory ||
      oldState.activeSubCat !== this.state.activeSubCat
    ) {
      sessionStorage.setItem("quesiti_bak", JSON.stringify(this.state));
      if (window.scrollY !== 0) {
        sessionStorage.setItem("quesiti_scroll", window.scrollY);
      }
    }
  }

  async handleClick(faq, code) {
    if (faq === this.state.active) {
      this.setState({ active: null, activeCategory: null });
    } else {
      let fascicoli = await axios.post(
        "https://api.utilitalia.it/DMS/Folders/List",
        {
          path: "\\AREA AMBIENTE\\QUESITI\\" + code,
          statusCode: "PUBBL",
        }
      );

      fascicoli = fascicoli.data.data.folders;
      if (fascicoli.length === 0) {
        let documenti = await axios.post(
          "https://api.utilitalia.it/DMS/Documents/List",
          {
            documentClasses: ["QUESITO"],
            heading: "\\AREA AMBIENTE\\QUESITI\\.",
            folder: code,

            documentValueOrders: [
              {
                code: "DATA",
                type: "5",
                direction: "asc",
              },
            ],
          }
        );
        documenti = documenti.data.data.documents;
        this.setState({
          fascicoli: [
            {
              name: documenti ? documenti[0].values[1].value : null,
              date: documenti ? documenti[0].values[0].value : null,
              id: getId(documenti[0]),
            },
          ],
        });
      } else if (fascicoli[0] && fascicoli[0].code === ".") {
        let documenti = await axios.post(
          "https://api.utilitalia.it/DMS/Documents/List",
          {
            documentClasses: ["QUESITO"],
            heading: "\\AREA AMBIENTE\\QUESITI\\" + code,
            folder: ".",

            documentValueOrders: [
              {
                code: "DATA",
                type: "5",
                direction: "asc",
              },
            ],
          }
        );
        documenti = documenti.data.data.documents;
        this.setState({
          fascicoli: [
            {
              name: documenti ? documenti[0].values[1].value : null,
              date: documenti ? documenti[0].values[0].value : null,
              id: getId(documenti[0]),
            },
          ],
        });
      } else {
        this.setState({ fascicoli: fascicoli });
      }
      this.setState({ active: faq, activeCategory: code });
    }
  }

  async handleClickSubCat(faq, code, category) {
    let documenti = await axios.post(
      "https://api.utilitalia.it/DMS/Documents/List",
      {
        documentClasses: ["QUESITO"],
        heading: "\\AREA AMBIENTE\\QUESITI\\" + category,
        folder: code,

        documentValueOrders: [
          {
            code: "DATA",
            type: "5",
            direction: "asc",
          },
        ],
      }
    );
    documenti = documenti.data.data.documents;
    this.setState({
      documenti: [
        {
          name: documenti ? documenti[0].values[1].value : null,
          date: documenti ? documenti[0].values[0].value : null,
          id: getId(documenti[0]),
        },
      ],
    });

    if (faq === this.state.activeSubCat) {
      this.setState({ activeSubCat: null });
    } else {
      this.setState({ activeSubCat: faq });
    }
  }

  render() {
    // console.log("STATE", this.state);
    return (
      <div className="list-faqs">
        {this.props.questions.map((element, i) => (
          <div key={i} className="faq">
            <div
              className="faq-heading"
              style={{
                cursor: "pointer",
                marginBottom: i === this.state.active ? 16 : 0,
              }}
              onClick={() => this.handleClick(i, element.code)}
            >
              <div className="faq-heading-left">
                <span style={{ width: 20 }}>{i + 1}</span>
                <h4 style={{ cursor: "pointer" }}>{element.name}</h4>
              </div>
              {this.state.active === i ? (
                <Up
                  style={{ cursor: "pointer" }}
                  className="active"
                  // onClick={() => this.handleClick(i, element.code)}
                />
              ) : (
                <Down
                  style={{ cursor: "pointer" }}
                  // onClick={() => this.handleClick(i, element.code)}
                />
              )}
            </div>
            {this.state.active === i ? (
              this.state.fascicoli &&
              this.state.fascicoli.map((element, i) => (
                <div
                  style={{
                    paddingLeft: 60,
                    paddingBottom: 5,
                    cursor: "pointer",
                  }}
                  key={i}
                >
                  {element.date ? (
                    <div>
                      <div style={{ width: "90%" }}>
                        <Link
                          // to={`/ambiente/quesito/${element.id}`}
                          to={{
                            pathname: `/ambiente/quesito/${element.id}`,
                            state: {
                              backpath: "ambiente",
                            },
                          }}
                        >
                          {moment(element.date).format("DD/MM/YYYY")} -{" "}
                          {element.name}
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div
                        style={{ fontWeight: "bold" }}
                        onClick={() =>
                          this.handleClickSubCat(
                            i,
                            element.code,
                            this.state.activeCategory
                          )
                        }
                      >
                        {" "}
                        - {element.name}
                      </div>
                      {this.state.activeSubCat === i ? (
                        this.state.documenti &&
                        this.state.documenti.map((element, i) => (
                          <div
                            style={{
                              paddingTop: 16,
                              paddingBottom: 16,
                              paddingLeft: 16,
                            }}
                            key={i}
                          >
                            <div style={{ width: "90%" }}>
                              <Link to={`/ambiente/quesito/${element.id}`}>
                                {" "}
                                {moment(element.date).format(
                                  "DD/MM/YYYY"
                                )} - {element.name}{" "}
                              </Link>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p></p>
                      )}
                    </div>
                  )}
                </div>
              ))
            ) : (
              <p></p>
            )}
          </div>
        ))}
      </div>
    );
  }
}
