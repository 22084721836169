import React, { Component } from "react";
import NavigationCircleRight from "components/navigation/circle/NavigationCircleRight";
import { ReactComponent as File } from "icons/file-bold.svg";
import IntroSection from "components/intro-section/IntroSection";
import "./SubCategoryPage.scss";
import { Link } from "react-router-dom";
import axios from "axios";
import { renderContent } from "./renderContent";
const getId = element => {
    return typeof element?.talkingId !== 'undefined' && element?.talkingId.trim() !== '' && element?.talkingId !== null && element?.talkingId !== false  ? element.talkingId : element.id;
}
export default class SubCategoryPage extends Component {
  state = { links: [] };

  componentDidMount() {
    if (
      this.props.match.url ===
        "/ambiente/csr-sostenibilita/misurarsi-per-migliorare/category-home" ||
      this.props.match.url ===
        "/direzione-generale/csr-sostenibilita/misurarsi-per-migliorare/category-home"
    ) {
      axios
        .post("https://api.utilitalia.it/DMS/Documents/List", {
          documentClasses: ["CONTENUTO_WEB"],
          heading: "\\AREA AMBIENTE\\SOSTENIBILITA\\MISURARSI PER MIGLIORARE",
          pageStart: 1,
          pageSize: 10,
          documentValueOrders: [
            {
              code: "POSIZIONE",
              type: "3",
              direction: "asc",
            },
          ],
        })
        .then((response) =>
          this.setState({
            links:
              response &&
              response.data &&
              response.data.data &&
              response.data.data.documents,
          })
        );
    }
  }
  render() {
    return (
      <div id="sub-category-page">
        <IntroSection
          category={renderContent(this.props.match.url).category}
          subsector={renderContent(this.props.match.url).subsector}
          subsubsector={renderContent(this.props.match.url).subsubsector}
        />
        <div className="container">
          <div className="links">
            {renderContent(this.props.match.url).links
              ? renderContent(this.props.match.url).links.map((element, i) => (
                  <Link to={element.to} className="link" key={i}>
                    <File />
                    <div className="link-top">
                      <h2
                        className="general"
                        dangerouslySetInnerHTML={{
                          __html: element.title,
                        }}
                      />
                      <p>{element.text}</p>
                    </div>
                    <NavigationCircleRight />
                  </Link>
                ))
              : this.state.links.map((element, i) => (
                  <Link to={getId(element)} className="link" key={i}>
                    <File />
                    <div className="link-top">
                      <h2
                        className="general"
                        dangerouslySetInnerHTML={{
                          __html: element.values.find(
                            (value) => value.code === "TITOLO"
                          ).value,
                        }}
                      />
                      <p>
                        {
                          element.values.find(
                            (value) => value.code === "ABSTRACT"
                          ).value
                        }
                      </p>
                    </div>
                    <NavigationCircleRight />
                  </Link>
                ))}
          </div>
        </div>
      </div>
    );
  }
}
