export const renderContent = (category) => {
  switch (category) {
    case "/area-giuridico-legislativa-fiscale/appalti-e-concessioni/category-home": {
      return {
        category: "Giuridico Legislativa Fiscale",
        subsector: "Appalti e Concessioni",
        links: [
          {
            title: "Circolari",
            text: "All'interno  tutte le circolari che riguardano la sezione Appalti e Concessioni. ",
            to: "/appalti_e_concessioni/circolari",
          },
          {
            title: "Norme e <span>Regole</span>",
            text: "Una raccolta dei principali documenti di interesse: provvedimenti normativi, pronunce della magistratura, altri atti di istituzioni o Autorità.",
            to: "/area-giuridico-legislativa-fiscale/appalti-e-concessioni/norme-e-regole/list",
          },
        ],
      };
    }
    case "/ambiente/csr-sostenibilita/attivita-gdl/category-home": {
      return {
        category: "Ambiente",
        subsector: "Csr & Sostenibilità",
        subsubsector: "Attività GDL",
        links: [
          {
            title: `Resoconto <span>riunioni</span>`,
            text: "Nella sezione sono raccolti i verbali delle riunioni del gruppo di lavoro. ",
            to: "/ambiente/csr-sostenibilita/attivita-gdl/resoconti-riunioni/list",
          },
          {
            title: `Area <span>documentale</span>`,
            text: " All'interno della sezione sono raccolti i principali documenti di interesse..",
            to: "/ambiente/csr-sostenibilita/attivita-gdl/area-documentale/list",
          },
          {
            title: `Comunicazioni al <span>Gdl</span>`,
            text: "Nella sezione sono raccolte tutte le comunicazioni riguardanti il gruppo di lavoro.",
            to: "/ambiente/csr-sostenibilita/attivita-gdl/comunicazioni-gdl/list",
          },
        ],
      };
    }
    case "/ambiente/csr-sostenibilita/misurarsi-per-migliorare/category-home": {
      return {
        category: "Ambiente",
        subsector: "Csr & Sostenibilità",
        subsubsector: "Misurarsi per migliorare",
      };
    }
    case "/direzione-generale/csr-sostenibilita/attivita-gdl/category-home": {
      return {
        category: "direzione generale",
        subsector: "Csr & Sostenibilità",
        subsubsector: "Attività GDL",
        links: [
          {
            title: `Resoconto <span>riunioni</span>`,
            text: "Nella sezione sono raccolti i verbali delle riunioni del gruppo di lavoro. ",
            to: "/direzione-generale/csr-sostenibilita/attivita-gdl/resoconti-riunioni/list",
          },
          {
            title: `Area <span>documentale</span>`,
            text: " All'interno della sezione sono raccolti i principali documenti di interesse..",
            to: "/direzione-generale/csr-sostenibilita/attivita-gdl/area-documentale/list",
          },
          {
            title: `Comunicazioni al <span>Gdl</span>`,
            text: "Nella sezione sono raccolte tutte le comunicazioni riguardanti il gruppo di lavoro.",
            to: "/direzione-generale/csr-sostenibilita/attivita-gdl/comunicazioni-gdl/list",
          },
        ],
      };
    }
    case "/direzione-generale/csr-sostenibilita/misurarsi-per-migliorare/category-home": {
      return {
        category: "direzione generale",
        subsector: "Csr & Sostenibilità",
        subsubsector: "Misurarsi per migliorare",
      };
    }
    default:
  }
};
