// import RenderIcon from "components/render-icon/RenderIcon";
import moment from "moment";
import { Link } from "react-router-dom";
import "./CardNews.scss";

export default function CardNew({ element }) {
  return (
    <div className="card-news">
      <div className="heading">
        <div style={{ flex: 1 }}></div>

        <div style={{ flex: 1, textAlign: "center" }}>
          <span className="date">{moment(element.date).format("ll")}</span>
        </div>

        <div style={{ flex: 1, textAlign: "end" }}>
          <span className="general">NEWS</span>
        </div>
      </div>

      <div className="module">
        <div className="fade">
          <h4>{element.subject}</h4>
        </div>
      </div>

      <div className="module text">
        <div className="fade">{element.description}</div>
      </div>

      <Link to={"/notizia/" + element.id} className="btn small">
        Leggi di più
      </Link>
    </div>
  );
}
