import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import "./NewsMediaPressKit.scss";
import IntroPressKit from "components/intro-press-kit/IntroPressKit";
import NavigationCircle from "components/navigation/circle/NavigationCircle";
import Brochure from "img/brochure-img.png";
import { ReactComponent as Download } from "icons/file-arrow-down-bold.svg";
import { ReactComponent as Play } from "icons/play-bold.svg";
import { ReactComponent as ArrowRight } from "icons/arrow-right.svg";
import Video from "img/video-press-kit.png";
import { Link } from "react-router-dom";
import AttachmentButton from "components/buttons/attachmentButton";
import { truncate } from "functions/formatTextFunctions";
import Placeholders from "components/placeholders/Placeholders";
const getId = element => {
    return typeof element?.talkingId !== 'undefined' && element?.talkingId.trim() !== '' && element?.talkingId !== null && element?.talkingId !== false  ? element.talkingId : element.id;
}
export default class NewsMediaPressKit extends Component {
  state = {
    mediaKit: [],
    comunicati: [],
    comunicatiFrom: 1,
    video: [],
    videoFrom: 1,
    loadingComunicati: true,
    loadingVideo: true,
    activeYear: new Date().getFullYear(),
  };

  async componentDidMount() {
    // mediakit
    try {
      const response = await axios.post(
        "https://api.utilitalia.it/DMS/Documents/List",
        {
          documentClasses: ["MEDIA_KIT"],
          headingPath: "\\COM",
          pageStart: 1,
          pageSize: 0,
          documentValueOrders: [
            {
              code: "POSIZIONE",
              type: "3",
              direction: "desc",
            },
          ],
        }
      );
      const mediaKit = response?.data?.data?.documents;
      this.setState({
        mediaKit: mediaKit || [],
      });
    } catch (err) {
      console.log("Error fetching Media Kit CDM NewsMediaPressKit", err);
    }

    // comunicati
    try {
      const response = await this.fetchComunicati();
      const comunicati = response?.data?.data?.documents;
      this.setState({
        comunicati: comunicati || [],
      });
    } catch (err) {
      console.log("Error fetching comunicati CDM NewsMediaPressKit", err);
    }
    this.setState({ loadingComunicati: false });

    // video
    try {
      const response = await this.fetchVideo();
      const video = response?.data?.data?.documents;
      this.setState({
        video: video || [],
      });
    } catch (err) {
      console.log("Error fetching video CDM NewsMediaPressKit", err);
    }
    this.setState({ loadingVideo: false });
  }

  async componentDidUpdate(oldProps, oldState) {
    if (this.state.comunicatiFrom !== oldState.comunicatiFrom) {
      try {
        this.setState({ loadingComunicati: true });
        const response = await this.fetchComunicati();
        const comunicati = response?.data?.data?.documents;
        if (comunicati && comunicati.length === 0) {
          this.setState({ comunicatiFrom: 1 });
        }
        this.setState({
          comunicati: comunicati || [],
        });
      } catch (err) {
        console.log("Error fetching comunicati CDU NewsMediaPressKit", err);
      }
      this.setState({ loadingComunicati: false });
    }
    if (this.state.videoFrom !== oldState.videoFrom) {
      try {
        this.setState({ loadingVideo: true });
        const response = await this.fetchVideo();
        const video = response?.data?.data?.documents;
        if (video && video.length === 0) {
          this.setState({ videoFrom: 1 });
        }
        this.setState({
          video: video || [],
        });
      } catch (err) {
        console.log("Error fetching video CDu NewsMediaPressKit", err);
      }
      this.setState({ loadingVideo: false });
    }
  }

  fetchComunicati = async () => {
    const headers = {
      headers: { "Maestrale-Cache-Control": 1800 },
    };
    return await axios.post(
      "https://api.utilitalia.it/DMS/Documents/List",
      {
        documentClasses: ["COMUNICATI_STAMPA"],
        headingPath: "\\COM",
        folder: this.state.activeYear,
        pageStart: this.state.comunicatiFrom,
        pageSize: 3,
        documentValueOrders: [
          {
            code: "DATA",
            type: "5",
            direction: "desc",
          },
        ],
      },
      headers
    );
  };

  fetchVideo = async () => {
    const headers = {
      headers: { "Maestrale-Cache-Control": 1800 },
    };
    return await axios.post(
      "https://api.utilitalia.it/DMS/Documents/List",
      {
        documentClasses: ["VIDEO"],
        headingPath: "\\COM",
        pageStart: this.state.videoFrom,
        pageSize: 3,
        documentValueDefs: [
          {
            code: "TITOLO",
          },
          {
            code: "DESCRIZIONE",
          },
        ],
        documentValueOrders: [
          {
            code: "POSIZIONE",
            type: "3",
            direction: "desc",
          },
        ],
      },
      headers
    );
  };

  handleComunicatiNavigation = (delta) => {
    this.setState({ comunicatiFrom: this.state.comunicatiFrom + delta * 3 });
  };

  handleVideoNavigation = (delta) => {
    this.setState({ videoFrom: this.state.videoFrom + delta * 3 });
  };

  render() {
    return (
      <div id="news-media-press-kit">
        <Helmet>
          <title>Utilitalia - Press Kit</title>
          <meta
            name="description"
            content="All'interno di questa sezione trovate tutto il materiale scaricabile di Utilitalia. Visita il sito "
          />
        </Helmet>
        <IntroPressKit />
        <div className="container pad-mobile">
          <div className="media-kit-section">
            <h2 className="general">
              Media <span>Kit</span>
            </h2>
            <div className="media-kit-list pad-mobile">
              {this.state.mediaKit.map((element, i) => (
                <div className="media-kit" key={i}>
                  <div className="content">
                    <Download />
                    <img src={Brochure} alt="Brochure" />
                    <AttachmentButton
                      text="Scarica"
                      id={element.values[2].value.id}
                    />
                  </div>
                  <h4>
                    {
                      element.values.find((value) => value.code === "TITOLO")
                        .value
                    }
                  </h4>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div className="comunicati-stampa container pad-mobile">
            <div className="heading">
              <h2 className="general">
                Comunicati <span>stampa</span>
              </h2>
              <Link
                to="/news-media/press-kit/comunicati-stampa"
                className="heading-right"
              >
                <span className="general">Vedi tutti</span>
                <ArrowRight />
              </Link>
            </div>
            <div className="comunicati-list">
              {this.state.loadingComunicati ? (
                <Placeholders number={3} type="rassegna" />
              ) : (
                this.state.comunicati.map((element, i) => (
                  <div className="comunicato" key={i}>
                    <div className="date">
                      {moment(
                        element.values.find((value) => value.code === "DATA")
                          .value
                      ).format("ll")}
                    </div>
                    <h3>
                      {truncate(
                        element.values.find((value) => value.code === "TITOLO")
                          .value,
                        80
                      )}
                    </h3>
                    <Link to={"/notizia/" + getId(element)} className="btn small">
                      Leggi di più
                    </Link>
                  </div>
                ))
              )}
            </div>
            <NavigationCircle
              disabled={this.state.comunicatiFrom === 1}
              color="var(--greyColor)"
              function={this.handleComunicatiNavigation}
              margin={0}
            />
          </div>
        </div>
        <div className="video container pad-mobile">
          <div className="heading">
            <h2 className="general">Video</h2>
            <Link to="/news-media/press-kit/video" className="heading-right">
              <span className="general">Vedi tutti</span>
              <ArrowRight />
            </Link>
          </div>
          <div className="video-list">
            {this.state.loadingVideo ? (
              <Placeholders number={3} type="video" />
            ) : (
              this.state.video.map((element, i) => (
                <Link to={"/video/" + getId(element)} className="video" key={i}>
                  <div className="sample">
                    <img src={Video} alt="" />
                    <div className="play">
                      <Play />
                    </div>
                  </div>
                  <h3>
                    {
                      element.values.find((value) => value.code === "TITOLO")
                        .value
                    }
                  </h3>
                </Link>
              ))
            )}
          </div>
          <NavigationCircle
            disabled={this.state.videoFrom === 1}
            color="var(--greyDarkColor)"
            function={this.handleVideoNavigation}
            margin={0}
          />
        </div>
      </div>
    );
  }
}
