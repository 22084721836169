import NavigationCircleRight from "components/navigation/circle/NavigationCircleRight";
import moment from "moment";
import { Link } from "react-router-dom";
import "./CardMondoUtilities.scss";

export default function CardMondoUtilities({ element }) {
  return (
    <div className="card-utility">
      <div
        style={{
          paddingBottom: 8,
          fontSize: "0.8rem",
          textAlign: "right",
        }}
      >
        <span className="date">
          {moment(element.date).format("DD/MM/YYYY")}
        </span>
      </div>

      <div style={{ paddingBottom: 8 }}>
        <div className="module" style={{ height: "2.8rem" }}>
          <h4 className="fade">{element.subject}</h4>
        </div>
      </div>

      <div style={{ paddingBottom: 16 }}>
        <div className="module" style={{ height: "4.2rem" }}>
          <div className="fade">{element.description}</div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <img
          src={"data:image/jpeg;base64, " + element.foto}
          alt=""
          style={{
            maxHeight: 55,
            maxWidth: "50%",
            objectFit: "contain",
          }}
        />

        <Link to={"/notizia/" + element.id}>
          <NavigationCircleRight />
        </Link>
      </div>
    </div>
  );
}
