import axios from "axios";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import "./Circolare.scss";
import NavigationCircleLeft from "components/navigation/circle/NavigationCircleLeft";
import moment from "moment";
import PrivateAttachmentButton from "components/buttons/privateAttachmentButton";
import Placeholders from "components/placeholders/Placeholders";

export default function Circolare(props) {
  const [loading, setLoading] = useState(true);
  const [circolare, setCircolare] = useState({});

  useEffect(() => {
    fetchCircolare();
  }, [props.match.params.id]);

  const fetchCircolare = async () => {
    setLoading(true);
    try {
      const circolareId = props.match.params.id;
      const response = await axios.post(
        "https://api.utilitalia.it/UTI/CIR/Circolari/Circolare",
        {
          circolareId: circolareId,
        }
      );
      setCircolare(
        response.data && response.data.data && response.data.data.circolare
      );
    } catch (err) {
      console.log("Error fetching circolare CDM Circolare", err);
    }
    setLoading(false);
  };

  return (
    <div id="circolare">
      <Helmet>
        <title>
          {`Utilitalia - Circolare |
            ${circolare && circolare.oggetto}`}
        </title>
        <meta name="description" content={circolare && circolare.incipit} />
      </Helmet>
      <div className="container pad-mobile heading">
        <div className="back" onClick={props.history.goBack}>
          <NavigationCircleLeft />
          <span className="general">Torna INDIETRO</span>
        </div>
        <div className="center">SCHEDA CIRCOLARE</div>
        <div className="right">{circolare && circolare.area}</div>
      </div>
      <div className="bg-white">
        <div
          className="container pad-mobile content"
          style={{ maxWidth: "970px" }}
        >
          {loading ? (
            <Placeholders number={1} type="new" />
          ) : (
            <>
              <div>
                {circolare && circolare.numero} -{" "}
                {moment(circolare && circolare.data).format("DD/MM/YYYY")}
              </div>
              <h2>{circolare && circolare.oggetto}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: circolare && circolare.incipit,
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flexWrap: "wrap",
                }}
              >
                {/* <div
                  onClick={() =>
                    fetchPdf(circolare.circolareFilePointer.id)
                  }
                  style={{ marginLeft: 8, cursor: "pointer" }}
                  className="btn"
                >
                  <Download width={16} height={16} style={{ marginRight: 4 }} />
                  {circolare.circolareFilePointer.file.name}
                </div> */}
                <PrivateAttachmentButton
                  text={
                    circolare &&
                    circolare.circolareFilePointer &&
                    circolare.circolareFilePointer.file &&
                    circolare.circolareFilePointer.file.name
                  }
                  id={
                    circolare &&
                    circolare.circolareFilePointer &&
                    circolare.circolareFilePointer.id
                  }
                />
                {circolare &&
                  circolare.allegati &&
                  circolare.allegati.map((allegato) => (
                    // <div
                    //   onClick={() => fetchPdf(allegato.id)}
                    //   className="btn"
                    //   style={{ marginLeft: 8, cursor: "pointer" }}
                    // >
                    //   <Download
                    //     width={16}
                    //     height={16}
                    //     style={{ marginRight: 4 }}
                    //   />
                    //   {allegato.description}
                    // </div>
                    <PrivateAttachmentButton
                      key={`allegato_${allegato.id}`}
                      text={allegato.description}
                      id={allegato.id}
                    />
                  ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
