import "./NavigationCircle.scss";
import { BsArrowRight as ArrowRight } from "react-icons/bs";
import { BsArrowLeft as ArrowLeft } from "react-icons/bs";

export default function NavigationCircle(props) {
  return (
    <div className="navigation-circle" style={{ margin: props.margin }}>
      <ArrowLeft
        onClick={() => props.function(-1)}
        style={{ borderColor: props.color }}
        className={props.disabled && "disabled"}
      />
      <ArrowRight
        onClick={() => props.function(1)}
        style={{ borderColor: props.color }}
        className={props.nextDisabled && "disabled"}
      />
    </div>
  );
}
