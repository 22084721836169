import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import IntroSection from "components/intro-section/IntroSection";
import Filter from "components/filter/Filter";
import NavigationCircle from "components/navigation/circle/NavigationCircle";
import Placeholders from "components/placeholders/Placeholders";
const getId = element => {
    return typeof element?.talkingId !== 'undefined' && element?.talkingId.trim() !== '' && element?.talkingId !== null && element?.talkingId !== false  ? element.talkingId : element.id;
}

const format = (title) => {
  if (title) {
    let arr = [];
    let exludeWords = ["e", "di"];
    arr = title.split("-");
    return arr
      .map((word, i) => {
        return exludeWords.includes(word) && i !== 0
          ? [word]
          : word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  } else {
    return null;
  }
};

const renderAttribute = (array, code) => {
  return (
    array.find((value) => value.code === code) &&
    array.find((value) => value.code === code).value
  );
};

export default class GiurisprudenzaList extends Component {
  state = {
    list: [],
    loading: true,
    active: {
      folder: "",
    },
    lvl1Filter: [],
    lvl2Filter: [],
    lvl3Filter: [],
    from: 1,
  };

  async componentDidMount() {
    this.setState({ loading: true });
    try {
      let list = await axios.post(
        "https://api.utilitalia.it/DMS/Documents/LiSt",
        {
          documentClasses: ["GIURISPRUDENZA_NEW"],
          pageStart: 1,
          pageSize: 10,
          documentValueOrders: [
            {
              code: "DATA",
              type: "5",
              direction: "desc",
            },
          ],
        }
      );
      list = list?.data?.data;

      this.setState({
        list: list?.documents,
        pages: list?.totalPages,
      });
      this.fetchFilter1();
    } catch (err) {
      console.log("Error CDM GiurisprudenzaList", err);
    }
    this.setState({ loading: false });
  }

  async componentDidUpdate(oldProps, oldState) {
    if (
      this.state.active.lvl1Filter !== oldState.active.lvl1Filter ||
      this.state.active.lvl2Filter !== oldState.active.lvl2Filter ||
      this.state.from !== oldState.from
    ) {
      this.setState({ loading: true });
      try {
        const response = await this.fetchList(
          this.state.lvl1Filter.length > 0 ? this.state.active.lvl1Filter : "",
          this.state.lvl2Filter.length > 0 ? this.state.active.lvl2Filter : "",
          this.state.lvl3Filter.length > 0 ? this.state.active.lvl3Filter : ""
        );
        this.setState({
          pages: response?.data?.data?.totalPages,
          list: response?.data?.data?.documents,
        });
        await this.fetchFilter2(this.state.active.lvl1Filter);
        await this.fetchFilter3(
          this.state.active.lvl1Filter,
          this.state.active.lvl2Filter
        );
      } catch (err) {
        console.log("Error CDU GiurispunzaList", err);
      }
      this.setState({ loading: false });
    }
  }

  fetchList = async (headingPath, heading, folder) => {
    return await axios.post("https://api.utilitalia.it/DMS/Documents/List", {
      documentClasses: ["GIURISPRUDENZA_NEW"],
      headingPath: headingPath
        ? "\\AREA LAVORO\\GIURISPRUDENZA_NEW\\" + headingPath
        : "",
      heading: heading
        ? "\\AREA LAVORO\\GIURISPRUDENZA_NEW\\" + headingPath + "\\" + heading
        : "",
      folder: folder,
      pageStart: this.state.from,
      pageSize: 10,
      documentValueOrders: [
        {
          code: "DATA",
          type: "5",
          direction: "desc",
        },
      ],
    });
  };

  fetchFilter1 = async () => {
    let args = await axios.post("https://api.utilitalia.it/DMS/Headings/List", {
      path: "\\AREA LAVORO\\GIURISPRUDENZA_NEW",
      deep: "1",
    });
    args = args.data && args.data.data && args.data.data.headings;
    let values = [];
    args.forEach((element) => {
      values.push(element.code);
    });

    this.setState({ lvl1Filter: values });
  };

  fetchFilter2 = async (headingPath) => {
    this.setState({ lvl2Filter: "" });
    let args = await axios.post("https://api.utilitalia.it/DMS/Headings/List", {
      path: "\\AREA LAVORO\\GIURISPRUDENZA_NEW\\" + headingPath,
      deep: "1",
      onlyContainsDocuments: "true",
    });
    args = args.data && args.data.data && args.data.data.headings;
    let values = [];
    args.forEach((element) => {
      values.push(element.code);
    });

    this.setState({ lvl2Filter: values });
  };

  fetchFilter3 = async (headingPath, folder) => {
    let args = await axios.post("https://api.utilitalia.it/DMS/Folders/List", {
      path: "\\AREA LAVORO\\GIURISPRUDENZA_NEW\\" + headingPath + "\\" + folder,
      deep: "1",
      onlyContainsDocuments: "true",
    });
    args = args.data && args.data.data ? args.data.data.folders : [];
    let values = [];
    args.forEach((element) => {
      values.push(element.code);
    });

    this.setState({ lvl3Filter: values });
  };

  handleChange = (value, target) => {
    this.setState({ active: { ...this.state.active, [target]: value } });
  };

  handleReset = () => {
    this.setState({
      active: { lvl1Filter: "", lvl2Filter: "", lvl3Filter: "" },
    });
  };

  handleNavigation = (delta) => {
    this.setState({ from: this.state.from + delta * 10 });
  };

  render() {
    const filters = [
      {
        name: "lvl1Filter",
        text: "SELEZIONA",
        values: this.state.lvl1Filter,
      },
      {
        name: "lvl2Filter",
        text: "SELEZIONA",
        values: this.state.lvl2Filter,
      },
      {
        name: "lvl3Filter",
        text: "SELEZIONA",
        values: this.state.lvl3Filter,
      },
    ];

    return (
      <div>
        <IntroSection
          category={format(this.props.match.url.split("/")[1])}
          subsector={format(this.props.match.url.split("/")[2])}
          subsubsector={format(this.props.match.url.split("/")[3])}
          subsubsubsector={format(this.props.match.url.split("/")[4])}
        />
        <div className="container pad-mobile">
          <h2 className="general">Giurisprudenza</h2>

          <Filter
            filters={filters}
            change={this.handleChange}
            submit={this.handleSubmit}
            value={this.state.active}
            reset={this.handleReset}
          />

          <div className="general-list">
            {this.state.loading ? (
              <Placeholders number={3} type="new" />
            ) : this.state.list.length === 0 ? (
              <p>Nessun risultato</p>
            ) : (
              this.state.list.map((element, i) => (
                <div className="item" key={i}>
                  <div className="left">
                    <span className="date">
                      Sentenza n° {renderAttribute(element.values, "NUMERO")}{" "}
                      del{" "}
                      {moment(renderAttribute(element.values, "DATA")).format(
                        "DD/MM/YYYY"
                      )}{" "}
                      - {renderAttribute(element.values, "ENTE")}
                    </span>

                    <h4>{renderAttribute(element.values, "OGGETTO")}</h4>
                  </div>
                  <Link
                    to={
                      "/" +
                      this.props.match.url.split("/")[1] +
                      "/" +
                      this.props.match.url.split("/")[2] +
                      "/list/" +
                      getId(element)
                    }
                    className="btn"
                  >
                    Leggi di più
                  </Link>
                </div>
              ))
            )}
          </div>
          {this.state.pages > 1 && (
            <NavigationCircle
              function={this.handleNavigation}
              color="var(--greyDarkColor)"
              disabled={this.state.from === 1}
            />
          )}
        </div>
      </div>
    );
  }
}
