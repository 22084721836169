import { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import "./ChiSiamoCodiceEtico.scss";
import IntroChiSiamo from "components/intro-chi-siamo/IntroChiSiamo";
import Logo from "img/codice-etico.png";
import Placeholders from "components/placeholders/Placeholders";
import { RenderAllegato } from "../../single-document/SingleDocument";
import { renderAttribute } from "functions/renderAttribute";

export default function ChiSiamoCodiceEtico() {
  const [content, setContent] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCodiceEtico();
  }, []);

  const fetchCodiceEtico = async () => {
    try {
      let response = await axios.post(
        "https://api.utilitalia.it/DMS/Documents/List",
        {
          documentClasses: ["CONTENUTO_WEB"],
          heading: "\\CHI_SIAMO",
          folder: "CODICE_ETICO",
          documentValueOrders: [
            {
              code: "TITOLO",
              type: "1",
              direction: "asc",
            },
          ],
        }
      );
      response =
        response?.data?.data?.documents?.length > 0 &&
        response?.data?.data?.documents[0]?.values;
      setContent(response);
    } catch (err) {
      console.log("Error fetching codice etico CDM ChiSiamoCodiceEtico", err);
    }
    setLoading(false);
  };

  return (
    <div className="chi-siamo-codice-etico-page">
      <Helmet>
        <title>Utilitalia - Codice Etico</title>
        <meta
          name="description"
          content="Il presente Codice Etico è una dichiarazione pubblica di Utilitalia, che tiene conto anche delle best practice delle aziende associate"
        />
      </Helmet>

      <IntroChiSiamo section="Codice etico" background="blue" />

      <div className="container pad-mobile">
        <div className="row">
          <div className="left">
            <h2 className="general">
              Codice <span>etico</span>
            </h2>
            {loading ? (
              <Placeholders number={1} type="codice-etico" />
            ) : (
              <p
                dangerouslySetInnerHTML={{
                  __html: renderAttribute(content, "TESTO"),
                }}
              />
            )}
            <div
              style={{
                textTransform: "capitalize",
                float: "left",
              }}
            >
              <RenderAllegato type="ALLEGATO_PRINCIPALE" document={content} />
            </div>
          </div>

          <div className="right">
            <div className="orange"></div>
            <div className="blue"></div>
            <div className="green"></div>
            <div className="box"></div>
            <img src={Logo} alt="Logo" />
          </div>
        </div>
      </div>
    </div>
  );
}
