import "./IntroChiSiamo.scss";

const IntroChiSiamo = ({ section, legal }) => (
  <div className="intro-chi-siamo-component">
    <div className="overlay" style={{ background: "var(--blueColor)" }}>
      <div className="innerText pad-mobile">
        <div className="category">{legal ? "Legal" : "Chi siamo"}</div>

        <div className="dot">·</div>

        <h3>{section}</h3>
      </div>
    </div>
  </div>
);

export default IntroChiSiamo;
