import "./SocialList.scss";
import { FaLinkedinIn as Linkedin } from "react-icons/fa";
import { FaInstagram as Instagram } from "react-icons/fa";
import { FaYoutube as Youtube } from "react-icons/fa";
import Twitter from "../../icons/twitter.svg";

export default function SocialList() {
  return (
    <div className="social-list-component">
      <a
        href="https://www.linkedin.com/company/utilitalia/"
        target="_blank"
        rel="noreferrer"
      >
        <Linkedin />
      </a>

      <a href="https://twitter.com/UTILITALIA" target="_blank" rel="noreferrer">
        <img src={Twitter} Alt="X" />
      </a>

      <a
        href="https://www.instagram.com/utilitalia/"
        target="_blank"
        rel="noreferrer"
      >
        <Instagram />
      </a>

      <a
        //href="https://www.youtube.com/user/FederutilityStampa"
        href="https://www.youtube.com/@YoutubeUtilitalia/featured"
        target="_blank"
        rel="noreferrer"
      >
        <Youtube />
      </a>
    </div>
  );
}
