import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import IntroSection from "components/intro-section/IntroSection";
import "components/expand/Expand.scss";
// import moment from "moment";
import Spinner from "components/spinner/Spinner";
import Placeholders from "components/placeholders/Placeholders";
import { FaAngleDown as Down } from "react-icons/fa";
import { FaAngleUp as Up } from "react-icons/fa";
import moment from "moment";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
const getId = element => {
    return typeof element?.talkingId !== 'undefined' && element?.talkingId.trim() !== '' && element?.talkingId !== null && element?.talkingId !== false  ? element.talkingId : element.id;
}
const clean = (string) => {
  return string.split("\\").join("");
};

const findSons = async (array, element) => {
  let sons = await Promise.all(
    array
      .filter((obj) => clean(obj.path) === clean(element.path + element.code))
      .map(async (obj) => {
        if (obj.code === ".") {
          let quesiti = await axios.post(
            "https://api.utilitalia.it/DMS/Folders/List",
            {
              path: obj.path + "\\" + obj.code,
              statusCode: "PUBBL",
            }
          );
          quesiti = quesiti.data.data?.folders;
          // console.log("quesiti", quesiti);
          return quesiti;
        }

        return obj;
      })
  );
  sons = sons
    .reduce((acc, curr) => {
      const isArray = Array.isArray(curr);
      const obj = isArray ? curr : [curr];
      return [...acc, ...obj];
    }, [])
    .sort((a, b) => {
      return a.name < b.name ? -1 : 1;
    });
  if (sons && sons.length > 0) {
    return {
      ...element,
      sons: await Promise.all(
        sons.map(async (son) => {
          return await findSons(array, son);
        })
      ),
    };
  } else {
    return {
      ...element,
      sons,
    };
  }
};

const setStorage = (active, element) => {
  if (window.scrollY !== 0) {
    sessionStorage.setItem("quesiti_lav_scroll", window.scrollY);
  }

  const bakActiveQuestions =
    JSON.parse(sessionStorage.getItem("quesiti_lav_cat_bak")) || [];
  if (!active) {
    bakActiveQuestions.push(element.code);
    sessionStorage.setItem(
      "quesiti_lav_cat_bak",
      JSON.stringify(bakActiveQuestions)
    );
  } else {
    const newActiveQuestions = bakActiveQuestions.filter(
      (e) => e !== element.code
    );
    sessionStorage.setItem(
      "quesiti_lav_cat_bak",
      JSON.stringify(newActiveQuestions)
    );
  }
};

export default class LavoroQuesiti extends Component {
  state = {
    dati: [],
    active: "",
    activeSubCat: "",
    loading: true,
  };

  async componentDidMount() {
    try {
      window.scrollTo(0, 0);
      let quesiti = await axios.post(
        "https://api.utilitalia.it/DMS/Headings/List",
        {
          path: "\\AREA LAVORO\\QUESITI",
          statusCode: "PUBBL",
          // orderByName: "true",
          // passTroughCode: ".",
        }
      );
      quesiti = quesiti.data.data?.headings;

      quesiti = await Promise.all(
        quesiti
          .filter((fascicolo) => fascicolo.deep === 3)
          .map(async (fascicolo) => {
            return await findSons(quesiti, fascicolo);
          })
      );

      const bakScroll = sessionStorage.getItem("quesiti_lav_scroll");
      // console.log("bakScroll", bakScroll);
      if (bakScroll) {
        setTimeout(() => {
          window.scrollTo({
            top: bakScroll,
            left: 0,
            behavior: "smooth",
          });
        }, 1000);
      }

      // console.log("prima chiamata res", quesiti);
      this.setState({ dati: quesiti });
    } catch (err) {
      console.log("Error CDM LavoroQuesiti", err);
    }
  }

  async componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div id="lavoro-dati">
        <IntroSection category="lavoro" subsector="Quesiti" />
        <div className="container pad-mobile">
          <h2 className="general">Quesiti</h2>

          <div className="list"></div>

          <ExpandQuestion questions={this.state.dati} />
        </div>
      </div>
    );
  }
}

class ExpandQuestion extends Component {
  render() {
    return (
      <div className="list-faqs">
        {this.props.questions.map((element, i) => (
          <ExpandableComponent
            key={`element_${i}_${element.code}`}
            i={i}
            element={element}
            main
            deep={0}
            handleClick={this.handleClick}
          />
        ))}
      </div>
    );
  }
}

const formatName = (string, active) => {
  if (string === "- .") {
    return active ? "Nascondi" : "Espandi";
  }
  return string;
};

function ExpandableComponent(props) {
  const bakActiveQuestions =
    JSON.parse(sessionStorage.getItem("quesiti_lav_cat_bak")) || [];
  const [active, setActive] = useState(
    bakActiveQuestions.find((e) => e === props.element?.code)
  );
  // const [active, setActive] = useState(false);

  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sons, setSons] = useState(props.element.sons || []);
  const { i, element, main } = props;
  const deep = 0 + props.deep;
  const delta = isMobile ? 8 : 16;
  const paddingLeft = (isMobile ? 0 : 64) + deep * delta;

  useEffect(async () => {
    // const bakScroll = sessionStorage.getItem("quesiti_lav_scroll");
    // if (bakScroll) {
    //   window.scrollTo({ top: bakScroll, left: 0, behavior: "smooth" });
    // }
    if (active) {
      if (element.isLeaf) {
        const res = await axios.post(
          "https://api.utilitalia.it/DMS/Folders/List",
          {
            path: element.path + "\\" + element.code,
            onlyContainsDocuments: true,
            statusCode: "PUBBL",
          }
        );
        // console.log("folder quesion", res);

        let folders = res.data.data?.folders;
        let quesiti = folders?.find((folder) => folder?.code === ".");
        // console.log("quesiti", quesiti);

        if (quesiti) {
          await handleClick(quesiti.path, quesiti.code, false, true);
        }

        setSons(
          res.data.data?.folders?.filter((folder) => folder.code !== ".")
        );
      } else {
        let quesiti = await axios.post(
          "https://api.utilitalia.it/DMS/Documents/List",
          {
            documentClasses: ["QUESITO"],
            heading: element.path,
            folder: element.code,

            documentValueOrders: [
              {
                code: "DATA",
                type: "5",
                direction: "asc",
              },
            ],
          }
        );
        quesiti = quesiti.data.data?.documents;

        setQuestions(quesiti);
      }
    }
  }, []);

  const handleClick = async (path, code, isLeaf, doNotUpdatedActive) => {
    setStorage(active, element);
    setActive(!active || doNotUpdatedActive);
    if (isLeaf) {
      const res = await axios.post(
        "https://api.utilitalia.it/DMS/Folders/List",
        {
          path: path + "\\" + code,
          onlyContainsDocuments: true,
          statusCode: "PUBBL",
        }
      );

      let folders = res.data.data?.folders;
      let quesiti = folders?.find((folder) => folder?.code === ".");

      if (quesiti) {
        await handleClick(quesiti.path, quesiti.code, false, true);
      }

      setSons(res.data.data?.folders?.filter((folder) => folder.code !== "."));
    } else {
      setLoading(true);
      let quesiti = await axios.post(
        "https://api.utilitalia.it/DMS/Documents/List",
        {
          documentClasses: ["QUESITO"],
          heading: path,
          folder: code,

          documentValueOrders: [
            {
              code: "DATA",
              type: "5",
              direction: "asc",
            },
          ],
        }
      );
      quesiti = quesiti.data.data?.documents;
      setQuestions(quesiti);
      setLoading(false);
    }
  };

  // console.log("sons", sons);
  // console.log("questions", questions);
  return (
    <div key={i} className={main ? "faq" : null}>
      <div
        className={main ? "faq-heading" : null}
        style={main ? { cursor: "pointer" } : { cursor: "default" }}
        // onClick={
        //   (element.sons && element.sons?.length > 0) || questions?.length > 0
        //     ? () => {
        //         setStorage(active, element);
        //         setActive(!active);
        //       }
        //     : () => {
        //         handleClick(element.path, element.code, element.isLeaf);
        //       }
        // }
      >
        <div className="faq-heading-left">
          {main ? <span style={{ width: 20 }}>{i + 1}</span> : null}
          <h4
            style={
              main
                ? { cursor: "pointer" }
                : {
                    cursor: "pointer",
                    padding: 8,
                    paddingLeft: 12,
                    marginLeft: paddingLeft,
                    borderLeftColor: "#dedede",
                    borderLeftWidth: 2,
                    borderLeftStyle: "outset",
                  }
            }
            onClick={
              element.sons && element.sons.length > 0
                ? () => {
                    setStorage(active, element);
                    setActive(!active);
                  }
                : () => handleClick(element.path, element.code, element.isLeaf)
            }
          >
            {formatName(element.name)}
          </h4>
          {!main &&
            active &&
            !!questions &&
            questions.map((son, secondIndex) => (
              <LastSections
                key={`key_${secondIndex}`}
                paddingLeft={paddingLeft + delta}
                i={secondIndex}
                element={son}
              />
            ))}
        </div>
        {main ? (
          active ? (
            <Up
              className="active"
              // onClick={() => {
              //   setStorage(active, element);
              //   setActive(!active);
              // }}
            />
          ) : (
            // <Down onClick={() => setActive(!active)} />
            <Down
            // onClick={
            //   element.sons && element.sons.length > 0
            //     ? () => {
            //         setStorage(active, element);
            //         setActive(!active);
            //       }
            //     : () => handleClick(element.path, element.code)
            // }
            />
          )
        ) : null}
      </div>
      {loading ? (
        <div
          style={{
            padding: 8,
            paddingLeft: paddingLeft + delta,
          }}
        >
          <Placeholders number={1} type="quesito" />
        </div>
      ) : !!main && active && !!questions && questions?.length > 0 ? (
        questions.map((son, secondIndex) => (
          <LastSections
            key={`key_${secondIndex}`}
            paddingLeft={paddingLeft + delta}
            i={secondIndex}
            element={son}
          />
        ))
      ) : (
        active &&
        sons.length == 0 &&
        questions?.length === 0 &&
        !loading && (
          <h5
            style={{
              padding: 8,
              paddingLeft: paddingLeft + delta,
              display: "block",
              width: "95%",
              fontSize: 16,
            }}
          >
            {" "}
            Per questo argomento non sono presenti quesiti
          </h5>
        )
      )}
      {active &&
        sons.length > 0 &&
        sons.map((son, index) => (
          <ExpandableComponent
            deep={deep + 1}
            key={`son_${index}`}
            i={index}
            element={son}
            handleClick={props.handleClick}
          />
        ))}
    </div>
  );
}

function LastSections(props) {
  const delta = isMobile ? 8 : 16;

  const { element } = props;
  // console.log("last section element", element);
  return (
    <Link
      onClick={() =>
        sessionStorage.setItem("quesiti_lav_scroll", window.scrollY)
      }
      // key={`${index}`}
      // to={`quesito/${son.id}`}
      to={{
        pathname: `quesito/${getId(element)}`,
        state: { backpath: "area-lavoro-relazioni-industriali" },
      }}
      style={{
        padding: 8,
        paddingLeft: props.paddingLeft + delta,
        display: "block",
        width: "95%",
      }}
    >
      {element.values[0].value
        ? moment(element.values[0].value).format("DD/MM/YY") +
          " - " +
          element.values[1].value
        : element.values[1].value}
    </Link>
    // <div>
    //   <div>
    //     <div>
    //       <h4
    //         style={{
    //           cursor: "pointer",
    //           fontWeight: "normal",
    //           paddingLeft: props.paddingLeft,
    //           paddingTop: 8,
    //           paddingBottom: 8,
    //         }}
    //         onClick={() => handleClick(element.path, element.code)}
    //       >
    //         {formatName("- " + element.name, active)}
    //       </h4>
    //     </div>
    //   </div>
    //   {questions && questions.length > 0
    //     ? active &&
    //       questions.map((son, index) => (
    //         <Link
    //           key={`index_${index}`}
    //           // to={`quesito/${son.id}`}
    //           to={{
    //             pathname: `quesito/${son.id}`,
    //             state: { backpath: "area-lavoro-relazioni-industriali" },
    //           }}
    //           style={{
    //             padding: 8,
    //             paddingLeft: props.paddingLeft + delta,
    //             display: "block",
    //             width: "95%",
    //           }}
    //         >
    //           {son.values[0].value
    //             ? moment(son.values[0].value).format("DD/MM/YY") +
    //               " - " +
    //               son.values[1].value
    //             : son.values[1].value}
    //         </Link>
    //       ))
    //     : active && (
    //         <h5
    //           style={{
    //             padding: 8,
    //             paddingLeft: props.paddingLeft + delta,
    //             display: "block",
    //             width: "95%",
    //             fontSize: 16,
    //           }}
    //         >
    //           {" "}
    //           Per questo argomento non sono presenti quesiti
    //         </h5>
    //       )}
    // </div>
  );
}
