import React, { Component } from "react";
import IntroSection from "components/intro-section/IntroSection";
import axios from "axios";
import AttachmentButton from "components/buttons/attachmentButton";
import "./SimplePage.scss";
import { renderContent } from "./renderContent";

export default class SimplePage extends Component {
  state = { documents: [], misurarsi: [] };

  componentDidMount() {
    if (this.props.match.url === "/amministrazione/come-aderire/page") {
      axios
        .post("https://api.utilitalia.it/DMS/Documents/List", {
          documentClasses: ["CONTENUTO_WEB"],
          heading: "\\AREA AMMINISTRAZIONE",
          folder: renderContent(this.props.match.url).folder,
          documentValueOrders: [
            {
              code: "TITOLO",
              type: "1",
              direction: "asc",
            },
          ],
        })
        .then((response) => {
          const text = response?.data?.data?.documents[0]?.values?.find(
            (value) => value.code === "TESTO"
          )?.value;
          if (text) {
            this.setState({
              text,
            });
          }
        });
    } else if (
      this.props.match.url.includes(
        "csr-sostenibilita/misurarsi-per-migliorare"
      )
    ) {
      const id = this.props.match.params.id;
      axios
        .post("https://api.utilitalia.it/DMS/Documents/Document", {
          documentId: id,
        })
        .then((response) => {
          this.setState({
            misurarsi:
              response.data &&
              response.data.data &&
              response.data.data.document.values,
          });
        });
    } else if (this.props.match.url === "/amministrazione/modulistica/page") {
      axios
        .post("https://api.utilitalia.it/DMS/Documents/List", {
          documentClasses: ["CONTENUTO_WEB"],
          heading: "\\AREA AMMINISTRAZIONE",
          folder: "MODULI",
          pageSize: "0",
          pageStart: "1",
          documentValueOrders: [
            {
              code: "POSIZIONE",
              type: "3",
              direction: "asc",
            },
          ],
        })
        .then((response) =>
          this.setState({
            documents:
              response.data &&
              response.data.data &&
              response.data.data.documents,
          })
        );
    }
  }
  render() {
    return (
      <div id="simple-page">
        <IntroSection
          category={this.props.match.url.split("/")[1]}
          subsector={renderContent(this.props.match.url).subsector}
          subsubsector={renderContent(this.props.match.url).subsubsector}
        />
        <div className="container pad-mobile">
          <h2
            className="general"
            dangerouslySetInnerHTML={{
              __html: renderContent(this.props.match.url).title,
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: this.state.text,
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: renderContent(this.props.match.url).text,
            }}
          />
          {this.state.documents.map((element, i) => (
            <a
              href={
                element?.values?.find((value) => value.code === "LINK")?.value
                  ?.url
              }
              style={{ display: "inline-flex", marginBottom: 8 }}
              // className="btn"
            >
              {element?.values?.find((value) => value.code === "TITOLO")?.value}
            </a>
          ))}
          <p
            dangerouslySetInnerHTML={{
              __html:
                this.state.misurarsi?.find((value) => value.code === "TESTO") &&
                this.state.misurarsi?.find((value) => value.code === "TESTO")
                  ?.value,
            }}
          />
          {this.state.misurarsi.find((value) => value.code === "TESTO") && (
            <AttachmentButton
              end
              id={
                this.state.misurarsi?.find((value) => value.code === "ALLEGATO")
                  ?.value?.id
              }
              text={
                this.state.misurarsi?.find((value) => value.code === "ALLEGATO")
                  ?.value?.file?.name
              }
            />
          )}
        </div>
      </div>
    );
  }
}
