import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import CircularsList from "components/circulars-list/CircularsList";
import Filter from "components/filter/Filter";
import Spinner from "components/spinner/Spinner";
import IntroSection from "components/intro-section/IntroSection";
import { switchCircolari } from "./functions";
import Pagination from "components/pagination/Pagination";

export default class CircolariSection extends Component {
  state = {
    circolari: [],
    loading: true,
    active: {
      year: null,
    },
    years: [],
    totalPages: 1,
  };

  async componentDidMount() {
    try {
      const bakFilter = sessionStorage.getItem("circolari_section_bak");
      const sector = switchCircolari();
      const resYearsFilter = await this.fetchAnni(sector);
      const yearsFilter = resYearsFilter?.data?.data;
      this.setState({
        active: {
          year: bakFilter
            ? JSON.parse(bakFilter).year
            : yearsFilter?.values[0],
        },
        years: yearsFilter?.values,
      });
    } catch (err) {
      console.log("Err CDM CircolariSezioni", err);
    }
  }

  async componentDidUpdate(oldProps, oldState) {
    if (
      this.state.active.year !== oldState.active.year ||
      this.state.active.area !== oldState.active.area
    ) {
      this.setState({ loading: true });
      sessionStorage.setItem(
        "circolari_section_bak",
        JSON.stringify(this.state.active)
      );
      try {
        const resCircolari = await this.fetchCircolari(switchCircolari(), 0);
        const circolari = resCircolari?.data?.data;
        this.setState({
          circolari: circolari?.circolari,
          totalPages: circolari?.totalPages,
        });
      } catch (err) {
        console.log("Err CDU CircolariSezioni", err);
      }
      this.setState({ loading: false });
    }
  }

  fetchAnni = async (sector) => {
    return await axios.post(
      "https://api.utilitalia.it/UTI/CIR/Circolari/DateLookup",
      {
        aree: sector.aree,
        areeSecondarie: sector.areeSecondarie,
        servizi: sector.servizi,
        serviziSecondari: sector.serviziSecondari,
        orderDirection: "desc",
      }
    );
  };

  fetchCircolari = async (sector, page) => {
    return await axios.post(
      "https://api.utilitalia.it/UTI/CIR/Circolari/List",
      {
        aree: sector.aree,
        areeSecondarie: sector.areeSecondarie,
        servizi: sector.servizi,
        serviziSecondari: sector.serviziSecondari,
        startDate: this.state.active.year
          ? this.state.active.year + "-01-01"
          : moment().format("YYYY") + "-01-01",
        endDate: this.state.active.year
          ? moment(this.state.active.year).add(1, "y").format("YYYY-MM-DD")
          : moment().add(1, "y").format("YYYY-MM-DD"),
        pageSize: "20",
        pageStart: (page || 0) + 1,
        orderDirection: "desc",
      }
    );
  };

  handleChange = (value, target) => {
    this.setState({ active: { ...this.state.active, [target]: value } });
  };

  handleSubmit = (event) => {
    event.preventDefault();
  };

  handleReset = () => {
    this.setState({ active: { year: moment().format("YYYY") } });
  };
  handlePageClick = async (data) => {
    this.setState({ loading: true });
    try {
      window.scrollTo(0, 0);
      let { selected } = data;
      const response = await this.fetchCircolari(
        switchCircolari(),
        (selected || 0) * 20
      );
      this.setState({
        circolari: response?.data?.data?.circolari,
      });
    } catch (err) {
      console.log("Error fetchCircolari handlePageClick CircolariSezioni", err);
    }
    this.setState({ loading: false });
  };

  render() {
    const filters = [
      {
        name: "year",
        text: "Seleziona l'anno",
        values: this.state.years,
      },
    ];
    return (
      <div id="circolari">
        <IntroSection category={switchCircolari().name} subsector="Circolari" />
        <div className="container pad-mobile">
          <h2 className="general">Circolari</h2>
          <Filter
            filters={filters}
            change={this.handleChange}
            submit={this.handleSubmit}
            value={this.state.active}
            reset={this.handleReset}
            resetButton={this.state.active.year !== moment().format("YYYY")}
          />
          {this.state.loading ? (
            <Spinner />
          ) : (
            <CircularsList circulars={this.state.circolari} col={4} />
          )}
          {this.state.totalPages > 1 && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                paddingTop: 32,
              }}
            >
              <Pagination
                totalPages={this.state.totalPages}
                handlePageClick={this.handlePageClick}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
