import { useEffect, useState } from "react";
import "./Presentazione.scss";
import IntroChiSiamo from "components/intro-chi-siamo/IntroChiSiamo";
import ReactPlayer from "react-player";

export default function ChiSiamoPresentazione() {
  const [videoUrl, setVideoUrl] = useState("");

  useEffect(() => {
    setVideoUrl("https://www.youtube.com/watch?v=suQDQIWMAOs");
  }, []);

  return (
    <div className="chi-siamo-brochure-page">
      <IntroChiSiamo section="Presentazione" background="blue" />

      <div className="container pad-mobile">
        <h2 className="general">Video Istituzionale</h2>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {videoUrl ? (
            <div className="responsive-video video-container">
              <ReactPlayer
                url={videoUrl}
                controls
                width="100%"
                height="100%"
                /*    style={{
                  border: "5px solid red",
                }} */
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
