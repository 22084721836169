import React, { Component } from "react";
import axios from "axios";

export default class RenderIcon extends Component {
  state = {
    image: "",
  };

  async componentDidMount() {
    if (!this.props.fetchDisabled) {
      try {
        if (!!sessionStorage.getItem(`${this.props.fonte}img`)) {
          this.setState({
            image: sessionStorage.getItem(`${this.props.fonte}img`),
          });
        } else {
          const response = await axios.post(
            "https://api.utilitalia.it/CMS/Files/File",
            {
              fileName: `/immagini/news/${this.renderUrl(
                this.props.fonte
              )}.png`,
            }
          );
          const image = response?.data?.data?.data;
          sessionStorage.setItem(`${this.props.fonte}img`, image);
          this.setState({
            image,
          });
        }
      } catch (err) {
        console.log("Error CDM RenderIcon", err);
      }
    }
  }

  renderUrl = (fonte) => {
    switch (fonte) {
      case "Adnkronos":
        return "news-adnkronos";
      case "Italpress":
        return "news-italpress";
      case "Quotidiano Energia":
        return "news-quotidiano";
      case "RADIOCOR":
        return "news-radiocor";
      case "Quotidiano Enti Locali e Pa":
        return "news-quelpa";
      case "PUBLIC POLICY":
        return "news-publicpolicy";
      case "Utilitalia":
        return "news-utilitalia";
      case "Mondo Utilities":
        return "news-mondoutilities";
      default:
    }
  };

  renderName = (fonte) => {
    switch (fonte) {
      case "Quotidiano Enti Locali e Pa":
        return "QUELPA";
      default:
        return fonte;
    }
  };

  render() {
    return (
      <>
        {this.props.fetchDisabled ? (
          <span className="general">{this.renderName(this.props.fonte)}</span>
        ) : (
          <img
            src={"data:image/jpeg;base64," + this.state.image}
            alt=""
            style={{
              objectFit: this.props.fonte === "Italpress" ? "contain" : "cover",
            }}
          />
        )}
      </>
    );
  }
}
