import React, { Component } from "react";
import { Helmet } from "react-helmet-async";
import "./ChiSiamoUtilitalia.scss";

export default class ChiSiamoUtilitalia extends Component {
  render() {
    return (
      <div id="chi-siamo-utilitalia">
        <Helmet>
          <title>Utilitalia - Chi Siamo</title>
          <meta
            name="description"
            content="Utilitalia è la Federazione che riunisce le Aziende operanti nei servizi pubblici dell'Acqua, dell'Ambiente, dell'Energia Elettrica e del Gas, rappresentandole presso le Istituzioni nazionali ed europee."
          />
        </Helmet>
        <div className="hero">
          <div className="content">
            {/* <h6>Chi siamo</h6> */}
            <h1>Chi siamo</h1>
            <p>
              Utilitalia è la Federazione che riunisce le Aziende operanti nei
              servizi pubblici dell'Acqua, dell'Ambiente, dell'Energia Elettrica
              e del Gas, rappresentandole presso le Istituzioni nazionali ed
              europee. Nasce dalla fusione di Federutility (servizi energetici e
              idrici) e di Federambiente (servizi ambientali).
            </p>
          </div>
        </div>
        <div className="container pad-mobile">
          <h6>Cosa Fa Utilitalia</h6>
          <h2>
            Offre servizi di assistenza, di aggiornamento e di formazione, oltre
            ad attività di consulenza su aspetti contrattuali, normativi,
            gestionali, tributari e legali
          </h2>
          <div className="list">
            <p>Al supporto tecnico su settori specifici, si aggiungono:</p>
            <ul>
              <li>
                assistenza nell'applicazione dei Contratti di Lavoro e delle
                norme giuslavoristiche;
              </li>
              <li>
                aggiornamento e supporto interpretativo su leggi e provvedimenti
                che regolano i servizi pubblici;
              </li>
              <li>
                consulenza e assistenza su problematiche fiscali, tributarie,
                giuridiche e previdenziali, attraverso un 'attività di risposta
                ai Quesiti delle Associate;
              </li>
              <li>
                interventi in giudizio nelle questioni di interesse generale;
              </li>
              <li>
                monitoraggio e analisi di tariffe, dati economici e tecnici a
                livello nazionale;
              </li>
              <li>
                supporto per la redazione di piani finanziari a copertura degli
                investimenti;
              </li>
              <li>
                valorizzazione e condivisione dei modelli di Responsabilitò
                sociale d'impresa;
              </li>
              <li>
                supporto agli Enti Locali nella pianificazione dei servizi
                pubblici e delle relative gare (in particolare, i bandi per il
                servizio distribuzione Gas)
              </li>
              <li>
                assistenza nell'evoluzione industriale e gestionale attraverso
                la condivisione di best practices nelle commissioni e gruppi di
                lavoro associativi
              </li>
              <li>
                promozione di attività convegnistiche, seminariali e formative,
                anche in collaborazione con Università, Fondazioni e Centri di
                ricerca
              </li>
              <li>
                presentazione ed elaborazione di studi e ricerche sui temi di
                interesse (economico-giuridico);
              </li>
              <li>
                interlocuzione con le associazioni dei Consumatori e
                Ambientaliste;
              </li>
              <li>informazione periodica su tematiche specifiche;</li>
              <li>
                affiancamento nelle attività di comunicazione e immagine, nella
                diffusione di notizie e nella promozione di campagne nazionali
                di sensibilizzazione.
              </li>
            </ul>
          </div>
          <div className="row">
            <div className="left">
              {/* <img src={Img} alt="test" /> */}
              <div className="orange"></div>
              <div className="blue"></div>
              <div className="green"></div>
              <div className="box"></div>
            </div>
            <div className="right">
              <h2>
                CCNL
                <span style={{ fontWeight: "bold", display: "block" }}>
                  {" "}
                  e previdenza
                </span>
              </h2>
              <p>
                L'associazione è firmataria del CCNL Gas Acqua (41.000 i
                lavoratori dipendenti degli Associati), del CCNL Ambiente
                (47.000 lavoratori), del CCNL Elettrico (13.900).
              </p>
              <p>
                Utilitalia - in virtù della fusione tra Federutility e
                Federambiente - è subentrata nella titolarità dei due Fondi di
                Previdenza Complementare, istituti dalle preesistenti
                Federazioni:
              </p>
              <ul>
                <li>
                  il Fondo di Previdenza Complementare Pegaso - costituito
                  insieme ad Anfida-Confindustria, dal 2000 raccoglie le
                  adesioni di circa 31.000 lavoratori;
                </li>
                <li>
                  il Fondo di Previdenza Complementare PreviAmbiente -
                  costituito insieme a Fise Assoambiente - raccoglie le adesioni
                  di oltre 30.000 iscritti a cui viene applicato il CCNL
                  Ambiente.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="numbers">
          <div className="container pad-mobile">
            <h6>I numeri</h6>
            <h2>
              Oltre <b>450</b> soggetti associati
              {/* Numeri Utilitalia headline <br />
              <span>Utilitalia</span> */}
            </h2>
            <p>
              Sono oltre 450 i soggetti associati - tra Società di capitali,
              Consorzi, Comuni, Aziende speciali ed altri enti -, con circa
              100.000 addetti complessivi, un valore della produzione pari a
              circa 40 miliardi di euro e utili per 1,3 miliardi di Euro.
            </p>
            <p>In particolare, gli Associati ad Utilitalia:</p>
            <div className="percentages">
              <div className="percentage">
                <span>servizi idrici a circa l'</span>
                <br />
                <span>80%</span>
                <br />
                <span>della popolazione</span>
              </div>
              <div className="percentage">
                <span>servizi ambientali al</span>
                <br />
                <span>55%</span>
                <br />
                <span>della popolazione</span>
              </div>
              <div className="percentage">
                <span>servizi gas ad oltre il</span>
                <br />
                <span>30%</span>
                <br />
                <span>della popolazione</span>
              </div>
              <div className="percentage">
                <span>servizi energia elettrica al</span>
                <br />
                <span>15%</span>
                <br />
                <span>della popolazione</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
