import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import "./AnswersPage.scss";
import Spinner from "components/spinner/Spinner";
import IntroSection from "components/intro-section/IntroSection";
import { filterAttribute, renderAttribute } from "functions/renderAttribute";
import NavigationCircleLeft from "components/navigation/circle/NavigationCircleLeft";
import { Link } from "react-router-dom";
import { Download } from "phosphor-react";
import { AttachmentDifferentFormatButton } from "components/buttons/attachmentButton";

const format = (title) => {
  let arr = [];
  let exludeWords = ["e", "di"];
  arr = title.split("-");
  return arr
    .map((word, i) => {
      return exludeWords.includes(word) && i !== 0
        ? [word]
        : word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};

export default class SingleDocument extends Component {
  state = {
    loading: true,
  };

  async componentDidMount() {
    try {
      const id = this.props.match.params.id;

      const sessionId = sessionStorage.getItem("sessionId");
      const urlSession = window.location.search.substring(1);

      const headers = {
        headers: { "Session-Id": urlSession ? urlSession : sessionId },
      };

      let response = await axios.post(
        "https://api.utilitalia.it/DMS/Documents/Document",
        {
          documentId: id,
          documentValueDefs: [
            {
              code: "DATA",
            },
            {
              code: "QUESITO",
            },
            {
              code: "PARERE_HTML",
            },
            {
              code: "LINK",
            },
            {
              code: "ALLDOC",
            },
          ],
        },
        headers
      );

      const document = response?.data?.data?.document?.values;
      // console.log("document", document);
      if (!!document) {
        this.setState({ document });
      } else {
        if (
          navigator.userAgent !== "ReactSnap" ||
          (!urlSession && !sessionId)
        ) {
          // window.location.href = "/login";
          this.props.history.push({
            pathname: "/login",
            state: {},
          });
        }
      }
    } catch (err) {
      console.log("Error CDM AnswerPage", err);
    }
    this.setState({ loading: false });
  }

  render() {
    return (
      <div id="answers-page">
        <div className="no-print">
          <IntroSection
            category={format(this.props.match.url.split("/")[1])}
            subsector={format(this.props.match.url.split("/")[2])}
          />
        </div>

        <div className="container pad-mobile">
          <div
            className="no-print"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 40,
              width: "100%",
            }}
          >
            <Link
              to={`/${this.props.match.url.split("/")[1]}/quesiti`}
              style={{
                display: "flex",
                alignItems: "center",
                gap: 8,
              }}
            >
              <NavigationCircleLeft />
              <span className="general">Tutti i quesiti</span>
            </Link>
            <a className="btn" href="javascript:if(window.print)window.print()">
              Stampa
            </a>
          </div>

          {this.state.loading ? (
            <Spinner />
          ) : (
            <div className="print-padding">
              {renderAttribute(this.state.document, "DATA") && (
                <span className="date">
                  {moment(renderAttribute(this.state.document, "DATA")).format(
                    "L"
                  )}
                </span>
              )}

              <h2 style={{ marginBottom: "1rem" }}>
                {renderAttribute(this.state.document, "QUESITO")}
              </h2>

              {renderAttribute(this.state.document, "PARERE_HTML") && (
                <div className="styledText">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: renderAttribute(
                        this.state.document,
                        "PARERE_HTML"
                      ),
                    }}
                  />
                </div>
              )}
              {renderAttribute(this.state.document, " TESTO") && (
                <div className="styledText">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: renderAttribute(this.state.document, " TESTO"),
                    }}
                  />
                </div>
              )}
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: 24,
            }}
          >
            {this.state.document &&
              filterAttribute(this.state.document, "ALLDOC") &&
              filterAttribute(this.state.document, "ALLDOC").map((link) => (
                <AttachmentDifferentFormatButton
                  text={link.value?.file?.name}
                  id={link.value?.id}
                />
              ))}
          </div>
        </div>
      </div>
    );
  }
}
