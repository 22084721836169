import "./Filter.scss";
import { ReactComponent as Reset } from "icons/arrow-counter-clockwise-bold.svg";

export default function Filter(props) {
  const numFilters = props.filters.reduce((acc, curr) => {
    return acc + curr.values?.length;
  }, 0);
  return (
    !!numFilters && (
      <div id="filters">
        <form onSubmit={props.submit}>
          <div className="type">
            {props.filters.map((element, i) => {
              return (
                element?.values?.length > 0 && (
                  <select
                    key={i}
                    id={element.name}
                    name={element.name}
                    onChange={(ev) =>
                      props.change(ev.target.value, element.name)
                    }
                    value={
                      props.value
                        ? props.value[element.name] || props.storagedActiveYear
                        : ""
                    }
                  >
                    <option value="">
                      {element.placeholder || "SELEZIONA"}
                    </option>
                    {element.values.map((value, i) => (
                      <option value={value} key={i}>
                        {value}
                      </option>
                    ))}
                  </select>
                )
              );
            })}
          </div>
          {Object.values(props.value || {}).some((v) => !!v) && (
            <span onClick={props.reset} className="general reset show">
              <Reset />
              Reset filtri
            </span>
          )}
        </form>
      </div>
    )
  );
}
