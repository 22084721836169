import React, { Component } from "react";
import axios from "axios";
import "./Video.scss";
import NavigationCircleLeft from "components/navigation/circle/NavigationCircleLeft";
import Spinner from "components/spinner/Spinner";
import { Play } from "phosphor-react";
import Placeholders from "components/placeholders/Placeholders";

export default class Video extends Component {
  state = {
    loading: true,
  };

  async componentDidMount() {
    try {
      if (navigator.userAgent !== "ReactSnap") {
        let video = await axios.post(
          "https://api.utilitalia.it/DMS/Documents/Document",
          {
            documentId: this.props.match.params.id,
          }
        );
        video = video?.data?.data?.document?.values;

        if (video && video.find((value) => value.code === "ALLEGATO")) {
          const response = await axios.post(
            "https://api.utilitalia.it/DMS/Files/File",
            {
              fileId: video?.find((value) => value.code === "ALLEGATO")?.value
                ?.id,
            }
          );
          const file = response?.data?.data;
          this.setState({
            play: file?.data,
            videoName: file?.file.name,
            show: true,
          });
        }
        this.setState({
          video: video,
        });
      }
    } catch (err) {
      console.log("Error CDM Video", err);
    }
    this.setState({ loading: false });
  }

  click = () => {
    this.setState({ click: true });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  getFileType(pdfName) {
    const type = pdfName.split(".");
    switch (type[type.length - 1]) {
      case "pdf":
        return "application/pdf";
      case "docx":
        return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      case "xls":
        return "application/vnd.ms-excel";
      case "xlsx":
        return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      case "pptx":
        return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
      case "mp4":
        return "video/mp4";

      default:
        return "application/pdf";
    }
  }

  render() {
    let blobUrl;
    if (this.state.play && !this.state.loading) {
      const blob = this.b64toBlob(
        this.state.play,
        this.getFileType(this.state.videoName)
      );
      blobUrl = URL.createObjectURL(blob);
    }
    return (
      <div id="video">
        <div className="container pad-mobile heading">
          <div className="back" onClick={this.goBack}>
            <NavigationCircleLeft />
            <span className="general">Torna indietro</span>
          </div>
        </div>
        <div
          className="bg-white"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {this.state.loading ? (
            <Placeholders number={1} type="video-pagina" />
          ) : (
            <div
              className="container pad-mobile content"
              style={{ width: "970px", paddingTop: "2rem" }}
            >
              <h1>
                {
                  this.state.video?.find((value) => value?.code === "TITOLO")
                    ?.value
                }
              </h1>
              <p
                dangerouslySetInnerHTML={{
                  __html: this.state.video?.find(
                    (value) => value?.code === "DESCRIZIONE"
                  )?.value,
                }}
              />

              {this.state.video?.find(
                (value) => value?.code === "LINK_YOUTUBE"
              ) && (
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    width: "100%",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: this.state.video?.find(
                      (value) => value.code === "LINK_YOUTUBE"
                    )?.value,
                  }}
                />
              )}
              {this.state.video &&
                !this.state.video?.find(
                  (value) => value.code === "LINK_YOUTUBE"
                ) && (
                  <div className="video" onClick={this.click}>
                    {!this.state.click ? (
                      <div>
                        <Play size={48} color="#fff" />
                      </div>
                    ) : !this.state.show ? (
                      <Spinner />
                    ) : (
                      this.state.play && (
                        // <embed type="video/mp4" src={"data:video/mp4;base64," + this.state.play} />
                        <embed type="video/mp4" src={blobUrl} />
                      )
                    )}
                  </div>
                )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
