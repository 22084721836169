import "./CircularsList.scss";
import { ReactComponent as Water } from "icons/drop-bold.svg";
import { ReactComponent as Leaf } from "icons/leaf-bold.svg";
import { ReactComponent as Thunder } from "icons/lightning-bold.svg";
import { ReactComponent as Settings } from "icons/faders-bold.svg";
import { ReactComponent as Bag } from "icons/tote-bold.svg";
import { ReactComponent as Steam } from "icons/shield-chevron-bold.svg";
import { ReactComponent as Chat } from "icons/chats-circle-bold.svg";
import { ReactComponent as Book } from "icons/book-bold.svg";
import { RiPushpin2Line as Pin } from "react-icons/ri";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Placeholders from "components/placeholders/Placeholders";
import { truncate } from "functions/formatTextFunctions";

function renderIcon(element) {
  switch (element) {
    case "Acqua":
      return (
        <Water
          className="category"
          style={{
            background: "var(--acquaGradient)",
          }}
        />
      );
    case "Ambiente":
      return (
        <Leaf
          className="category"
          style={{
            background: "var(--ambienteGradient)",
          }}
        />
      );
    case "Area Affari Regolatori":
      return (
        <Settings
          className="category"
          style={{
            background: "var(--affari)",
          }}
        />
      );
    case "Area Comunicazione":
      return (
        <Chat
          className="category"
          style={{
            background: "var(--comunicazione)",
          }}
        />
      );
    case "Direzione Generale":
      return (
        <Book
          className="category"
          style={{
            background: "var(--amministrazione)",
          }}
        />
      );
    case "Energia":
      return (
        <Thunder
          className="category"
          style={{
            background:
              "linear-gradient(135deg, #FF8801 0%, #FFBD0D 50%, #EB650C 100%)",
          }}
        />
      );
    case "Giuridico-Legislativo":
      return (
        <Steam
          className="category"
          style={{
            background: "var(--giuridico)",
          }}
        />
      );
    case "Lavoro":
      return (
        <Bag
          className="category"
          style={{
            background: "var(--lavoro)",
          }}
        />
      );
    case "Relazioni Esterne":
      return (
        <Thunder
          className="category"
          style={{
            background:
              "linear-gradient(135deg, #FF8801 0%, #FFBD0D 50%, #EB650C 100%)",
          }}
        />
      );
    default:
  }
}

export default function CircularsList(props) {
  return (
    <div
      className="circulars-list-component"
      style={{
        gridTemplateColumns: `repeat(${props.col}, minmax(0, 1fr))`,

        // height: isMobile && 400,
      }}
    >
      {props.loading ? (
        <Placeholders type="associata" number={props.col === "2" ? 2 : 9} />
      ) : (
        props.circulars &&
        props.circulars.map((element, i) => (
          <div key={i} className="circular">
            <Pin className="pin" />
            {element.data && (
              <div
                style={{
                  position: "absolute",
                  top: 16,
                  left: isMobile ? 16 : 16,
                  color: "#888",
                }}
              >
                {element.numero && (
                  <>
                    <b>{element.numero}</b>
                    {" - "}
                  </>
                )}
                {new Date(element.data).toLocaleDateString("it-IT")}
              </div>
            )}
            {renderIcon(element.area)}
            <div className="content">
              <h5 style={{ marginBottom: 8 }}>
                <div style={{ height: isMobile ? "5.6rem" : "4.2rem" }}>
                  <div className="fade">
                    {truncate(element.oggetto, isMobile ? 80 : 150)}
                    {/* {element.oggetto} */}
                  </div>
                </div>
              </h5>
              <div className="incipit" style={{ marginBottom: 48 }}>
                <div style={{ height: isMobile ? "5.6rem" : "8.4rem" }}>
                  <div className="fade">
                    {truncate(element.incipit, isMobile ? 50 : 120)}
                    {/* {element.incipit} */}
                  </div>
                </div>
              </div>
              <Link to={"/circolare/" + element.id} className="btn small">
                Leggi di più
              </Link>
            </div>
          </div>
        ))
      )}
    </div>
  );
}
