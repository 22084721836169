import React, { Component, useState } from "react";
import axios from "axios";
import Spinner from "components/spinner/Spinner";
import CardCircular from "./card-circular/CardCircular";
import CardEvent from "./card-event/CardEvent";
import CardGeneral from "./card-general/CardGeneral";
import CardMondoUtilities from "./card-mondo-utilities/CardMondoUtilities";
import CardNew from "./card-news/CardNews";
import "./SearchResults.scss";

export default class SearchResults extends Component {
  state = { list: [], categoryList: [] };

  componentDidMount() {
    this._fetchResults();
  }

  componentDidUpdate(oldProps, oldState) {
    if (oldProps.location.state.query !== this.props.location.state.query) {
      this._fetchResults();
    }
  }

  _fetchResults = async () => {
    this.setState({ loading: true });
    try {
      const response = await axios.post(
        "https://api.utilitalia.it/Search/GlobalSearch",
        {
          profile: "utilitalia",
          filter: this.props.location.state.query,
          pageStart: "1",
          pageSize: "20",
          maxRows: 500,
          applyPagingToFindingTypes: true,
        }
      );
      let list = response?.data?.data?.findings;
      if (list) {
        list = groupByType(list);
        // console.log("LIST", list);
        //order array by type
        list.sort((a, b) => {
          if (a.code === "circolari") return -1;
          if (b.code === "circolari") return 1;
          if (a.code === "norme_regole") return -1;
          if (b.code === "norme_regole") return 1;
          if (a.code === "quesiti_lavoro") return -1;
          if (b.code === "quesiti_lavoro") return 1;
          if (a.code === "quesiti_ambiente") return -1;
          if (b.code === "quesiti_ambiente") return 1;
          if (a.code === "news") return 1;
          if (b.code === "news") return -1;

          return 0;
        });

        this.setState({ list });
      }
    } catch (err) {
      console.log("Error fetching results SearchResults", err);
    }
    this.setState({ loading: false });
  };

  render() {
    return (
      <div className="search-results-page">
        <div className="container pad-mobile">
          <div
            className="search-results-list"
            style={{
              minHeight: "26vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {this.state.loading ? (
              <Spinner />
            ) : this.state.list.length === 0 ? (
              <h1 className="big no-results">Non ci sono risultati</h1>
            ) : (
              this.state.list.map((element, i) => (
                <Category key={`category_${i}`} element={element} i={i} />
              ))
            )}
          </div>
        </div>
      </div>
    );
  }
}

const Category = ({ element, i }) => {
  const [num, setNum] = useState(6);

  return (
    <div key={i} className="category-results">
      <h1 className="big">{element.name}</h1>

      <div className="items-results">
        {element.data.slice(0, num).map((item, iItem) => (
          <div className="item" key={`${item.findingType.code}_${iItem}`}>
            {item.findingType.code.includes("eventi") ? (
              <CardEvent element={item} />
            ) : item.findingType.code === "news" ? (
              <CardNew element={item} />
            ) : item.findingType.code === "circolari" ? (
              <CardCircular element={item} />
            ) : item.findingType.code === "mondoutilities" ? (
              <CardMondoUtilities element={item} />
            ) : item.findingType.code === "pubblicazioni" ? (
              <CardGeneral link="/pubblicazioni/list/list/" element={item} />
            ) : item.findingType.code === "bollettino_finanziamenti" ? (
              <CardGeneral
                link="/energia/bollettino-finanziamenti/list/"
                element={item}
              />
            ) : item.findingType.code === "norme_regole" ? (
              <CardGeneral
                link="/area-giuridico-legislativa-fiscale/norme-e-regole/list/"
                element={item}
              />
            ) : item.findingType.code === "quesiti_lavoro" ? (
              <CardGeneral
                link="area-lavoro-relazioni-industriali/quesito/"
                element={item}
              />
            ) : (
              item.findingType.code === "quesiti_ambiente" && (
                <CardGeneral link="/ambiente/quesito/" element={item} />
              )
            )}
          </div>
        ))}
      </div>
      {num < element.data.length && (
        <div
          onClick={() => setNum(num + 6)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          Mostra di più
        </div>
      )}
    </div>
  );
};

const groupByType = (arr) => {
  return Object.values(
    arr.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.findingType.code]: {
          code: curr.findingType.code,
          name: curr.findingType.name,
          data: [
            ...(acc[curr.findingType.code]
              ? acc[curr.findingType.code].data
              : []),
            curr,
          ],
        },
      };
    }, {})
  );
};
