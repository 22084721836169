import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import moment from "moment";
import "./CalendarioEventi.scss";
import Pagination from "components/pagination/Pagination";
import { ReactComponent as Timer } from "icons/timer-bold.svg";
import Placeholders from "components/placeholders/Placeholders";
import { BsArrowRight as ArrowRight } from "react-icons/bs";

const isAllDay = (date1, date2) => {
  if (moment(date1).format("LLL") === moment(date2).format("LLL")) return true;
  else return false;
};
const isSameDate = (date1, date2) => {
  if (moment(date1).format("L") === moment(date2).format("L")) return true;
  else return false;
};

const colorEvent = (type) => {
  switch (type) {
    case "EVENTO INTERAMENTE UTILITALIA":
      return {
        color: "#eb640c",
        text: "Evento Utilitalia",
      };
    case "EVENTI ESTERNI DA SEGNALARE":
      return {
        color: "#00447b",
        text: "Utilitalia segnala",
      };
    case "EVENTO PATROCINATO O IN COLLABORAZIONE":
      return {
        color: "#07ca6e",
        text: "Patrocinio Utilitalia",
      };
    default:
      return {
        color: "#00447b",
        text: type,
      };
  }
};

export default class CalendarioEventi extends Component {
  state = {
    to: 20,
    from: 0,
    show: 9,
    active: 1,
    eventi: [],
    loading: true,
    totalPages: 1,
  };

  async componentDidMount() {
    // try {
    //   let response = await axios.post(
    //     "https://api.utilitalia.it/AGE/Events/List",
    //     {
    //       calendars: ["Calendario interno"],
    //       showNextDays: "365",
    //       showPastDays: "1",
    //       orderDirection: "asc",
    //       pageStart:this.state.from ?? 1,
    //       pageSize: "20",
    //     }
    //   );
    //   response = response?.data?.data;
    //   this.setState({
    //     eventi: response?.appointments,
    //     totalPages: response?.totalPages,
    //   });
    // } catch (err) {
    //   console.log("Error in CDU CalendarioEventi", err);
    // }
    // this.setState({ loading: false });
    this.fetchEventi();
  }

  fetchEventi = async  ()=> {
    this.setState({ loading: true });
    // console.log("storagedActiveYear", activeYear);
    try {
      let response = await axios.post(
        "https://api.utilitalia.it/AGE/Events/List",
        {
          calendars: ["Calendario interno"],
          showNextDays: "365",
          showPastDays: "1",
          orderDirection: "asc",
          pageStart: this.state.from > 0 ? this.state.from : "1",
          pageSize: "20",
        }
      );
      response = response?.data?.data;
      this.setState({
        eventi: response?.appointments,
        totalPages: response?.totalPages,
      });
      //console.log( this.state.eventi );
    } catch (err) {
      console.log("Error in CDU CalendarioEventi", err);
    }
    this.setState({ loading: false });
  };
  componentDidUpdate(oldProps, oldState) {
    if (
      this.state.from !== oldState.from
    ) {
       this.fetchEventi();
    }
  }

  handlePageClick = async (data) => {
    let selected = data.selected;
    window.scrollTo(0, 440);
    this.setState({
      from: selected * 20,
      to: (selected * 20) + 20
    });
  };


  render() {
    return (
      <div id="calendario-eventi">
        <Helmet>
          <title>Utilitalia - Calendario Eventi</title>
          <meta
            name="description"
            content="Visita il nostro sito e scopri tutti gli eventi organizzati da Utilitalia"
          />
        </Helmet>

        <div className="hero"></div>

        <div className="container pad-mobile">
          <div className="heading">
            <h2 className="general">
              Calendario <span>Eventi</span>
            </h2>

            <Link to="/archivio-eventi">
              <span className="general">Archivio Eventi</span>
              <ArrowRight />
            </Link>
          </div>

          <div className="eventi-list">
            {this.state.loading ? (
              <Placeholders number={9} type="evento" />
            ) : (
              this.state.eventi
                //.slice(this.state.from, this.state.to)
                .map((element, i) =>{ 
                    const id = element?.talkingId !== null && element?.talkingId.trim() !== '' && element?.talkingId !== false ? element?.talkingId : element.id;
                    return (
                  <div className="event" key={i}>
                    <div
                      className="heading"
                      style={{ background: colorEvent(element.type).color }}
                    >
                      <span>
                        {moment(element.startDate).format("DD MMM YYYY")}
                      </span>
                      <span>{colorEvent(element.type).text}</span>
                    </div>
                    <div className="body">
                      <div className="top">
                        <p>{element.subject}</p>
                      </div>
                      <div className="bottom">
                        <div className="time">
                          <Timer />{" "}
                          {isSameDate(element.startDate, element.endDate) ? (
                            isAllDay(element.startDate, element.endDate) ? (
                              <span>Tutto il giorno</span>
                            ) : (
                              <span>
                                {moment(element.startDate).format("HH:mm")} -{" "}
                                {moment(element.endDate).format("HH:mm")}
                              </span>
                            )
                          ) : (
                            <span>
                              {moment(element.startDate).format("DD/MM")} -{" "}
                              {moment(element.endDate).format("DD/MM")}
                            </span>
                          )}
                        </div>
                        <Link
                          to={"/evento/" + id}
                          className="btn small"
                        >
                          Dettaglio
                        </Link>
                      </div>
                    </div>
                  </div>
                )})
            )}
            {this.state.totalPages > 1 && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: 32,
                }}
              >
                <Pagination
                  totalPages={this.state.totalPages}
                  handlePageClick={this.handlePageClick}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
